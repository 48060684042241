import { AfterViewInit, Component, ViewEncapsulation } from "@angular/core";
import { Premio } from "../../interfaces/premio.interface";
import { Router } from "@angular/router";

@Component({
  selector: "app-premio-categoria",
  templateUrl: "./premio-categoria.component.html",
  styleUrls: ["./premio-categoria.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class PremioCategoriaComponent implements AfterViewInit{


  constructor(private router:Router){

  }

  ngAfterViewInit(): void {
    let sort = document.querySelector(".sort");
    let view = document.querySelector(".view");
    sort?.children[0].children[2].children[0].classList.remove("pi-chevron-down")
    view?.children[0].children[2].children[0].classList.remove("pi-chevron-down")
    sort?.children[0].children[2].children[0].classList.add("bi-caret-down-fill")
    view?.children[0].children[2].children[0].classList.add("bi-caret-down-fill")
    
  }
  premios: Premio[] = [
    {
      id: 1,
      description: `Cena para dos personas en "Don Julio"`,
      price: 1000,
      ruta: "../../../../../assets/img/",
      nombreImagen: "",
      imagen: "",
    },
    {
      id: 1,
      description: `1 kg de helado de "Lucianno's"`,
      price: 500,
      ruta: "../../../../../assets/img/",
      nombreImagen: "",
      imagen: "",
    },
    {
      id: 1,
      description: `Cena para 3 personas en "Lo de Tata"`,
      price: 1500,
      ruta: "../../../../../assets/img/",
      nombreImagen: "",
      imagen: "",
    },
    {
      id: 1,
      description: `Cena para 4 personas en "Fechoria"`,
      price: 2500,
      ruta: "../../../../../assets/img/",
      nombreImagen: "",
      imagen: "",
    },
    {
      id: 1,
      description: `2 kg de helado de "Lucianno's"`,
      price: 1000,
      ruta: "../../../../../assets/img/",
      nombreImagen: "",
      imagen: "",
    },
    {
      id: 1,
      description: `Cena para 4 personas en "Fechoria"`,
      price: 1500,
      ruta: "../../../../../assets/img/",
      nombreImagen: "",
      imagen: "",
    },
  ];

  filtroOrden = [
    {label: "Menor a mayor",value:1},
    {label: "Mayor a menor",value:2},
  ]

  viesPremier = [
    {label: "todos",value:1},
    {label: "menor a 1000",value:2},
    {label: "menor a 2000",value:3},
    {label: "mayor a 1000",value:4},
    {label: "mayor a 2000",value:5},
  ]

  filter(value:any,param:any){

  }

  canjear(premio:Premio){
    this.router.navigate([`canjear/${premio.id}`])
  }
}
