import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuscadorComponent } from './modules/polizas/pages/buscador/buscador.component';
import { HomeComponent } from './modules/shell/pages/home/home.component';
import { ContratacionComponent } from './modules/polizas/pages/contratacion/contratacion.component';
import { MiPerfilComponent } from './modules/users/pages/mi-perfil/mi-perfil.component';
import { ReferidosComponent } from './modules/referidos/pages/referidos/referidos.component';
import { PolizasComponent } from './modules/polizas/pages/polizas/polizas.component';
import { PremiosYCanjesComponent } from './modules/premios/pages/premios-y-canjes/premios-y-canjes.component';
import { PremioCategoriaComponent } from './modules/premios/pages/premio-categoria/premio-categoria.component';
import { PremioCanjeComponent } from './modules/premios/pages/premio-canje/premio-canje.component';
import { ContratacionLoginComponent } from './modules/contratacion/pages/contratacion-login/contratacion-login.component';
import { ContratacionAutomovilComponent } from './modules/contratacion/pages/contratacion-automovil/contratacion-automovil.component';
import { ContratacionFinalizarComponent } from './modules/contratacion/pages/contratacion-finalizar/contratacion-finalizar.component';
import { ContratacionPolizaContratadaComponent } from './modules/contratacion/pages/contratacion-poliza-contratada/contratacion-poliza-contratada.component';
import { RegistrarseComponent } from './modules/users/components/registrarse/registrarse.component';
import { SobreNosotrosComponent } from './modules/shell/components/sobre-nosotros/sobre-nosotros.component';
import { TerminosYCondicionesComponent } from './modules/shell/components/terminos-y-condiciones/terminos-y-condiciones.component';
import { PreguntasPrecuentesComponent } from './modules/shell/components/preguntas-precuentes/preguntas-precuentes.component';
import { ServicioAlClienteComponent } from './modules/shell/components/servicio-al-cliente/servicio-al-cliente.component';
import { ValidarCodigoComponent } from './modules/users/components/validar-codigo/validar-codigo.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'registrarse', component: RegistrarseComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'registrarse/validar-codigo', component: ValidarCodigoComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'buscador', component: BuscadorComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'contratacion', component: ContratacionComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'contratacion/ingresar', component: ContratacionLoginComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'contratacion/automovil', component: ContratacionAutomovilComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'contratacion/finalizar', component: ContratacionFinalizarComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'contratacion/poliza-contratada', component: ContratacionPolizaContratadaComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'mi-perfil', component: MiPerfilComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'referidos', component: ReferidosComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'polizas', component: PolizasComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'premios-y-canjes', component: PremiosYCanjesComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'premios-y-canjes/:categoria', component: PremioCategoriaComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'canjear/:premio', component: PremioCanjeComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'sobre-nosotros', component: SobreNosotrosComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'terminos-y-condiciones', component: TerminosYCondicionesComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'preguntas-frecuentes', component: PreguntasPrecuentesComponent, /*canActivate: [AuthGuard]*/ },
  { path: 'servicio-al-cliente', component: ServicioAlClienteComponent, /*canActivate: [AuthGuard]*/ },
  {
    path: 'consejos',
    loadChildren: () =>
      import('./modules/consejos/consejo.module').then((m) => m.ConsejoModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
