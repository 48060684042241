<div [style.width]="'100%'" [style.min-height]="'20vh'"  [style.margin]="'1em!important'" class="d-flex justify-content-center" *ngIf="!spinner">

  <form [formGroup]="form" (ngSubmit)="confirmar()">
    <div [style.margin]="'0.5em !important'">
      <h1 *ngIf="mail" class="subtitle-blue">Por favor ingrese el código de confirmación enviado al mail {{mail}}</h1>
      <h1 *ngIf="!mail" class="subtitle-blue">Por favor ingrese el código de confirmación enviado a su  mail </h1>
      <p-divider></p-divider>
    </div>
    <div class="row ">  
      <div class="col-6 mb-3">
        <label for="" class="label-input">Nro documento</label>
        <div class="col-12">
          <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" pInputText
            formControlName="doc" title="Número de Documento" readonly (keydown)="false"/>
        </div>
        <div *ngIf="form.get('doc').hasError('required') && form.get('doc').touched" class="text-center">
          <small class="text-danger">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="" class="label-input">Código</label>
        <input type="mail" [style]="{ width: '100%' }" pInputText formControlName="code"
          class="input p-inputtext p-component p-element" title="" />
        <div *ngIf="form.get('code').hasError('required') && form.get('code').touched" class="text-center">
          <small class="text-danger">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
    </div>
    
    <div class="col-12" *ngIf="referidosExist">
      <label for="" class="label-input">Me refirió</label>
      <div class="col-12">
        <p-dropdown class="" [options]="referidos" [style]="{ width: '100%' }" formControlName="referralCode"
          optionLabel="Referrer" optionValue="Code"  placeholder="Ninguno" appendTo="body">
        </p-dropdown>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-center" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
      <p-button type="submit" (click)="mostrarErrores()" [style.margin]="'1em !important'" class="max"  label="Confirmar" [disabled]="form.invalid"></p-button>
      <p-button routerLink="/home" [style.margin]="'1em !important'" class="max secondary" label="Cancelar" (click)="volver()"> </p-button>
    </div>
  
  </form>
</div>

<div class="card w-100 text-center" *ngIf="spinner" [style.min-height]="'42vh'">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
</div>