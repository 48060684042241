<app-title title="Contratación"></app-title>
<div class="container-w80" [style.min-height]="'70vh'">
    <div class='d-flex flex-column flex-sm-row justify-content-between'>
        <span class="subtitle" [style.margin]="'2em 0px '"> Chevrolet > Celta 1.4 5 Ptas > LS AA > 2011 >
            Buenos Aires > Mar del Plata</span>
        <span class="pasos" [style.margin]="'2em 0px '">
            Paso {{paso}} / 3
        </span>
    </div>

    <div class="d-flex flex-row" [style.width]="'100%'">
        <div class="card-datos" class="d-flex flex-column" [style.width]="'100%'">
            <div class="d-flex flex-column flex-sm-row justify-content-around text-align-center" [style.width]="'100%'">
                <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                    <div class="d-flex flex-column " [style.width]="'100%'" [style.margin]="'0.5em'">
                        <span class="modal-monto">$ 2.204</span>
                        <span class="label">Por Mes</span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'" [style.margin]="'0.5em'">
                        <span class="label">Pesponsabilidad Civil</span>
                    </div>
                </div>

                <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                    <div class="d-flex flex-column" [style.width]="'100%'" [style.margin]="'0.5em'">
                        <span class="label">Monto Asegurado</span>
                        <span class="label">$1.155.000</span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'" [style.margin]="'0.5em'">
                        <span class="detalle-poliza" [style.width]="'100%'"> Detalle de la póliza</span>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'" fxLayoutAlign="space-around center">
                    <div class="d-flex flex-column" [style.width]="'100%'" fxLayoutAlign="space-around center"
                        [style.margin]="'0.5em'">
                        <span class="suma-puntos">SUMAS 1250 PUNTOS!</span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'" fxLayoutAlign="space-around center"
                        [style.margin]="'0.5em'">
                        <img class="logo"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSv8WWyRlFESun5YQIkXGAu4vxiHUpFc-Zv0PNRDesYqp3AobTFkeNFedmVnWbHal-HqQ&usqp=CAU"
                            alt="">
                    </div>
                </div>
            </div>
            <p-divider *ngIf="datosFormulario3"></p-divider>
            <div class="d-flex flex-column flex-sm-row justify-content-around text-align-center"
                *ngIf="datosFormulario3">
                <div class="d-flex flex-column" [style.width]="'100%'">
                    <div class="d-flex flex-column " [style.width]="'100%'">
                        <span class="label">{{datosFormulario3.marca}} - {{datosFormulario3.modelo}}</span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label"> {{datosFormulario3.version }} - {{datosFormulario3.anio}}</span>
                    </div>
                </div>

                <div class="d-flex flex-column" [style.width]="'100%'">
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label">{{datosFormulario3.combustible}} </span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label">Patente {{datosFormulario3.patente }} </span>
                    </div>
                </div>
                <div class="d-flex flex-column" [style.width]="'100%'">
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label"> N° de Chasis {{datosFormulario3.chasis }} </span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label"> N° de Motor {{datosFormulario3.motor }} </span>
                    </div>
                </div>
                <div class="d-flex flex-column" [style.width]="'100%'">
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label"> {{datosFormulario3.calle }} {{datosFormulario3.numero }} </span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label"> {{datosFormulario3.provincia }} {{datosFormulario3.localidad }} </span>
                    </div>
                </div>
            </div>
            <p-divider *ngIf="paso==4"></p-divider>
            <div class="d-flex flex-column flex-sm-row justify-content-around text-align-center" *ngIf="paso==4">
                <div class="d-flex flex-column" [style.width]="'100%'">
                    <div class="d-flex flex-column " [style.width]="'100%'">
                        <span class="label">Poliza N° 1223456</span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'" [style.margin-top]="'0.5em'">
                        <span class="label"> Medio de pafo Tarjeta de credito **** **** **** 1234</span>
                    </div>
                </div>
                <div class="d-flex flex-column" [style.width]="'100%'">
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label"> Vigencia del 15/05/2022 al 15/06/2022</span>
                    </div>
                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <span class="label-estado"> Estado</span>
                        <span class="label-value"> HABILITADO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="paso==1" class="d-flex flex-column flex-sm-row flex-md-row" [style.margin]="'2em 0px !important'">
        <div [style.width]="'100%'" [style.margin]="'1em!important'">
            <form [formGroup]="form1" class="d-flex flex-column" [style.width]="'100%'">
                <div [style.margin]="'0.5em !important'">
                    <h1 class="subtitle-blue">¿Ya tenés cuenta?</h1>
                    <h1 class="subtitle-pink-ligth">Ingresá con tus datos</h1>
                    <p-divider></p-divider>
                </div>
                <div class="input-container">
                    <label class="label-input">Correo electronico</label>
                    <div class="p-inputgroup">
                        <input type="text" class="input p-inputtext p-component p-element" pInputText
                            formControlName="correo" />
                    </div>
                </div>
                <div class="input-container">
                    <label class="label-input">Contraseña</label>
                    <div class="p-inputgroup">
                        <input type="password" class="input p-inputtext p-component p-element" pInputText
                            formControlName="contrasenia" />
                    </div>
                </div>
                <div class="input-container" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
                    <p-button [style.margin-top]="'1em !important'" (click)="ingresar()" class="max"
                        label="Ingresar"></p-button>
                </div>
            </form>
        </div>
        <div [style.width]="'100%'" [style.margin]="'1em!important'">
            <form [formGroup]="form2" autocomplete="off" class="d-flex flex-column" [style.width]="'100%'">
                <div [style.margin]="'0.5em !important'">
                    <h1 class="subtitle-blue">¿Aún no tenés cuenta?</h1>
                    <h1 class="subtitle-pink-ligth">Creala ahora</h1>
                    <p-divider></p-divider>
                </div>
                <div class="d-flex flex-row">
                    <div class="input-container" [style.width]="'100%'">
                        <label class="label-input">Nombre</label>
                        <div class="p-inputgroup">
                            <input type="text" class="input p-inputtext p-component p-element" pInputText
                                formControlName="nombre" />
                        </div>
                    </div>
                    <div class="input-container" [style.width]="'100%'">
                        <label class="label-input">Apellido</label>
                        <div class="p-inputgroup">
                            <input type="text" class="input p-inputtext p-component p-element" pInputText
                                formControlName="apellido" />
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <div class="input-container" [style.width]="'100%'">
                        <label class="label-input">Teléfono móvil</label>
                        <div class="d-flex flex-row">
                            <div class="p-inputgroup" fxFlex="30">
                                <span class="p-inputgroup-addon">0</span>
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="telefono1" />
                            </div>
                            <div class="p-inputgroup" fxFlex="70">
                                <span class="p-inputgroup-addon">15</span>
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="telefono2" />
                            </div>
                        </div>

                    </div>
                </div>

                <div class="input-container">
                    <label class="label-input">Correo electronico</label>
                    <div class="p-inputgroup">
                        <input type="text" class="input p-inputtext p-component p-element" pInputText
                            formControlName="correo" />
                    </div>
                </div>

                <div class="input-container">
                    <label class="label-input">Contraseña</label>
                    <div class="p-inputgroup">
                        <input type="password" class="input p-inputtext p-component p-element" pInputText
                            formControlName="contrasenia" />
                    </div>
                </div>

                <div class="input-container">
                    <label class="label-input">Confirmar contraseña</label>
                    <div class="p-inputgroup">
                        <input type="password" class="input p-inputtext p-component p-element" pInputText
                            formControlName="confirmarContrasenia" />
                    </div>
                </div>
                <div class="input-container" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
                    <p-button [style.margin-top]="'1em !important'" class="max" label="Crear cuenta"></p-button>
                </div>
            </form>
        </div>


    </div>

    <form *ngIf="paso==2" [formGroup]="form3" class="d-flex flex-column" [style.margin]="'2em 0px !important'">
        <div class="d-flex flex-column" [style.width]="'100%'">
            <div [style.margin]="'0.5em !important'">
                <h1 class="subtitle-blue">Hola Juan, ingresá por favor los datos de tu automóvil</h1>
                <h1 class="subtitle-pink-ligth">Completamos algunos campos con los datos que ingresaste inicialmente, verificá
                    que sean correctos</h1>
                <p-divider></p-divider>
            </div>
            <div class="d-flex flex-column flex-sm-row">
                <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                    <div class="d-flex flex-column " [style.width]="'100%'">
                        <div class="input-container">
                            <label class="label-input">Marca</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="marca" />
                            </div>
                        </div>
                        <div class="input-container">
                            <label class="label-input">Modelo</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="modelo" />
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column " [style.width]="'100%'">
                        <div class="input-container">
                            <label class="label-input">Año</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="anio" />
                            </div>
                        </div>
                        <div class="input-container">
                            <label class="label-input">Versión</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="version" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                    <div class="d-flex flex-column " [style.width]="'100%'">
                        <div class="input-container">
                            <label class="label-input">Combustible</label>
                            <div class="d-flex flex--row" [style.margin-top]="'0.4em'" [style.margin-bottom]="'0.35em'">
                                <div class="p-inputgroup">
                                    <label class='label-check'>Nafta</label>
                                    <p-radioButton value="Nafta" [(ngModel)]="selectedCombustible"
                                        [style.margin-left]="'0.5em'"
                                        [ngModelOptions]="{standalone: true}"></p-radioButton>
                                </div>
                                <div class="p-inputgroup">
                                    <label class='label-check'>GNC</label>
                                    <p-radioButton value="GNC" [(ngModel)]="selectedCombustible"
                                        [style.margin-left]="'0.5em'"
                                        [ngModelOptions]="{standalone: true}"></p-radioButton>
                                </div>
                            </div>
                        </div>
                        <div class="input-container">
                            <label class="label-input">N° de motor <span class="atencion">¡</span></label>
                            <div class="p-inputgroup">
                                <p-inputNumber formControlName="chasis" mode="decimal">
                                </p-inputNumber>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column" [style.width]="'100%'">
                        <div class="input-container">
                            <label class="label-input">Patente <span class="atencion">¡</span></label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="patente" />
                            </div>
                        </div>
                        <div class="input-container">
                            <label class="label-input">N° de chasis <span class="atencion">¡</span></label>
                            <div class="p-inputgroup">
                                <p-inputNumber formControlName="chasis" mode="decimal">
                                </p-inputNumber>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div autocomplete="off" class="d-flex flex-column " [style.width]="'100%'">
            <div [style.margin]="'0.5em !important'">
                <h1 class="subtitle-blue">Dirección donde reside mi automóvil</h1>
                <p-divider></p-divider>
            </div>
            <div class="d-flex flex-column flex-sm-row">
                <div class="d-flex flex-column " [style.width]="'100%'">
                    <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                        <div class="input-container" [style.width]="'100%'">
                            <label class="label-input">Calle</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="calle" />
                            </div>
                        </div>
                        <div class="input-container" [style.width]="'100%'">
                            <label class="label-input">Número</label>
                            <div class="p-inputgroup">
                                <p-inputNumber formControlName="numero" mode="decimal">
                                </p-inputNumber>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column">
                        <div class="input-container">
                            <label class="label-input">Provincia</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="provincia" />
                            </div>
                        </div>

                    </div>

                    <div class="d-flex flex-column">
                        <div class="input-container">
                            <div class="p-inputgroup">
                                <p-radioButton value="true" [(ngModel)]="selectedDatos" [style.margin-left]="'0.5em'"
                                    [ngModelOptions]="{standalone: true}"></p-radioButton>
                                <label class='label-declaraciones'>Declaro que mis datos son verdaderos</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column " [style.width]="'100%'">
                    <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                        <div class="input-container" [style.width]="'100%'">
                            <label class="label-input">Piso/Dto</label>
                            <div class="p-inputgroup">
                                <p-inputNumber formControlName="piso" mode="decimal">
                                </p-inputNumber>
                            </div>
                        </div>
                        <div class="input-container" [style.width]="'100%'">
                            <label class="label-input">Código postal</label>
                            <div class="p-inputgroup">
                                <p-inputNumber formControlName="codigoPostal" mode="decimal">
                                </p-inputNumber>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column ">
                        <div class="input-container">
                            <label class="label-input">Localidad</label>
                            <div class="p-inputgroup">
                                <input type="text" class="input p-inputtext p-component p-element" pInputText
                                    formControlName="localidad" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column ">
                        <div class="input-container">
                            <div class="p-inputgroup">
                                <p-radioButton value="true" [(ngModel)]="selectedConsumidorFinal"
                                    [style.margin-left]="'0.5em'" [ngModelOptions]="{standalone: true}"></p-radioButton>
                                <label class='label-declaraciones'>Declaro que soy consumidor final</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-container">
                <p-button [style.margin]="'0.5em !important'" (click)="continuar()" class="max"
                    label="continuar"></p-button>
            </div>
        </div>
    </form>

    <div *ngIf="paso==3" class="d-flex flex-column" [style.margin]="'2em 0px !important'">
        <div class="d-flex flex-column" [style.width]="'100%'" [style.margin-top]="'1em'">
            <div [style.margin]="'0.5em !important'">
                <h1 class="subtitle-blue">

                    Código de referido
                </h1>
                <h1 class="subtitle-pink-ligth">¿Tenés el código de algún referido? Ingresalo acá y hace que esa persona sume
                    puntos</h1>
                <p-divider></p-divider>
            </div>
            <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                    <p-accordion [activeIndex]="[0]" [style.width]="'100%'">
                        <p-accordionTab header="Ingresar código">
                            <form [formGroup]="formReferido" class="d-flex flex-column"
                                [style.margin]="'2em 0px !important'">
                                <div class="d-flex flex-column">
                                    <div class="input-container">
                                        <label class="label-input">Codigo referido</label>
                                        <div class="p-inputgroup">
                                            <input type="text" class="input p-inputtext p-component p-element"
                                                pInputText formControlName="codigo" />
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'" [style.margin-top]="'1em'">
            <div [style.margin]="'0.5em !important'">
                <h1 class="subtitle-blue">Finalizar contratación</h1>
                <h1 class="subtitle-pink-ligth">Selecciona un medio de pago</h1>
                <p-divider></p-divider>
            </div>
            <p-accordion [activeIndex]="[0]" [style.width]="'100%'">
                <p-accordionTab header="Tarjeta (débito/crédito)" [style.width]="'100%'">
                    <form [formGroup]="formTarjeta" class="d-flex flex-row" [style.margin]="'2em 0px !important'">
                        <div class="d-flex flex-column" [style.width]="'100%'">
                            <div class="input-container" [style.width]="'100%'">
                                <label class="label-input">Número de tarjeta</label>
                                <div class="p-inputgroup">
                                    <p-inputNumber formControlName="numero" mode="decimal">
                                    </p-inputNumber>
                                </div>
                            </div>
                            <div class="input-container" [style.width]="'100%'">
                                <label class="label-input">Nombre del titular</label>
                                <div class="p-inputgroup">
                                    <input type="text" class="input p-inputtext p-component p-element" pInputText
                                        formControlName="titular" />
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row" [style.width]="'100%'">
                            <div class="d-flex flex-column " [style.width]="'100%'">
                                <div class="input-container" class="d-flex flex-row" [style.width]="'100%'">
                                    <div [style.width]="'100%'">
                                        <label class="label-input">Mes</label>
                                        <div class="p-inputgroup" [style.width]="'100%'">
                                            <p-dropdown [options]="anios" formControlName="vencimientoMes"
                                                optionLabel="name"></p-dropdown>
                                        </div>
                                    </div>
                                    <div [style.width]="'100%'">
                                        <label class="label-input">Año</label>
                                        <div class="p-inputgroup">
                                            <p-inputNumber formControlName="vencimientoAnio" mode="decimal">
                                            </p-inputNumber>
                                        </div>
                                    </div>

                                </div>
                                <div class="input-container" [style.width]="'100%'">
                                    <label class="label-input">DNI</label>
                                    <div class="p-inputgroup">
                                        <p-inputNumber formControlName="dni" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row" [style.width]="'100%'">
                            <div class="d-flex flex-column " [style.width]="'100%'">
                                <div class="input-container" [style.width]="'100%'">
                                    <label class="label-input">Codigo de seguridad</label>
                                    <div class="p-inputgroup">
                                        <p-inputNumber formControlName="codigoSeguridad" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>
                                <div class="input-container" [style.width]="'100%'">
                                    <label class="label-input">Banco</label>
                                    <div class="p-inputgroup">
                                        <input type="text" class="input p-inputtext p-component p-element" pInputText
                                            formControlName="banco" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
                <p-accordionTab header="Nro de CBU bancario" [style.width]="'100%'">
                    <form [formGroup]="formCbu" class="d-flex flex-column" [style.margin]="'2em 0px !important'">
                        <div class="d-flex flex-column">
                            <div class="input-container">
                                <label class="label-input">CBU</label>
                                <div class="p-inputgroup">
                                    <input type="text" class="input p-inputtext p-component p-element" pInputText
                                        formControlName="cbu" />
                                </div>
                            </div>

                        </div>
                    </form>
                </p-accordionTab>
                <p-accordionTab header="Transferencia bancaria" [style.width]="'100%'">
                    <form [formGroup]="formTranferencia" class="d-flex flex-column flex-sm-row"
                        [style.margin]="'2em 0px !important'">
                        <div class="d-flex flex-column flex-sm-row" [style.width]="'100%'">
                            <div class="input-container" [style.width]="'100%'">
                                <label class="label-input">Cuenta</label>
                                <div class="p-inputgroup">
                                    <input type="text" class="input p-inputtext p-component p-element" pInputText
                                        formControlName="cuenta" />
                                </div>
                            </div>

                        </div>
                    </form>
                </p-accordionTab>
            </p-accordion>

        </div>



        <div class="input-container" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
            <p-button [style.margin-top]="'1em !important'" (click)="contratar()" class="max"
                label="Contratar"></p-button>
        </div>

    </div>


    <div class="input-container"  *ngIf ="paso==4"[style.text-align]="'center'" [style.margin-top]="'1em !important'">
        <p-button [style.margin-top]="'1em !important'" class="max"
            label="Ir a mis pólizas"></p-button>
    </div>
</div>