import { Component } from '@angular/core';

@Component({
  selector: 'app-pop-up-detail-terminos-y-condiciones',
  templateUrl: './pop-up-detail-terminos-y-condiciones.component.html',
  styleUrls: ['./pop-up-detail-terminos-y-condiciones.component.css']
})
export class PopUpDetailTerminosYCondicionesComponent {

}
