import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class RefererManagerService {

  constructor(private http: HttpClient) { }

  getList() {
    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return this.http
      .get(`${environment.apiUrl}/ReferralsManager.svc/GetUserReferrals`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  create(emailToRefer) {
    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return this.http
      .post(`${environment.apiUrl}/ReferralsManager.svc/CreateUserReferral/${emailToRefer}`, {}, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  GetUserReferrers(UserId:string) {
    return this.http
      .get(`${environment.apiUrl}/ReferralsManager.svc/GetUserReferrers?userId=${UserId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
