<p-dialog [(visible)]="visiblePopUpContratacion" [style]="{ width: '50vw' }" [modal]="true">
  <app-pop-up-detail-poliza  [contratarBtn]="false" [budgetId]="budgetId" [quoteDetailsCompany]="quoteDetailsCompany" [company]="company"
    [comboReceived]="comboReceived"></app-pop-up-detail-poliza>
</p-dialog>
<div class="card w-100 text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container" *ngIf="currentUser && budgetId && !spinner;else error">
  <span class="pasos" [style.margin]="'2em 0px '" style="float: right;"> Paso 4 / 4 </span>
    
  <div class="d-flex flex-row" [style.width]="'100%'">
    <div class="card-datos" class="d-flex flex-column" [style.width]="'100%'">
      <div class="d-flex flex-row" [style.width]="'100%'">
        <div class="card-datos" class="d-flex flex-column" [style.width]="'100%'"
          *ngIf="quoteDetailsCompany && comboReceived && dataCar && addressCar">
          <div class="row" [style.width]="'100%'">
            <div class="col-6 col-lg-2 text-center">
              <div>
                <span class="modal-monto">${{ quoteDetailsCompany.premioMensual }}</span>
              </div>
              <div>
                <span class="label">Por Mes</span>
              </div>

            </div>
            <div class="col-6 col-lg-2">
              <span class="label">Responsabilidad Civil</span>
            </div>
            <div class="col-6 col-lg-2 text-center">
              <div>
                <span class="label">Monto Asegurado</span>
              </div>
              <div>
                <span class="label">${{ quoteDetailsCompany.valorAsegurado }}</span>
              </div>
            </div>
            <div class="col-6 col-lg-2">
              <span class="detalle-poliza" [style.width]="'100%'">
                <span (click)="showPopUpSeeDetails()">Detalle de la póliza</span></span>
            </div>
            <div class="col-6 col-lg-2 text-center text-lg-start my-3 my-lg-0">
              <span class="suma-puntos">SUMAS {{ quoteDetailsCompany.sumaRc }} PUNTOS!</span>
            </div>
            <div class="col-6 col-lg-2">
              <img class="logo" [src]="
                'https://www.aseguraygana.com/img/logos/' +
                company.Code +
                '.png'
              " alt="" />
            </div>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="d-flex flex-column flex-sm-row justify-content-around text-align-center"
        *ngIf="dataCar && addressCar">
        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ comboReceived.brand.Value }} -
              {{ comboReceived.model.Value }}</span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ comboReceived.version.Value }} -
              {{ comboReceived.year.Value }}</span>
          </div>
        </div>

        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">{{ dataCar.fuel }}</span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">Patente {{ dataCar.patent }} </span>
          </div>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label"> N° de Chasis {{ dataCar.chassis }} </span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label"> N° de Motor {{ dataCar.engine }} </span>
          </div>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ addressCar.street }} {{ addressCar.numberStreet }}
            </span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ currentUser.Provincia }} - {{ currentUser.Localidad }}
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column" [style.margin]="">
            <div class="d-flex flex-column" [style.width]="'100%'" [style.margin-top]="'1em'">
              <div [style.margin]="'0.5em !important'">
                <h1 class="subtitle-blue">Finalizar contratación</h1>
                <h1 class="subtitle-pink-ligth">
                  Selecciona un medio de pago
                </h1>
                <p-divider></p-divider>
              </div>
              <p-accordion [(activeIndex)]="activeIndex" [multiple]="false" [style.width]="'100%'">
                <p-accordionTab header="Tarjeta de crédito" [style.width]="'100%'">
                  <form [formGroup]="form" [style.margin]="'2em 0px !important'">
                    <div class="row">
                      <div class="col-6 col-lg-4">
                        <label class="label-input">Número de tarjeta</label>
                        <p-inputNumber formControlName="numero" mode="decimal" [useGrouping]="false" [style]="{ width: '100%' }"></p-inputNumber>
                        <span class="danger" *ngIf="form.get('numero').hasError('required') && form.get('numero').touched">
                          <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                        <span *ngIf="form.get('numero').hasError('pattern') && form.get('numero').touched">
                          <small class="p-error">El longitud debe ser de 16 dígitos</small>
                        </span>
                      </div>
                      <div class="col-6 col-lg-4">
                        <label class="label-input">Vencimiento</label>
                        <p-calendar [minDate]="currentDate" [style]="{ width: '100%' }" formControlName="vencimiento" view="month" dateFormat="mm/yy" [readonlyInput]="true"></p-calendar>
                        <span class="danger" *ngIf="form.get('vencimiento').hasError('required') && form.get('vencimiento').touched">
                          <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                      </div>
                      <div class="col-6 col-lg-4">
                        <label class="label-input">Entidad Financiera</label>
                        <p-dropdown class="" [options]="financialEntity" [style]="{ width: '100%' }" formControlName="entidad" optionLabel="Value">
                          <ng-template let-option pTemplate="item">
                            <span [class]="'entidad-' + option.Code">{{ option.Value }}</span>
                          </ng-template>
                        </p-dropdown>
                        <span class="danger" *ngIf="form.get('entidad').hasError('required') && form.get('entidad').touched">
                          <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>                        
                      </div>
                    </div>
                  </form>
                </p-accordionTab>
                <p-accordionTab header="Débito automático" [style.width]="'100%'">
                  <form [formGroup]="form2" [style.margin]="'2em 0px !important'">

                    <div class="row">
                      <div class="col-6 col-lg-6">
                        <label class="label-input">Nro de CBU</label>
                        <input type="text" class="form-control" pInputText formControlName="cbu" />
                        <span class="danger" *ngIf="form2.get('cbu').hasError('required') && form2.get('cbu').touched">
                          <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                        <span class="danger" *ngIf="form2.get('cbu').hasError('pattern')">
                          <small class="p-error">El CBU debe tener exactamente 22 dígitos</small>
                        </span>
                      </div>
                      <div class="col-6 col-lg-6">
                        <label class="label-input">Banco</label>
                        <p-dropdown class="" [options]="bankingEntity" [style]="{ width: '100%' }" formControlName="banco" optionLabel="Value">
                          <ng-template let-option pTemplate="item">
                            <span [class]="'banco-' + option.Code">{{ option.Value }}</span>
                          </ng-template>
                        </p-dropdown>
                        
                        <span class="danger" *ngIf="form2.get('banco').hasError('required') && form2.get('banco').touched">
                          <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                      </div>
                    </div>
                  </form>
                </p-accordionTab>
              </p-accordion>
            </div>
            <div class="input-container m-3" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
              <p-button (click)="send()" [style.margin-top]="'1em !important'" class="max" label="Contratar"></p-button>
              <p-button [style.margin]="'1em !important'" class="max" label="Volver" (click)="volver()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #error>
  <div class="container w-100 my-5" *ngIf="!spinner">
    <div class="row">
      <div class="col-12">
        <p-messages [closable]="false" [value]="[{ severity: 'error', detail: 'Ha ocurrido un error' }]"></p-messages>
      </div>
    </div>
  </div>
</ng-template>