<div class="d-flex flex-row" [style.background-color]="'#da6d73'">
  <div [style]="{ padding: '1em' }" class="container-w80 ml-5">
    <h1 class="title-white">
      Premios y Canjes &nbsp; > &nbsp; Gastronomía &nbsp; > &nbsp; Canjear
    </h1>
  </div>
</div>

<div class="background-section">
  <div class="d-flex flex-row">
    <div class="offset-md-1 col-12 col-md-4">
      <a [style.margin]="'2em 0px 0px 1.1em'" routerLink="/premios-y-canjes/Gastronomía" class="subtitle"
        ><i class="pi pi-arrow-left flecha"></i>Canjear</a
      >
    </div>
  </div>
  <!-- <div class="d-flex flex-row">
    <span class="subtitle" >
      Chevrolet > Celta 1.4 5 Ptas > LS AA > 2011 > Buenos Aires > Mar del
      Plata</span
    >
  </div> -->
  <div class="container m-0 p-4 m-auto">
    <div class="row m-0 p-0">
      <div class="col-12 col-lg-6">
        <p-image
          src="https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg"
          alt="Image"
          width="100%"
          height="100%"
        ></p-image>
      </div>
      <div class="col-12 col-lg-6">
        <p class="description-premio">Cena para 4 personas en "Lo de Tata"</p>
        <p class="mt-3 address-premio"></p>
        <img
          widht="56px"
          height="51px"
          src="../../../../../assets/img/boton-asyg.svg"
          alt=""
          srcset=""
          class="mb-4"
        />
        <span class="price ml-3">1500</span>
        <p-button class="grant" label="Canjear"></p-button>
        <p class="mt-3 time-warning-canjeo">
          Al canjear este beneficio tendrás 15 días para utilizarlo
        </p>
        <a href="" class="more-info">Ver más información</a>
      </div>
    </div>
  </div>
</div>
