import { Component, Input, OnInit } from "@angular/core";
import { Premio } from "../../interfaces/premio.interface";

@Component({
  selector: "app-premio-categoria-card",
  templateUrl: "./premio-categoria-card.component.html",
  styleUrls: ["./premio-categoria-card.component.css"],
})
export class PremioCategoriaCardComponent implements OnInit {

  @Input()
  premio!: Premio;

  ngOnInit(): void {
    this.premio.imagen = this.premio.ruta;
  }
}
