import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, map } from "rxjs";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class UserManagerService {
  
  constructor(private http: HttpClient) {}

  signIn(data: any) {
    return this.http
      .post(`${environment.apiUrl}/UserManager.svc/SignIn`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  signOut() {
    let headers = new HttpHeaders({
      "access-token": "my-auth-token",
    });
    return this.http
      .post(`${environment.apiUrl}/signOut`, {}, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  registerClient(client: any) {
    let headers = new HttpHeaders({
      "access-token": "my-auth-token",
    });
    return this.http
      .post(`${environment.apiUrl}/UserManager.svc/CreateUser`, client, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  forgotPassword(query: any) {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "userEmail") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "userId") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "isCuit") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value ;
        }
      }
    });

    return this.http
      .get(`${environment.apiUrl}/UserManager.svc/ForgotPassword?${queryBuid}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updatePassword(query: any) {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "newPassword") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "deleteSessions") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "isCuit") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders({
      "access-token": "my-auth-token",
    });

    return this.http
      .get(`${environment.apiUrl}/updatePassword?${queryBuid}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  GetUserData() {
    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}/UserManager.svc/GetUserData`, {
        headers: headers,
      })
    );
  }

  createUserForApi(user: any) {
    return this.http.post(`${environment.apiUrl}/createUserForApi`, user).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  activateUser(user: any) {
    return this.http.post(`${environment.apiUrl}/UserManager.svc/ActivateUser`, user).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateUser(user: any) {
    let token = localStorage.getItem("tokenAsegurayGana");
    let headers = token ? new HttpHeaders({ "access-token": token }) : new HttpHeaders();
    
    return this.http.post(`${environment.apiUrl}/UserManager.svc/UpdateUserData`, user, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
