<div id="cabecera" class="d-flex flex-column flex-sm-row justify-content-around text-align-center" [style.margin]="'1.5em 0px'"
  [style.align-items]="'center'">
  <a href="/home" style="margin-right: 28.5%">
    <img id="logo" src="assets/img/logo.svg" alt="">
  </a>
  <div class="d-flex flex-row justify-content-around">
    <p-button (click)="loginPopUp()" *ngIf="!tokenLogin && botonIngresar" class="grant m-1 my-3 my-lg-0 btn-over"
      label="Ingresar"></p-button>
    <p-button routerLink="/registrarse" *ngIf="!tokenLogin && botonRegister" class="grant m-1  my-3 my-lg-0 btn-over"
      label="Registrarse"></p-button>

  </div>
  <p-button *ngIf="tokenLogin" class="grant user-button  my-3 my-lg-0"
    label="¡Hola {{currentUser.Nombre}}!" icon="pi pi-user" iconPos="right" data-bs-toggle="dropdown"
    aria-expanded="false">
  </p-button>
  <ul class="dropdown-menu" *ngIf="tokenLogin != ''">
    <li><a class="dropdown-item cursor" href="mi-perfil">Mi perfil</a></li>
    <li><a class="dropdown-item cursor" href="referidos">Referidos</a></li>
    <li><a class="dropdown-item cursor" href="polizas">Pólizas</a></li>
    <li><a class="dropdown-item cursor" href="premios-y-canjes">Premios y canjes</a></li>
    <li><a class="dropdown-item cursor" (click)="logout()">Cerrar sesión</a></li>
  </ul>
</div>

<p-dialog header="Olvide mi contraseña" [modal]="true" [position]="'top'" [transitionOptions]="'500ms'"
  [(visible)]="visibleModalPassword" [style]="{ width: '50vw' }">
  <form [formGroup]="form2" (ngSubmit)="forgotPassword()">
    <div class="row">
      <div class="col-6 mb-3">
        <label for="" class="label-input">Mail</label>
        <div class="col-12">
          <input type="mail" pInputText formControlName="mail" class="form-control"  title="Correo Electrónico"/>
        </div>
        <div class="danger" *ngIf="form2.get('mail').hasError('required') && form2.get('mail').touched" class="text-center">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </div>
        <div class="danger" *ngIf="form2.get('mail').hasError('email')">
          <small class="p-error">El correo es <strong>inválido</strong></small>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="" class="label-input">Nro. documento</label>
        <div class="col-12">
          <input type="text" pInputText formControlName="doc" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            title="Número de Documento"/>
        </div>
        <div class="danger" *ngIf="form2.get('doc').hasError('required') && form2.get('doc').touched" class="text-center">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <button [disabled]="form2.invalid || isSubmitting" (click)="onButtonClick2()" class="submit-login btn">
          Enviar
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Ingresar" [modal]="true" [position]="'top'" [transitionOptions]="'500ms'"
  [(visible)]="visibleModalLogin" [style]="{ width: '50vw' }">
  <form [formGroup]="form" (ngSubmit)="login()">
    <div class="row mt-3">
      <div class="col-12">
        <label for="user"><span>Número de documento</span></label>
        <input type="text" [style]="{ width: '100%' }" pInputText formControlName="user"
          class="input p-inputtext p-component p-element"  title="Número de Documento" maxlength="8" oninput="this.value = this.value.replace(/[^0-9]/g, '')"/>
        <div class="danger" *ngIf="form.get('user').hasError('required') && form.get('user').touched" class="text-center">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <label for="password"><span>Contraseña</span></label>
        <input type="password" [style]="{ width: '100%' }" pInputText formControlName="password"
          class="input p-inputtext p-component p-element"  title="Contraseña"/>
        <div class="danger" *ngIf="form.get('password').hasError('required') && form.get('password').touched" class="text-center">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <span>
          <a (click)="forgotPasswordVisible()" class="forget-password">Olvide mi contraseña</a>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <button [disabled]="form.invalid" (click)="onButtonClick()" class="submit-login btn ">
          Ingresar >
        </button>       
      </div>
    </div>
  </form>
</p-dialog>
<div class="clsfix"></div>