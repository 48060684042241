import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  ListCompany,
  ListQuoteDetail,
} from "src/app/modules/polizas/interfaces/companies.interface";
import { sendCombos } from "src/app/modules/shell/interfaces/sendComboQuoted.interface";
import { dataCar } from "../../interfaces/car.interface";
import { addressCar } from "../../interfaces/address.interface";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Combo } from "src/app/modules/shell/interfaces/combo.interface";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import { MessageService } from "primeng/api";
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";
@Component({
  selector: "app-contratacion-finalizar",
  templateUrl: "./contratacion-finalizar.component.html",
  styleUrls: ["./contratacion-finalizar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ContratacionFinalizarComponent implements OnInit {
  company: ListCompany;
  quoteDetailsCompany: ListQuoteDetail;
  dataCar: dataCar;
  addressCar: addressCar;
  budgetId: any;
  currentUser: any;
  visiblePopUpContratacion = false;
  form: FormGroup;
  form2: FormGroup;
  financialEntity: Combo[] = [];
  bankingEntity: Combo[] = [];
  activeIndex: number = 0;
  comboReceived: sendCombos = history.state.comboReceived;

  spinner = true;

  constructor(
    private formBuilder: FormBuilder,
    private budgetManagerService: BudgetManagerService,
    private userManagerService: UserManagerService,
    private messageService: MessageService,
    private router: Router,
    private utilitiesService: UtilitiesService
  ) {

    this.form = this.formBuilder.group({
      numero: [null, [Validators.required,Validators.pattern(/^\d{16}$/)]],
      vencimiento: ["", [Validators.required]],
      entidad: ["", [Validators.required]],
    });
    this.form2 = this.formBuilder.group({
      cbu: ["", [Validators.required,Validators.pattern(/^\d{22}$/)]],
      banco: ["", [Validators.required]],
    });
    
  }
  longitudTarjeta = 16; 

  longitudTarjetaValidator(): any {

    return (control) => {
      const value = control.value || '';
      const soloNumeros = /^\d+$/;

      if (!soloNumeros.test(value) || value.length !== this.longitudTarjeta) {
        return { 'longitudTarjetaInvalida': true };
      }

      return null;
    };
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userManagerService.GetUserData();
    
    this.company = history.state.company;
    this.quoteDetailsCompany = history.state.quoteDetailsCompany;
    this.dataCar = history.state.dataCar;
    this.addressCar = history.state.addressCar;
    this.budgetId = history.state.budgetId;

    this.filterCombo('ComboBankingEntity','WayPaymentCode',{Code: '4', Field1: null, Field2: null, Value: 'TARJETA CREDITO'},'banking');
    this.filterCombo('ComboBankingEntity','WayPaymentCode',{Code: '7', Field1: null, Field2: null, Value: 'DEBITO EN CUENTA'},'banking');
    
    this.spinner = false;
  }

  banking = "";
  filters: any[] = [];

  query: any[] = [];

  async filterCombo(
    comboType: any,
    col: any = "",
    value: any = "",
    formCamp?: string
  ) {

    let filtros = "";

    this.query = [];

    let find = this.filters.find((d) => d.col == "filters");
    if (find) {
      let index = this.filters.indexOf(find);
      this.filters.splice(index, 1);
    }

    let findFil = this.filters.find((d) => d.col == col);
    if (findFil) {
      let index = this.filters.indexOf(findFil);
      this.filters.splice(index, 1);
    }

    if (col == "WayPaymentCode") {
      this.banking = value.Code;
      this.filters.push({ col, value: col + "=" + this.banking });
    }

    const values = this.filters.map((filter) => filter.value);

    filtros = values.join(";");

    if (comboType) {
      this.query.push({ col: "comboType", value: comboType });
      this.query.push({ col: "filters", value: filtros });

      if (value.Code == '4') {
        this.financialEntity = await this.getCombo();
        
      }else{
        this.bankingEntity = await this.getCombo();
      }
      
    }
  }

  async getCombo() {
    return await this.budgetManagerService.getCombo(this.query);
  }

  
  currentDate = new Date();

  send() {
               
     let object: any = {
      BudgetId: this.budgetId,
      Chassis: this.dataCar.chassis,
      InspectionData: {  
        Calle: this.addressCar.street,
        CodigoPostal: this.addressCar.postalCode,
        FechaDesde: "",
        FechaHasta: "", 
        Id: this.currentUser.Id,
        Localidad: this.comboReceived.localitie.Value,
        Lugar: this.comboReceived.province.Value,
        Numero: this.addressCar.numberStreet,
        Observacion: "",
        Provincia: this.comboReceived.province.Value,
        Telefono: this.currentUser.Telefono,
      },
      Motor: this.dataCar.engine,
      Patent: this.dataCar.patent,
      QuoteDetailId: this.quoteDetailsCompany.Id,
      WayPayment: {
        EntidadBancaria: "", //banco o entidad financiera
        NumeroCbuTarjeta: "",
        VencimientoTarjeta: "",
        CodTipoViaDeCobro:"",
      },
    };
    if (this.activeIndex === 0) {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        object.WayPayment.EntidadBancaria = (this.form.get("entidad").value) ? this.form.get("entidad").value.Value : "";      
        object.WayPayment.NumeroCbuTarjeta = this.form.get("numero").value;
        object.WayPayment.VencimientoTarjeta = String(new Date(this.form.get("vencimiento").value).getMonth() + 1).padStart(2, "0") + new Date(this.form.get("vencimiento").value).getFullYear();
        object.WayPayment.CodTipoViaDeCobro = 4;
        
        this.contratar(object);
        this.spinner = true;
      }
      
    } else if (this.activeIndex === 1) {
      this.form2.markAllAsTouched();
      if (this.form2.valid) {
        object.WayPayment.EntidadBancaria = this.form2.get("banco").value ? this.form2.get("banco").value.Value : "";      
        object.WayPayment.NumeroCbuTarjeta = this.form2.get("cbu").value;
        object.WayPayment.VencimientoTarjeta = ""
        object.WayPayment.CodTipoViaDeCobro = 7;

        this.contratar(object);
        this.spinner = true;
      }
    }
  }
  showPopUpSeeDetails() {
    this.visiblePopUpContratacion = true;
  }

  contratar(object) {
    this.budgetManagerService.issueOrderForClient(object).subscribe({
      next: (data) => {
        this.router.navigate(["/contratacion/poliza-contratada"], {
          state: {
            quoteDetailsCompany: this.quoteDetailsCompany,
            company: this.company,
            comboReceived: this.comboReceived,
            addressCar: this.addressCar,
            dataCar: this.dataCar,
            budgetId: this.budgetId,
            policyId: data.PolicyId,
            dataPolicy: object,
            payPalMethod: this.form.get('entidad').value ? this.form.get('entidad').value.Value : "",
          },
        });
        this.spinner = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
        this.spinner = false;
      },
      complete: () => {
        console.log("subcribe completo");
      },
    });
  }

  volver() {
    let data = {
      quoteDetailsCompany: this.quoteDetailsCompany,
      company: this.company,
      addressCar: this.addressCar,
      dataCar: this.dataCar,
      budgetId: this.budgetId,
      volver: true
    }    
    this.utilitiesService.redirectToHome('/contratacion/automovil', this.comboReceived, data);
  }
}
