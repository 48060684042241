import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsejoPrecioSeguroComponent } from './pages/consejo-precio-seguro/consejo-precio-seguro.component';
import { ConsejoContratarSeguroComponent } from './pages/consejo-contratar-seguro/consejo-contratar-seguro.component';
import { ConsejoCompararComponent } from './pages/consejo-comparar/consejo-comparar.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '1', component: ConsejoPrecioSeguroComponent },
      { path: '2', component: ConsejoContratarSeguroComponent },
      { path: '3', component: ConsejoCompararComponent },
      { path: '**', redirectTo: '' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsejoRoutingModule { }
