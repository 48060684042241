import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MiPerfilComponent } from "./pages/mi-perfil/mi-perfil.component";
import { TableDescriptionUserComponent } from "./components/table-description-user/table-description-user.component";
import { PrimeNgModule } from "src/app/shared/prime-ng/prime-ng.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from 'src/app/shared/components/shared.module';
import { RegistrarseComponent } from './components/registrarse/registrarse.component';
import { ValidarCodigoComponent } from './components/validar-codigo/validar-codigo.component';
import { PopUpDetailTerminosYCondicionesComponent } from './components/pop-up-detail-terminos-y-condiciones/pop-up-detail-terminos-y-condiciones.component';

@NgModule({
  declarations: [MiPerfilComponent, TableDescriptionUserComponent, RegistrarseComponent, ValidarCodigoComponent, PopUpDetailTerminosYCondicionesComponent],
  imports: [CommonModule,   FormsModule,
    ReactiveFormsModule, PrimeNgModule,SharedModule],
  exports: [
    MiPerfilComponent,TableDescriptionUserComponent
  ],
})
export class UserModule {}
