<p-card class="card">
  <ng-template pTemplate="header">
    <img
      src="https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg"
      alt=""
      class=""
    />
  </ng-template>
  <ng-template pTemplate="title"
    ><img
      widht="21px"
      height="19px"
      src="../../../../../assets/img/boton-asyg.svg"
      alt=""
      srcset=""
    />
    <span class="price">{{ premio.price }}</span>
  </ng-template>
  <ng-template pTemplate="subheader"
    ><span class="description">{{ premio.description }}</span></ng-template
  >
</p-card>
