import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
  ListCompany,
  ListQuoteDetail,
} from "../../../modules/polizas/interfaces/companies.interface";
import { Router } from "@angular/router";
import {  sendCombos } from "src/app/modules/shell/interfaces/sendComboQuoted.interface";

@Component({
  selector: "app-pop-up-detail-poliza",
  templateUrl: "./pop-up-detail-poliza.component.html",
  styleUrls: ["./pop-up-detail-poliza.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PopUpDetailPolizaComponent implements OnInit {
  @Input()
  quoteDetailsCompany: ListQuoteDetail;

  @Input() company: ListCompany;
  @Input() companies: ListCompany[] = [];
  @Input() comboReceived: sendCombos;
  @Input() budgetId:any;
  @Input() contratarBtn=true;
  
  spinner = true;
  userNotLoged: boolean = true;
  currentUser = JSON.parse(localStorage.getItem("user")) || "";
  
  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.currentUser) {
      this.userNotLoged = false
    }  
  }

  contratar() {
    if (!localStorage.getItem("tokenAsegurayGana")) {
      this.router.navigate(["/contratacion/ingresar"], {
        state: {
          quoteDetailsCompany: this.quoteDetailsCompany,
          company: this.company,
          companies: this.companies,
          comboReceived: this.comboReceived,
          budgetId: this.budgetId
        },
      });
    }else{
      this.router.navigate(["/contratacion/automovil"], {
        state: {
          quoteDetailsCompany: this.quoteDetailsCompany,
          company: this.company,
          companies: this.companies,
          comboReceived: this.comboReceived,
          budgetId: this.budgetId
        },
      });
    }
  }
}
