<div class="container mb-5">
    <div class="row my-4">
        <div class="col">
            <div class="text-start">
                <a href="" class="text-dark text-decoration-none title-blue" pTooltip="Volver">
                    <i class="bi bi-arrow-left h1" routerLink="/home"></i>
                </a>
                <div class="text-center">
                    <h1 class="text-center d-inline my-4 title-blue">“ASEGURÁ Y GANÁ ©”. TÉRMINOS Y CONDICIONES</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3">

        <div class="col col-12">
            <p class="content">
                Los siguientes términos y condiciones de uso (los “Términos y Condiciones”) regirán los servicios que COMPARADOR DIGITAL S.A. SOCIEDAD ANÓNIMA DE PRODUCTORES ASESORES DE SEGUROS, 
                inscripta en el Registro de Productores Asesores de Seguros de la Superintendencia de Seguros de la Nación (“SSN”) bajo el N°1782, CUIT 30716659808 (la “Empresa”) prestará a Ud. 
                en su carácter de usuario (el “Usuario”) del sitio web <a>www.aseguraygana.com</a> (el “Sitio”), quedando el Usuario sujeto a aquéllos por el uso que realice de tal Sitio.
            </p>
            <p class="content">
                En el sitio la Empresa ofrece un servicio de comparación de productos y servicios de seguros, y sus respectivos precios, por compañías de seguros autorizadas a 
                funcionar por la SSN (la “Aseguradora” o “Aseguradoras”) permitiendo al Usuario la elección de aquellos que a su exclusivo criterio considere que responden a su 
                interés, y pueda acceder a la contratación con la respectiva Aseguradora (los “Servicios”).
            </p>
            <p class="content">
                La Empresa es un intermediario en la contratación de seguros, en los términos de la Ley N°22.400 de Productores de Seguros, NO ES UNA COMPAÑÍA DE SEGUROS Y 
                POR LO TANTO NO TIENE RESPONSABILIDAD POR EL CUMPLIMIENTO QUE LA O LAS ASEGURADORAS CONTRATADAS POR EL USUARIO, REALICEN DEL RESPECTIVO CONTRATO DE SEGURO, 
                O POR EL CUMPLIMIENTO POR PARTE DEL USUARIO RESPECTO DE SUS DEBERES DE INFORMACIÓN A LA O LAS ASEGURADORAS RESPECTO DEL BIEN O BIENES ASEGURADOS, 
                SINIESTROS ACAECIDOS, ETC.
            </p>
            <p class="content">
                El Usuario leerá atentamente y deberá comprender y aceptar estos Términos y Condiciones, previo a utilizar el Sitio y los Servicios en él incluidos.
            </p>
        </div>

        <div class="col col-12">
            <p class="subtitle">
                1. Condiciones de Uso.
            </p>
            <ol class="content" type="a">
                <li>Acceso y/o Uso de los Servicios<br>
                    <p class="content">
                        El acceso y/o uso del Sitio sólo podrá tener lugar por parte de personas humanas y/o jurídicas con capacidad legal para contratar conforme a 
                        la ley vigente en  la República Argentina y que cumplan con los procedimientos previstos en estos Términos y Condiciones.
                    </p>
                </li>
                <li>Declaraciones del Usuario. Usos Prohibidos.<br>
                    <p class="content">
                        El Usuario, declara conocer y aceptar expresamente que: la Empresa tiene plena libertad para determinar las Compañías cuyos productos podrán ser comparados en 
                        el Sitio, debido a lo cual el Usuario no podrá solicitar la exclusión de ciertas compañías, marcas, bienes y Servicios del Sitio.
                    </p>
                    <p class="content">
                        Los Usuarios no podrán: (a) manipular la información contenida en las cotizaciones e información proporcionada por las Compañías; (b) asociar el contenido 
                        de las cotizaciones con material o contenidos prohibidos por la legislación vigente en la República Argentina, con mensajes difamatorios, contrarios al honor, 
                        la libertad, las creencias religiosas, el respeto de las etnias y de las personas en general o que, de cualquier otra manera pudieran perjudicar la imagen de 
                        la Empresa, sus controlantes, controladas y filiales o de las Compañías cuyos productos puedan ser comparados en el Sitio; (c) usar las cotizaciones y/o 
                        información recibida de las Compañías a través del Sitio, para fines distintos a los comprendidos en los Servicios, (d) utilizar los Servicios para actividades 
                        vinculadas al lavado de activos y financiación del terrorismo, (e) utilizar los Servicios para cualquier fin contrario a la ley, la moral o las buenas 
                        costumbres, (f) utilizar los Servicios de cualquier otra forma que no sea para la de consumidor final de los productos y servicios ofrecidos, (g) publicar, 
                        transmitir, copiar, modificar, crear trabajos derivados, distribuir, vender, o volver a publicar cualquier cosa que usted obtenga o descarga en el sitio 
                        de la Empresa y (h) participar en la recuperación sistemática de datos u otro contenido de este Sitio.
                    </p>
                </li>
                <li>Violaciones del Sistema o Bases de Datos. Sistema de Monitoreo.<br>
                    <p class="content">
                        Asimismo, es prohibida toda acción o uso de dispositivo, software, u otro medio tendiente a interferir en las actividades y operatoria de la Empresa, 
                        incluyendo sus cuentas o bases de datos. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad 
                        intelectual y/o a las prohibiciones estipuladas en estos Términos y Condiciones darán lugar a las acciones legales pertinentes, y a las sanciones 
                        previstas por este acuerdo y harán al infractor responsable de indemnizar los daños ocasionados.
                    </p>
                </li>
                <li>Propiedad Intelectual.<br>
                    <p class="content">
                        Con excepción de la información publicada como teniendo fuente en terceros, todos los materiales en la Página, incluyendo, sin que la enumeración 
                        sea limitativa, el logotipo de Asegurá y Ganá y otras marcas comerciales relacionadas constituye propiedad de la Empresa y/o sus afiliadas o 
                        licenciantes y están protegidos por derechos de autor y de marca internacional establecidos por las leyes y normativa aplicable. 
                        Todos los derechos reservados.
                    </p>
                    <p class="content">
                        Asimismo, los programas, así como las bases de datos (sin perjuicio de lo previsto en el apartado 2) de estos Términos y Condiciones de Uso, 
                        sobre el Tratamiento de los Datos Personales del Usuario), redes, archivos que permiten al Usuario utilizar el Sitio, son de propiedad de la Empresa 
                        y se encuentran protegidos por las leyes de la República Argentina y tratados internacionales de derecho de autor, marcas, patentes, modelos y 
                        diseños industriales. El uso indebido y la reproducción total o parcial de los mismos queda prohibido, salvo autorización expresa y por escrito de la Empresa.
                    </p>
                    <p class="content underline">
                        Específicamente se prohíbe al Usuario, con relación al Sitio:
                    </p>    
                    <ol type="i" class="ol-roman">
                        <li class="content">
                            <p>
                                Utilizarlo para fines diversos de los establecidos en los Términos y Condiciones y en las políticas de la Empresa.
                            </p>        
                        </li>
                        <li class="content">
                            <p>
                                Realizar adaptación o modificación alguna al Sitio que pudiere alterar el uso y/o funcionamiento, o realizar obras derivadas del mismo.
                            </p>        
                        </li>
                        <li class="content">
                            <p>
                                Realizar pruebas de "black-box", practicar ingeniería inversa o descompilación o valerse de cualquier método o técnica para acceder al 
                                código fuente o a elementos del Portal.
                            </p>        
                        </li>
                    </ol>
                </li>
                <li>Páginas Web de Terceros.<br>
                    <p class="content">
                        El Sitio puede contener enlaces a páginas web de terceros ("Páginas de Terceros"), o tales Páginas de Terceros pueden tener vínculos al Sitio. 
                        Estos enlaces se ofrecen exclusivamente con fines informativos. Las páginas de terceros son mantenidas por sus respectivas compañías, siendo 
                        éstas las únicas responsables del contenido e información en ellas. La Empresa no verifica ni ofrece ninguna garantía o declaración sobre el 
                        contenido, la exactitud, opiniones expresadas, garantías, productos o servicios, el cumplimiento de la propiedad intelectual, o enlaces de 
                        dichas páginas de terceros. El Usuario deberá leer las políticas de privacidad y los términos de los acuerdos de uso de las páginas de terceros.
                    </p>
                </li>  
                <li>Licencia limitada.<br>
                    <p class="content">
                        Todos los derechos de propiedad intelectual e industrial y cualquier otro de similar naturaleza, sobre el Sitio, sus funcionalidades, códigos, 
                        desarrollos, software, hardware, dominio, logos, emblemas, logotipos, diseños, estructura, contenidos, información, entre otros, son de propiedad 
                        de la Empresa. En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos.
                    </p>
                </li>  
            </ol>
            <p class="content"></p>
        </div>

        <div class="col col-12">
            <p class="subtitle">
                2. Tratamiento de Datos Personales. Política de Privacidad.
            </p>
            <ol class="content" type="a">
                <li>Principio General.<br>
                    <p class="content">
                        La Empresa adopta medidas para la protección de los datos de carácter personal del Usuario, incluido la recolección, utilización, almacenamiento, 
                        comunicación y protección de tales datos.
                    </p>
                    <p class="content">
                        El Usuario garantizará y responderá, en cualquier caso, por la veracidad, exactitud, vigencia y autenticidad de los datos ingresados. 
                        La Empresa no se responsabiliza por la exactitud, vigencia, veracidad y autenticidad de los datos provistos por el Usuario.
                    </p>
                    <p class="content">
                        La Empresa se reserva el derecho de solicitar información y/o documentación adicional a fin de corroborar la veracidad, exactitud y 
                        vigencia de la información proporcionada por el Usuario, incluyendo medios para su identificación.
                    </p>
                    <p class="content">
                        La Empresa podrá confirmar los datos personales suministrados acudiendo a fuentes de organismos públicos, bases de datos de compañías 
                        especializadas y/o centrales de riesgo, a cuyo efecto el Usuario autoriza expresamente a la Empresa para que a tales fines utilice y 
                        comparta los datos que reciba del Usuario.
                    </p>
                    <p class="content">
                        El Usuario podrá siempre solicitar a la Empresa el acceso, actualización, modificación, rectificación y/o cancelación de sus datos personales, 
                        en los términos de la Ley N°25.326 y normativa complementaria aplicable de la Dirección Nacional de Protección de Datos Personales, utilizando 
                        únicamente el canal incluido en la página web de la Empresa <a>www.aseguraygana.com</a> y acreditando debidamente su identidad, de conformidad con 
                        los arts. 14, 16 y ccs. de la ley citada.
                    </p>
                    <p class="content">
                        La Empresa podrá transmitir a la respectiva Compañía la información de identificación del Usuario, como así también la del bien o bienes a asegurar, 
                        riesgos comprendidos, etc. recibida del Usuario, en cumplimiento de sus funciones bajo el art. 11 de la Ley N°22.800 que rige su actividad. Asimismo, 
                        podrá utilizar tal información a los efectos de poder identificar o predecir patrones de consumo y ofrecer productos de seguro que respondan a tales patrones.
                    </p>
                </li>
                <li>Consentimiento expreso.<br>
                    <p class="content">
                        El Usuario consiente expresamente en que:
                    </p>
                    <ol type="i">    
                        <li class="content">
                            <p>
                                La información proporcionada por él al momento de acceder y/o utilizar el Sitio podrá ser tratada por la Empresa y comunicada por la Empresa 
                                a las Compañías, junto con la demás información que aquí se detalla con el objeto de que aquél pueda ser contactado por las Compañías, concretar 
                                la solicitud y la contratación de los servicios cotizados, pudiendo tal contacto ser también gestionado por la Empresa.
                            </p>        
                        </li>
                        <li class="content">
                            <p>
                                La Empresa se comunique con él a través de cualquiera de los medios señalados por el Usuario al momento de ingresar sus datos al Sitio, 
                                o por cualquier otro medio de que la Empresa disponga, a efectos de: (i) asesorarlo y orientarlo en la cotización de los productos, (ii) 
                                encuestarlo sobre la calidad del Servicio, (iii) verificar la efectividad de la contratación del producto seleccionado (iv) otros fines 
                                relacionados a los Servicios utilizados por el Usuario.
                            </p>        
                        </li>
                        <li class="content">
                            <p>
                                En el caso que la Empresa y/o sus sociedades controladas o vinculadas, o sustancialmente todos sus activos fueran adquiridos por terceros, 
                                incluyendo supuestos de insolvencia o quiebra, la información del Usuario almacenada en los archivos de la Empresa sea uno de los activos 
                                que se transfieran o sean adquiridos por tales terceros. Usted reconoce que dicha transferencia puede ocurrir y que el adquiriente de la 
                                Empresa y/o de sus sociedades controladas o vinculadas podrá seguir utilizando su información personal como se establece en sus propias políticas.
                            </p>        
                        </li>
                        <li class="content">Publicación del Acto de Entrega de premios.
                            <p>
                                En los casos en que se realicen sorteos, la Empresa publicará en el Sitio, exclusivamente a los fines de transparencia, el nombre completo y 
                                DNI de los Usuarios ganadores de los sorteos que realice, junto al número ganador y la respectiva fecha del sorteo de la Lotería de la 
                                Ciudad de Buenos Aires o de la institución similar aplicable. Asimismo, en caso de que durante el acto de entrega del premio se tome una foto, 
                                el Usuario consiente en que tal foto pueda ser publicada, a fines promocionales junto con la información precedentemente citada.
                            </p>        
                        </li>
                    </ol>
                </li>
                <li>Recolección de Datos.<br>
                    <p class="content">
                        La Empresa almacena automáticamente los datos del Usuario, por ejemplo, cuando éste se contacta con la Empresa ya sea personalmente o respondiendo 
                        a preguntas por correo, fax, a través del Sitio o por teléfono. Asimismo, la Empresa puede recolectar información cuando el Usuario visita el Sitio. 
                        Esta información puede incluir la dirección IP de la computadora desde la que se conecta el Usuario, la información sobre el sistema operativo y 
                        navegador utilizado, el sitio desde el cual el Usuario llegó al Sitio o la hora y fecha de su visita y actividad, todo esto únicamente a los fines 
                        de la administración y seguridad del sistema de la Empresa.
                    </p>
                </li>
                <li>Uso de “cookies”.<br>
                    <p class="content">
                        Este Sitio utiliza cookies. Al acceder al Sitio, el Usuario autoriza a la Empresa el uso de cookies de conformidad con los términos de esta política. 
                        Las cookies son archivos enviados por los servidores web a los navegadores web y almacenados en el sistema del Usuario. La información se reenvía al 
                        servidor cada vez que el navegador solicita una página del servidor. Esto permite a un servidor web identificar y realizar un seguimiento de los navegadores web.
                    </p>
                    <p class="content">
                        Si el Usuario lo desea puede eliminar el uso de cookies de almacenamiento de información de manera voluntaria, en cualquier momento, a través de la 
                        configuración en su navegador. Debe tener en cuenta que esto podría provocar que el Sitio no funcione en todo su potencial.
                    </p>
                </li>
                <li>Comunicación de Datos Personales por la Empresa.<br>
                    <p class="content">
                        La Empresa no vende, arrienda, comunica o transmite a terceros -excluyendo la transmisión a compañías controladas y/o vinculadas de la Empresa- 
                        los datos personales recibidos por aquélla por parte del Usuario, y salvo el supuesto en que deba cumplir con un requerimiento de autoridad judicial 
                        y/o administrativa.
                    </p>
                    <p class="content">
                        Sin perjuicio de lo anterior, la Empresa podrá comunicar a las Compañías, la información necesaria a efectos de obtener las cotizaciones solicitadas 
                        por los Usuarios a través del Sitio, para lo cual el Usuario presta su expresa conformidad. La Empresa no tiene responsabilidad alguna por la 
                        información adicional que el Usuario provea directamente a las Compañías. El Usuario deberá familiarizarse con las políticas y prácticas de protección 
                        de datos de las Compañías, antes de proporcionarle información y/o datos personales.
                    </p>
                    <p class="content">
                        Todas las comunicaciones entre los Usuarios y la Empresa se encuentran encriptadas por HTTPS siguiendo las normas aceptadas por la industria y 
                        ofreciendo un grado alto de seguridad de la información transferida.
                    </p>
                    <p class="content">
                        Toda la información almacenada por la Empresa se guarda en servidores protegidos por estrictas medidas de seguridad.
                    </p>
                </li>
                <li>Seguridad. Prevención del “Phishing” y otras prácticas fraudulentas.<br>
                    <p class="content">
                        El "Phishing" consiste en una modalidad de fraude electrónico cuya finalidad es robar datos personales mediante correos electrónicos, mensajes 
                        o llamados telefónicos muy convincentes que pueden aparentar provenir de la Empresa o alguna empresa relacionada, pero no lo son, solicitando 
                        datos como nombres de Usuario, contraseñas, cédula de identidad, entre otros.
                    </p>
                    <p class="content">
                        Para evitar ser víctima de este tipo de fraudes, el Usuario debe tener presente:
                    </p>
                    <ol type="i">    
                        <li class="content">
                            <p>
                                No responder a correos electrónicos, mensajes o llamados telefónicos que le soliciten información personal, un funcionario o dependiente 
                                de la Empresa NUNCA le pedirá su clave personal. Desconfiar de correos electrónicos cuya procedencia desconozca, los cuales sugerimos 
                                no abrir y eliminar de inmediato. Si los lee, recomendamos no abrir archivos adjuntos, ésta es la forma más común de contaminar con virus 
                                o software malicioso un computador.
                            </p>        
                        </li>
                        <li class="content">
                            <p>
                                En caso de dudas o ser afectado por un fraude, comunicarse con la Empresa a través de los medios de contacto establecidos en el Sitio.
                            </p>        
                        </li>
                    </ol>
                </li>
            </ol>
        </div>

        <div class="col col-12">
            <p class="subtitle">
                3. No garantía.
            </p>
            <ol class="content" type="a">
                <li>
                    <p class="content">
                        El Usuario declara que utilizará el Sitio y contratará los Servicios por su cuenta y riesgo, el cual es puesto a su disposición en el estado en que se 
                        encuentra y sin que la Empresa formule garantía expresa o implícita de ningún tipo.
                    </p>            
                    <p class="content">
                        La Empresa no formula garantía alguna con relación al Sitio y los Servicios, incluyendo pero no limitadas a, garantías de comerciabilidad, calidad 
                        satisfactoria o rendimiento para cualquier propósito. Bajo ninguna circunstancia la Empresa será responsable ante el Usuario, o alguna otra persona, 
                        por daños emergentes directos, indirectos o remotos, lucro cesante o daño moral de cualquier tipo, incluido pero no limitado a daños por pérdida de 
                        beneficios comerciales, interrupción de las actividades comerciales, pérdida de información comercial, fallas o mal funcionamiento de los computadores 
                        y/o comunicaciones telefónicas, así como por cualquier otra clase de daños o pérdidas comerciales, aun cuando la Empresa haya sido informada de la 
                        posibilidad de tales daños.
                    </p>            
                </li>
                    <p class="content">
                        Además del hipervínculo a los sitios web de las Compañías, el Portal podrá contener enlaces a otros sitios Web lo cual no indica que, en uno u otro caso, 
                        tales sitios sean propiedad u operados por la Empresa. En virtud de que la Empresa no tiene control sobre tales sitios, nunca será responsable por 
                        los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, 
                        sean causadas directa o indirectamente.
                    </p>            
                <li>
                    <p class="content">
                        La Empresa no garantiza el acceso y uso continuado e ininterrumpido del Portal o de los Servicios. El Portal puede eventualmente no estar disponible debido 
                        a dificultades técnicas o fallas de Internet o por cualquier otra circunstancia ajena a la Empresa. En caso de que las señaladas dificultades técnicas 
                        incidieran directamente en la Cotización entregada al Usuario, produciendo una diferencia del precio informado en la Cotización, la Empresa comunicará 
                        al Usuario respectivo su facultad de solicitar la anulación del pago y de la contratación realizada. Salvo el caso de excepción recién señalado, el Usuario 
                        deberá cumplir las obligaciones que hubiere asumido al aceptar la Cotización, sin que pueda imputar responsabilidad alguna a la Empresa, ni exigir indemnización 
                        alguna, en virtud de posibles perjuicios resultantes de las mencionadas dificultades, así como por cualquier otra clase de daños.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        La Empresa no garantiza al Usuario retorno ni utilidad alguna.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        La Empresa no garantiza al Usuario ahorro de dinero de ningún tipo ya que los Servicios sólo suponen poner a disposición del Usuario las Cotizaciones elaboradas 
                        por las Compañías de acuerdo a los datos ingresados por el Usuario en el Portal en forma totalmente objetiva e imparcial sin tratar de influir de ninguna forma 
                        en la decisión del Usuario. Debido a lo anterior, la responsabilidad por la elección de un producto por parte del Usuario recae en forma exclusiva en él.
                    </p>            
                </li>
            </ol>
        </div>

        <div class="col col-12">
            <p class="subtitle">
                4. Indemnidad.
            </p>
            <p class="content">
                El Usuario indemnizará a la Empresa y sus empleados y agentes, incluyendo honorarios legales, con respecto a toda demanda, sentencia condenatoria, multas, reclamo, sanción, 
                embargo, y medidas precautorias que pudieran derivarse de incumplimientos por parte del Usuario a las leyes de propiedad intelectual e industrial con relación al uso del 
                programa computacional y equipos necesarios para el funcionamiento del Sitio y los Servicios, como así también a derechos de terceros incluyendo las Compañías, que hubieren 
                derivado en reclamos, sentencias, multas, medidas precautorias o sanciones sobre la Empresa.
            </p>            
        </div>

        <div class="col col-12">
            <p class="subtitle">
                5. Modificaciones. Vigencia. Información al Usuario.
            </p>
            <p class="content">
                Estos Términos y Condiciones estarán vigentes en tanto y en cuanto la Empresa no los modifique o derogue. Pueden ser modificados por la Empresa en cualquier momento, 
                comunicándolo a los Usuarios, quienes tendrán en toda ocasión la facultad de dejar de utilizar el Sitio.
            </p>            
            <p class="content">
                La Empresa mantendrá informado al Usuario mediante correos electrónicos, anuncios o avisos que pondrá a su disposición en el Sitio o mediante comunicaciones a la dirección 
                que el Usuario hubiera incluido al registrarse en el Sitio. Si no desea recibir los correos mencionados, el Usuario podrá cancelar la suscripción siguiendo el enlace al 
                final de cada correo. Asimismo, cualquier información o comunicación masiva que deba efectuarse a los Usuarios en virtud de estos Términos y Condiciones podrá realizarse o 
                difundirse a través de los medios que la Empresa determine, incluyendo prensa escrita, televisión, sitio web, correo electrónico, correspondencia postal u otros.
            </p>            
            <p class="content">
                En caso de que la Empresa decida poner fin a la vigencia de los Términos y Condiciones, lo comunicará con al menos 30 (treinta) días corridos de antelación a la fecha en 
                que prevea su terminación, por las vías de información establecidas en estos Términos y Condiciones y en todo caso sin menoscabo a los beneficios que hubiere acordado con anterioridad a esa fecha.
            </p>            
        </div>

        <div class="col col-12">
            <p class="subtitle">
                6. Ley y Jurisdicción aplicable. Terminación.
            </p>
            <p class="content">
                Estos Términos y Condiciones y el uso del Sitio por Ud. se regirán en un todo por las leyes de la República Argentina, sin dar efecto a cualquier principio de conflicto de leyes.
            </p>            
            <p class="content">
                Toda cuestión o controversia relativa al uso del Sitio o a estos Términos y Condiciones estará sujeta a la jurisdicción exclusiva de los tribunales nacionales con competencia en 
                materia comercial, con domicilio en la Ciudad de Buenos Aires, República Argentina. En caso de que alguna disposición de estos Términos y Condiciones fuera considerada judicialmente 
                inválida o inexigible, dicha disposición será separable de y no afectará la validez o exigibilidad de las restantes disposiciones.
            </p>            
        </div>

        <div class="col col-12">
            <p class="subtitle">
                7. Acumulación y Vigencia de los Puntos.
            </p>
            <p class="content">
                Programa de Puntos.
            </p>
            <ol class="content" type="a">
                <li>
                    <p class="content">
                        Los clientes acumulan puntos por cada póliza vigente cuando es cobrada por la compañía de seguros. Si la póliza no es cobrada, no se otorgarán puntos.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        Si un cliente refiere a otros usuarios y tiene una póliza vigente, acumula puntos por las pólizas vigentes de dichos referidos siempre que estas sean cobradas.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        En el caso de que el cliente no tenga ninguna póliza vigente, la acumulación de puntos quedará suspendida hasta que el cliente adquiera una nueva póliza.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        Los puntos tienen una vigencia de 1 (UN) año a partir de la fecha de la más reciente asignación de puntos (ej. por pago bajo una póliza vigente o referencia 
                        de un tercero) bajo el Sistema. En caso de no haber nuevas asignaciones de puntos durante el plazo de 1 (UN) año, los puntos acumulados vencerán de manera 
                        automática y no podrán ser utilizados ni reclamados posteriormente.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        El vínculo con los referidos no caduca, y los puntos continuarán acumulándose por las pólizas de los referidos una vez que el cliente tenga nuevamente 
                        una póliza vigente.
                    </p>            
                </li>
            </ol>       
        </div>

        <div class="col col-12">
            <p class="subtitle">
                8. Sistema de Referidos.
            </p>
            <ol class="content" type="a">
                <li>
                    <p class="content">
                        El cliente podrá referir a otros usuarios a través de un enlace o código proporcionado por la plataforma. El referido deberá ingresar dicho código 
                        al momento de registrarse en la plataforma.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        Los puntos por referidos comenzarán a acumularse cuando el referido adquiera una póliza y ésta sea cobrada por la compañía de seguros.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        La acumulación de puntos por las pólizas de referidos estará sujeta a las mismas condiciones que las pólizas propias, es decir, 
                        deberán estar vigentes y haber sido cobradas.
                    </p>            
                </li>
            </ol>       
        </div>

        <div class="col col-12">
            <p class="subtitle">
                9. Condiciones para el Uso del Programa de Puntos.
            </p>
            <ol class="content" type="a">
                <li>
                    <p class="content">
                        El programa de puntos es exclusivo para clientes registrados con pólizas vigentes dentro del territorio de la República Argentina.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        Está estrictamente prohibido cualquier intento de manipulación o fraude en la obtención de puntos, como por ej. la creación
                        de cuentas falsas o el uso indebido del sistema de referidos. Si se detecta un mal uso, la cuenta del cliente podrá ser suspendida 
                        y los puntos acumulados anulados.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        En caso de cancelación de una póliza, el cliente dejará de acumular puntos por dicha póliza, pero podrá seguir acumulando por 
                        otras pólizas vigentes o futuras.
                    </p>            
                </li>
            </ol>
        </div>

        <div class="col col-12">
            <p class="subtitle">
                10. Modificaciones al Programa.
            </p>
            <p class="content">
                Modificaciones y Finalización del Programa.
            </p>
            <ol class="content" type="a">
                <li>
                    <p class="content">
                        Asegurá y Ganá se reserva el derecho de modificar o finalizar el programa de puntos en cualquier momento. Los clientes serán 
                        notificados con al menos 30 días de anticipación a través de los medios de comunicación proporcionados en la plataforma.
                    </p>            
                </li>
                <li>
                    <p class="content">
                        En caso de finalizar el programa, los puntos acumulados podrán ser utilizados dentro de un período de tiempo que será 
                        comunicado a los clientes en el momento de la notificación.
                    </p>            
                </li>
            </ol>       
        </div>

        <div class="col col-12">
            <p class="subtitle">
                11. Política de Privacidad - Tratamiento de Datos.
            </p>
            <p class="content">
                Recolección de Datos para el Programa de Puntos.
            </p>
            <ol class="content" type="a">
                <li>
                    <p class="content">
                        Los datos personales proporcionados por los clientes, incluidos aquellos relacionados con la acumulación de puntos 
                        y el sistema de referidos, serán utilizados conforme a las normativas de protección de datos personales de la 
                        República Argentina (Ley N° 25.326).
                    </p>            
                </li>
                <li>
                    <p class="content">
                        La plataforma recopila la información necesaria para la correcta administración del programa de puntos, 
                        incluyendo datos de las pólizas activas y referidos.
                    </p>            
                </li>
            </ol>                
        </div>
    </div>
</div>