import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contratacion',
  templateUrl: './contratacion.component.html',
  styleUrls: ['./contratacion.component.css']
})
export class ContratacionComponent implements OnInit {
  paso = 1;
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  formReferido: FormGroup;
  formCbu: FormGroup;
  formTarjeta: FormGroup;
  formTranferencia: FormGroup;
  selectedCombustible = 'Nafta';
  selectedConsumidorFinal: any;
  selectedDatos: any;
  datosFormulario3: any;
  anios = [
    { name: "Enero", value: "1" },
    { name: "Febrero", value: "2" },
    { name: "Marzo", value: "3" },
    { name: "Abril", value: "4" },
    { name: "Mayo", value: "5" },
    { name: "Junio", value: "6" },
    { name: "Julio", value: "7" },
    { name: "Agosto", value: "8" },
    { name: "Septiembre", value: "9" },
    { name: "Octubre", value: "10" },
    { name: "Noviembre", value: "11" },
    { name: "Diciembre", value: "12" },
  ]
  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.form1 = this.formBuilder.group({
      correo: ['username@gmail.com', []],
      contrasenia: ['123456789', []],
    })
    this.form2 = this.formBuilder.group({
      nombre: ['', []],
      apellido: ['', []],
      telefono1: ['', []],
      telefono2: ['', []],
      correo: ['', []],
      contrasenia: ['', []],
      confirmarContrasenia: ['', []],
    })
    this.form3 = this.formBuilder.group({
      marca: ['Chevrolet', []],
      anio: ['2011', []],
      modelo: ['Celta 1.4 5 Ptas', []],
      version: ['LS AA', []],
      patente: ['kjk43566', []],
      chasis: ['5354365', []],
      motor: ['34535435', []],
      numero: ['397', []],
      calle: ['Valentini', []],
      provincia: ['Buenos Aires', []],
      piso: ['2', []],
      codigoPostal: ['7600', []],
      localidad: ['Mar del plata', []],
    })
    this.formReferido = this.formBuilder.group({
      codigo: ['', []],
    })
    this.formTarjeta = this.formBuilder.group({
      numero: ['', []],
      titular: ['', []],
      codigo: ['', []],
      vencimientoAnio: ['', []],
      vencimientoMes: ['', []],
      codigoSeguridad: ['', []],
      dni: ['', []],
      banco: ['', []],
    })
    this.formCbu = this.formBuilder.group({
      cbu: ['', []],
    })

    this.formTranferencia = this.formBuilder.group({
      cuenta: ['', []],
    })
  }
  ngOnInit(): void {
  }
  ingresar() {
    this.paso = 2;
  }
  continuar() {
    this.datosFormulario3 = {
      marca: this.form3.get("marca")?.value,
      anio: this.form3.get("anio")?.value,
      modelo: this.form3.get("modelo")?.value,
      version: this.form3.get("version")?.value,
      patente: this.form3.get("patente")?.value,
      chasis: this.form3.get("chasis")?.value,
      motor: this.form3.get("motor")?.value,
      numero: this.form3.get("numero")?.value,
      calle: this.form3.get("calle")?.value,
      provincia: this.form3.get("provincia")?.value,
      piso: this.form3.get("piso")?.value,
      codigoPostal: this.form3.get("codigoPostal")?.value,
      localidad: this.form3.get("localidad")?.value,
      combustible: this.selectedCombustible
    }
    this.paso = 3;
  }

  contratar() {
    this.paso = 4;
  }
}
