<div class="container mb-5">
    <div class="row my-4">
        <div class="col">
            <div class="text-start">
                <a href="" class="text-dark text-decoration-none title-blue" pTooltip="Volver">
                    <i class="bi bi-arrow-left h1" routerLink="/home"></i>
                </a>
                <div class="text-center">
                    <h1 class="text-center d-inline my-4 title-blue">¡Bienvenido a nuestro Servicio al Cliente!</h1>
                    <p class="content">
                        Estamos aquí para ayudarte. Por favor, no dudes en contactarnos si tienes alguna pregunta o
                        necesitas asistencia.

                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col col-12">
            <p class="word-break subtitle">
                Información de Contacto:
            </p>

            <p class="content">
                E-mail : nuxi.servicioClientes@gmail.com
            </p>
            <p class="content">
                Telefono : (0800) 5456 7589

            </p>

        </div>
        <div class="col col-12">
            <p class="word-break subtitle">
                Envianos un mensaje!
            </p>

            <!--<form [formGroup]="formGroup">-->
            <form [style.width]="'100%'" class="row">
                <div class="col col-12 center">
                    <textarea rows="5" [style.width]="'80%'" cols="30" pInputTextarea formControlName="text"></textarea>
                </div>
                <div class="con col-12 center">
                    <div   [style.width]="'80%'" class="input-container" [style.text-align]="'center'"
                        [style.margin-top]="'1em !important'">
                        <p-button   [style.width]="'100%'" [style.margin-top]="'1em !important'" class="max" label="Enviar"></p-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>