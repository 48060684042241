import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { sendCombos } from 'src/app/modules/shell/interfaces/sendComboQuoted.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private router: Router) { }
  
  redirectToHome(url: string, comboReceived?: sendCombos, data?: any) {
    const navigationExtras: NavigationExtras = {
      state: {
          comboReceived: comboReceived || null,
          budgetId: data?.budgetId || null,
          addressCar: data?.addressCar || null,
          dataCar: data?.dataCar || null,
          companies: data?.companies || null,
          company: data?.company || null,
          quoteDetailsCompany: data?.quoteDetailsCompany || null,
          volver: data?.volver || false
      }
    };
    this.router.navigateByUrl(url, navigationExtras).then(() => {
      const homePageContainer = document.getElementById('cabecera');
      if (homePageContainer) {        
        homePageContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }    
  
}
