import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { Combo } from "src/app/modules/shell/interfaces/combo.interface";
import { sendCombos } from "src/app/modules/shell/interfaces/sendComboQuoted.interface";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";

@Component({
  selector: "app-table-description-user",
  templateUrl: "./table-description-user.component.html",
  styleUrls: ["./table-description-user.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TableDescriptionUserComponent implements OnInit {
  virtualScroll = false;
  @Input() user!: any;

  userForm: FormGroup;
  comboReceived: sendCombos;
  sex: Combo[] = [];
  stateCivil: Combo[] = [];
  documentType: Combo[] = [];
  localities: Combo[] = [];
  provinces: Combo[] = [];
  nacionality: Combo[] = [];
  query: any[] = [];

  inputCode = false;
  spinner = true;
  loading = false;
  
  constructor(
    private messageService: MessageService,
    private budgetManagerService: BudgetManagerService,
    private formBuilder: FormBuilder,
    private userManagerService: UserManagerService,
    private utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef
  ) {}
  
  async ngOnInit() {
    //this.filterCombo("ComboProvince", "", "");
    this.filterCombo("ComboSex");
    this.filterCombo("ComboCivilStatus");
    this.filterCombo("ComboNationality");
    
    this.comboReceived = history.state.comboReceived;
    if (this.comboReceived) {
      this.userForm.patchValue({
        province: this.comboReceived.province || '',
        localitie: this.comboReceived.localitie || '',
      });
    }

    const fechaNacimiento = this.user.FechaNacimiento ? moment(this.user.FechaNacimiento).format('DD/MM/YYYY') : null;
  
    this.userForm = this.formBuilder.group({
      nombre: [this.user.Nombre, [Validators.required]],
      apellido: [this.user.Apellido, [Validators.required]],
      telefonoOne: [this.user.Telefono, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      sex: [this.user.Sexo, [Validators.required]],
      stateCivil: [this.user.EstadoCivil, [Validators.required]],
      birthDate: [fechaNacimiento, [Validators.required]],
      province: [this.user.Provincia, [Validators.required]],
      localitie: [this.user.Localidad, [Validators.required]],
      calle: [this.user.Calle, [Validators.required]],
      numeroCalle: [this.user.Numero, [Validators.required, Validators.max(99999)]],
      piso: [this.user.Piso],
      depto: [this.user.Depto],
      numeroDocumento: [this.user.NumeroDocumento, [Validators.required, Validators.maxLength(8)]],
      mail: [this.user.Mail, [Validators.required, Validators.email]],
      cp: [this.user.CodigoPostal],
      nacionalidad: [this.user.Nacionalidad, Validators.required]
    });
    this.spinner = false;
  }
  //validacion de fechas
  dateErrorFormat: Set<string> = new Set();
  changeCalendar(event, campo) {
    let value = event.target.value;
    const patron: RegExp = /^\d{2}\/\d{2}\/\d{4}$/;
    if (patron.test(value)) {
      const date = moment(value, 'DD/MM/YYYY', true);
      this.userForm.controls[campo].setValue(date.toDate());
      this.dateErrorFormat.delete(campo);
    } else {
      this.dateErrorFormat.add(campo)
    }

  }
  selectCalendar(event, campo) {
    this.dateErrorFormat.delete(campo);
  }

  province = "";
  filters: any[] = [];

  filterCombo(
    comboType: any,
    col: any = "",
    value: any = "",
    formCamp?: string
  ) {
    this.cdr.detach();

    if (formCamp) {
      switch (formCamp) {
        case "province":
          this.provinceChange();
          break;
        default:
          break;
      }
    }

    let filtros = "";
    this.query = [];

    let find = this.filters.find((d) => d.col == "filters");
    if (find) {
      let index = this.filters.indexOf(find);
      this.filters.splice(index, 1);
    }

    let findFil = this.filters.find((d) => d.col == col);
    if (findFil) {
      let index = this.filters.indexOf(findFil);
      this.filters.splice(index, 1);
    }

    if (col == "Province") {
      this.province = value.Code;
      this.filters.push({ col, value: col + "=" + this.province });
      this.inputCode = false;
      this.localities = [];
    }

    const values = this.filters.map((filter) => filter.value);
    filtros = values.join(";");
    if (comboType) {
      let query = [];
      query.push({ col: "comboType", value: comboType });
      query.push({ col: "filters", value: filtros });
      this.getCombo(query, comboType);
    }
    this.cdr.reattach();
  }
  getCombo(query: any, comboType: any) {
    this.budgetManagerService.getComboObservable(query).subscribe((res) => {
      res = (res)?res.sort((a, b) => (a.Value > b.Value) ? 1 : -1):[];
      switch (comboType) {
        case "ComboProvince":
          this.userForm.get("province").disable();
          this.provinces = res;
          this.userForm.get("province").enable();
          this.userForm.get("province").setValue(this.provinces.find(p => p.Value === this.user.Provincia))
          this.filterCombo("ComboLocation", "Province", this.provinces.find(p => p.Value === this.user.Provincia));
          break;
        case "ComboLocation":
          this.userForm.get("localitie").disable();
          this.localities = res;
          this.userForm.get("localitie").setValue(this.localities.find(l => l.Value === this.user.Localidad));
          this.virtualScroll = this.localities.length > 3;
          this.userForm.get("localitie").enable();
          break;
          case "ComboSex":
            this.sex = res;
            break;
          case "ComboCivilStatus":
            this.stateCivil = res;
            break;
          case "ComboNationality":
            this.nacionality = res;
            break;
        default:
          break;
      }
    });
  }

  onLocalitieChange(event: any) {
    const selectedValue = event.value;
    this.userForm.controls["cp"].setValue(selectedValue.Code);
    this.inputCode = true;
  }

  provinceChange() {
    this.userForm.get("localitie").setValue(null);
    this.userForm.get("localitie").disable();
  }

  saveUser() {
    this.loading = true;
    this.spinner = true;
    let editedUser = {
      Apellido: "",
      Calle: this.userForm.get("calle").value,
      CodigoPostal: (this.userForm.get("localitie").value?.Code) ? (this.userForm.get("localitie").value?.Code) : this.userForm.get("cp").value,
      Depto: this.userForm.get("depto").value,
      EstadoCivil: "",
      FechaNacimiento: "",
      Id: this.user.Id,
      localStorage: true,
      Localidad: this.userForm.get("localitie").value?.Value,
      Mail: "",
      Nacionalidad: this.userForm.get("nacionalidad").value?.Value,
      Nombre: "",
      Numero: this.userForm.get("numeroCalle").value,
      NumeroDocumento: 0, //no acepta null
      Piso: this.userForm.get("piso").value,
      ProfilePicturePath: "",
      Provincia: this.userForm.get("province").value?.Value,
      Sexo: "",
      Telefono: this.userForm.get("telefonoOne").value,
    };
    
    this.userManagerService.updateUser(editedUser).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
          detail: "¡El usuario fue actualizado con exito!",
        });
        this.loading = false;
        this.spinner = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
        this.loading = false;
        this.spinner = false;
      },
      complete: () => {
      },
    });
  
  }
  
  volver() {
    this.utilitiesService.redirectToHome('/home');
  }
}
