import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratacionComponent } from './pages/contratacion/contratacion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from 'src/app/shared/prime-ng/prime-ng.module';
import { BuscadorComponent } from './pages/buscador/buscador.component';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { PolizasComponent } from './pages/polizas/polizas.component';
import { TableBudgetComponent } from './components/table-budget/table-budget.component';
import { PolizaComponent } from './components/poliza/poliza.component';
import { PopUpDetailDocumentacionComponent } from './components/pop-up-detail-documentacion/pop-up-detail-documentacion.component';



@NgModule({
  declarations: [
    ContratacionComponent,
    BuscadorComponent,
    PolizasComponent,
    TableBudgetComponent,
    PopUpDetailDocumentacionComponent,
    PolizaComponent,

  ],
  imports: [SharedModule,CommonModule, FormsModule, PrimeNgModule, ReactiveFormsModule],
  exports: [
    ContratacionComponent,
    BuscadorComponent,
      ],
})
export class PolizasModule { }
