import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import {
  ListCompany,
  ListQuoteDetail,
} from "src/app/modules/polizas/interfaces/companies.interface";
import { Combo } from "src/app/modules/shell/interfaces/combo.interface";
import {
  sendCombos,
} from "src/app/modules/shell/interfaces/sendComboQuoted.interface";
import * as moment from 'moment';
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css']
})

export class RegistrarseComponent implements OnInit {
  query: any[] = [];

  comboReceived: sendCombos;
  company: ListCompany;
  quoteDetailsCompany: ListQuoteDetail;
  budgetId: any;

  inputCode = false;
  spinner = false;

  provinces: Combo[] = [];
  localities: Combo[] = [];
  stateCivil: Combo[] = [];
  sex: Combo[] = [];
  nacionality: Combo[] = [];

  paso = 1;
  formRegister: FormGroup;
  selectedCombustible = "Nafta";
  selectedConsumidorFinal: any;
  selectedDatos: any;
  datosFormulario3: any;
  showTermsModal: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userManagerService: UserManagerService,
    private budgetManagerService: BudgetManagerService,
    private messageService: MessageService,
    private utilitiesService: UtilitiesService
  ) {

  }
  passwordPattern: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$';

  ngOnInit(): void {
    this.comboReceived = history.state.comboReceived;
    this.company = history.state.company;
    this.quoteDetailsCompany = history.state.quoteDetailsCompany;
    this.budgetId = history.state.budgetId;

    this.filterCombo("ComboProvince");
    this.filterCombo("ComboSex");
    this.filterCombo("ComboCivilStatus");
    this.filterCombo("ComboNationality");
    if (this.comboReceived) {
      if (this.comboReceived.province) {
        this.filterCombo("ComboLocation", "Province", {
          Code: this.comboReceived.province.Code,
        });
      }
    }

    this.formRegister = this.formBuilder.group({
      nombre: ["", [Validators.required]],
      apellido: ["", [Validators.required]],
      telefono: ["", [Validators.required,Validators.minLength(10), Validators.maxLength(10)]],
      sex: ["", [Validators.required]],
      stateCivil: ["", [Validators.required]],
      birthDate: ['', [Validators.required]],
      province: [this.comboReceived?.province || "", [Validators.required]],
      localitie: [this.comboReceived?.localitie || "", [Validators.required]],
      calle: ["", [Validators.required]],
      numeroCalle: ["", [Validators.required, Validators.max(99999)]],
      piso: [""],
      depto: [""],
      numeroDocumento: ["", [Validators.required, Validators.maxLength(8)]],
      mail: ["", [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordPattern)]],
      passwordRepeat: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordPattern)]],
      cp: [""],
      nacionalidad: ["", Validators.required],
      termsConditions: [false, [this.isTrueValidator()]]

    }, { validators: [this.compararContraseñas] });

  }


  register() {
    this.spinner = true;
    let object = {
      Nombre: this.formRegister.get("nombre").value,
      Apellido: this.formRegister.get("apellido").value,
      Telefono: this.formRegister.get("telefono").value,
      Sexo: this.formRegister.get("sex").value?.Value,
      EstadoCivil: this.formRegister.get("stateCivil").value?.Value,
      FechaNacimiento: new Date( this.formRegister.get("birthDate").value ).toISOString(),
      Provincia: this.formRegister.get("province").value?.Value,
      Localidad: this.formRegister.get("localitie").value?.Value,
      CodigoPostal: (this.formRegister.get("localitie").value?.Code) ? (this.formRegister.get("localitie").value?.Code) : this.formRegister.get("cp").value,
      Calle: this.formRegister.get("calle").value,
      Piso: this.formRegister.get("piso").value,
      Depto: this.formRegister.get("depto").value,
      Numero: this.formRegister.get("numeroCalle").value,
      Nacionalidad: this.formRegister.get("nacionalidad").value?.Value,
      NumeroDocumento: this.formRegister.get("numeroDocumento").value,
      Mail: this.formRegister.get("mail").value,
      UserPassword: this.formRegister.get("password").value,
      enableReferrals: true,
    };
    this.userManagerService.registerClient(object).subscribe(
      (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
        });
        setTimeout(() => {
          let doc = object.NumeroDocumento;
          let mail = object.Mail;
          this.router.navigate(["/registrarse/validar-codigo"], { state: {doc, mail} });
          this.spinner = false;
        }, 500);
      },
      (err) => {
        this.spinner = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
      }
    );
  }

  province = "";
  filters: any[] = [];
  filterCombo(comboType: any, col: any = "", value: any = "") {
    let filtros = "";
    this.query = [];

    let findIndex = this.filters.findIndex(d => d.includes(col));
    if (findIndex !== -1) {
      this.filters[findIndex] = col + "=" + value.Code;
    } else {
      this.filters.push(col + "=" + value.Code);
    }

    filtros = this.filters.join(";");

    if (comboType) {
      let query = [];
      query.push({ col: "comboType", value: comboType });
      query.push({ col: "filters", value: filtros });
      this.getCombo(query, comboType);
    }
  }

  getCombo(query: any, comboType: any) {

    this.budgetManagerService.getComboObservable(query).subscribe(res => {
      switch (comboType) {
        case "ComboLocation":
          this.localities = res.sort((a, b) => (a.Value > b.Value) ? 1 : -1);
          break;
        case "ComboProvince":
          this.provinces = res.sort((a, b) => (a.Value > b.Value) ? 1 : -1);
          break;
        case "ComboSex":
          this.sex = res.sort((a, b) => (a.Value > b.Value) ? 1 : -1);
          break;
        case "ComboCivilStatus":
          this.stateCivil = res.sort((a, b) => (a.Value > b.Value) ? 1 : -1);
          break;
        case "ComboNationality":
          this.nacionality = res.sort((a, b) => (a.Value > b.Value) ? 1 : -1);
          break;
        default:
          break;
      }
    })
  }

  onLocalitieChange(event: any) {
    const selectedValue = event.value;
    this.formRegister.controls['cp'].setValue(selectedValue.Code);
    this.inputCode = (selectedValue.Code == null);
  }

  //validacion de fechas
  dateErrorFormat: Set<string> = new Set();
  changeCalendar(event, campo) {
    let value = event.target.value;
    const patron: RegExp = /^\d{2}\/\d{2}\/\d{4}$/;
    if (patron.test(value)) {
      const date = moment(value, 'DD/MM/YYYY', true);
      this.formRegister.controls[campo].setValue(date.toDate());
      this.dateErrorFormat.delete(campo);
    } else {
      this.dateErrorFormat.add(campo)
    }

  }
  selectCalendar(event, campo) {
    this.dateErrorFormat.delete(campo);
  }

  mostrarErrores() {
    Object.values(this.formRegister.controls).forEach(control => {
      control.markAsTouched();
    });
  }
  compararContraseñas(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const passwordRepeat = formGroup.get('passwordRepeat').value;

    if (password === passwordRepeat) {
      return null;
    } else {
      return { notEqual: true };
    }
  }

  volver() {
    this.utilitiesService.redirectToHome('/home');
  }
  
  openTermsModal(): void {
    this.showTermsModal = true;
  }

  isTrueValidator(): ValidatorFn {
    return (control) => {
      const isValid = control.value === true;
      return isValid ? null : { isTrue: { valid: false } };
    };
  }
}


