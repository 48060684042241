<div class="container">
  <div class="row my-4">
    <div class="col">
      <div class="text-start">
        <a href="" class="text-dark text-decoration-none title-blue" pTooltip="Volver">
          <i class="bi bi-arrow-left h1" routerLink="/home"></i>
        </a>
        <div class="text-center">
          <h1 class="text-center my-4 d-inline title-blue">¿Qué debo tener en cuenta al comparar?</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <img
        src="assets/img/naturaleza-etica.png"
        alt=""
        srcset=""
        class="img-precio-seguro"
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <p class="word-break">
        Independientemente de la situación económica del país, el asegurado en
        muchos casos busca obtener el mismo o mejor beneficio a menor costo.
        Esto ocurre en todo el mundo. “Quiero la misma cobertura pero pagar
        menos” y sucede porque al seguro se lo ve como un gasto, cuando esto no
        debería ser asi.
      </p>
      <p class="word-break">
        Ahora bien, cuando otro productor, broker o un banco nos acerca una
        propuesta a un costo menor, ¿nos esta ofreciendo la misma cobertura?,
        ¿está teniendo en cuenta los factores que determinan el costo?. Para
        comparar «Peras con peras» tenemos que tener en cuenta lo siguiente:
      </p>
      <ul>
        <li>
          Que la cobertura sea la misma
          <ul>
            <li>
              Para «Terceros completo» tenes que tener en cuenta si incluye
              granizo, cristales etc.
            </li>
            <li>
              Para «Todo riesgo» que la franquicia sea la misma, ya sea fija, en
              porcentaje y dentro de porcentaje si es sobre la suma asegurada
              del 0Km o del auto al momento del siniestro.
            </li>
            <li>Escobillas y brazos limpiaparabrisas</li>
            <li>Espejos e insignias exteriores</li>
            <li>
              Herramientas, formen o no parte del equipo original de fábrica
            </li>
          </ul>
        </li>
        <li>Que los datos del auto sean los mismos.</li>
        <li>
          Que la suma asegurada del auto (el valor que le ponen a tu auto) sea
          la misma.
        </li>
        <li>El domicilio y código postal.</li>
        <li>Sexo</li>
        <li>Fecha de nacimiento</li>
      </ul>
      <ul>
        <li>
          Que otras variables debemos tener en cuenta y analizar:
          <ul>
            <li>
              La disponibilidad y conocimiento del asesor. ¿Nos conviene cambiar
              por pagar menos y que el otro productor, broker o banco no tenga
              una atención personalizada, no nos conozca o no tengamos una
              relación de confianza?. Siempre preguntanos o preguntale a tu
              asesor actual si tiene otras opciones, nadie te conoce mejor que
              él.
            </li>
            <li>La solvencia y vocación de pago que tiene la compañía.</li>
            <li>
              La experiencia que tuviste con la compañía que actualmente tenes,
              ¿tuviste siniestros?, ¿cómo respondió?, ¿te hizo las cosas
              fáciles?
            </li>
          </ul>
        </li>
      </ul>

      <p class="word-break">
        No obstante, la compañía responderá cuando la pérdida se hubiera
        producido con motivo del robo o hurto total del vehículo en la medida
        que esté comprendido el riesgo de robo o hurto parcial como secuela de
        acontecimiento cubierto. O sea, si te roban el auto y aparece con esos
        faltantes.
      </p>
      <p class="word-break">
        Una reflexión final, no todo es el precio, valora la relación con tu
        asesor, la comunicación, que te responda rápido y este siempre
        disponible.
      </p>
      <p class="word-break">!No dejes de consultarnos!</p>
    </div>
  </div>
</div>
