import { Component } from '@angular/core';

@Component({
  selector: 'app-consejo-contratar-seguro',
  templateUrl: './consejo-contratar-seguro.component.html',
  styleUrls: ['./consejo-contratar-seguro.component.css']
})
export class ConsejoContratarSeguroComponent {

}
