<div class="card w-100 text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container" *ngIf="!spinner && currentUser && budgetId;else error">
  <div
    class="d-flex flex-column flex-sm-row justify-content-between"
    *ngIf="comboReceived"
  >
    <span class="subtitle" [style.margin]="'2em 0px '">
      {{ comboReceived.brand.Value }} > {{ comboReceived.model.Value }} >
      {{ comboReceived.year.Value }} > {{ comboReceived.version.Value }} >
      {{ comboReceived.province.Value }} >
      {{ comboReceived.localitie.Value }}</span
    >
    <span class="pasos" [style.margin]="'2em 0px '"> Paso 3 / 3 </span>
  </div>

  <div class="d-flex flex-row" [style.width]="'100%'">
    <div class="card-datos" class="d-flex flex-column" [style.width]="'100%'">
      <div class="d-flex flex-row" [style.width]="'100%'">
        <div
          class="card-datos"
          class="d-flex flex-column"
          [style.width]="'100%'"
          *ngIf="quoteDetailsCompany && comboReceived && dataCar && addressCar"
        >
        <div class="row" [style.width]="'100%'">
          <div class="col-6 col-lg-2 text-center">
            <div>
              <span class="modal-monto"
              >${{ quoteDetailsCompany.premioMensual }}</span
            >
            </div>
            <div>
              <span class="label">Por Mes</span>
            </div>

          </div>
          <div class="col-6 col-lg-2">
            <span class="label">Responsabilidad Civil</span>
          </div>
          <div class="col-6 col-lg-2 text-center">
            <div>
              <span class="label">Monto Asegurado</span>
            </div>
            <div>
              <span class="label">${{ quoteDetailsCompany.valorAsegurado }}</span>
            </div>
          </div>
          <div class="col-6 col-lg-2">
            <span class="detalle-poliza" [style.width]="'100%'">
              <a routerLink="">Detalle de la póliza</a></span
            >
          </div>
          <div class="col-6 col-lg-2 text-center text-lg-start my-3 my-lg-0">
            <span class="suma-puntos"
              >SUMASTE {{ quoteDetailsCompany.sumaRc }} PUNTOS!</span
            >
          </div>
          <div class="col-6 col-lg-2">
            <img
              class="logo"
              [src]="
                'https://www.aseguraygana.com/img/logos/' +
                company.Code +
                '.png'
              "
              alt=""
            />
          </div>
        </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div
        class="d-flex flex-column flex-sm-row justify-content-around text-align-center"
        *ngIf="dataCar && addressCar"
      >
        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ comboReceived.brand.Value }} -
              {{ comboReceived.model.Value }}</span
            >
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ comboReceived.version.Value }} -
              {{ comboReceived.year.Value }}</span
            >
          </div>
        </div>

        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">{{ dataCar.fuel.Value }}</span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">Patente {{ dataCar.patent }} </span>
          </div>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label"> N° de Chasis {{ dataCar.chassis }} </span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label"> N° de Motor {{ dataCar.engine }} </span>
          </div>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'">
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ addressCar.street }} {{ addressCar.numberStreet }}
            </span>
          </div>
          <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label">
              {{ comboReceived.province.Value }}
              {{ comboReceived.localitie.Value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="d-flex flex-column flex-sm-row justify-content-around text-align-center" *ngIf="policy">
    <div class="d-flex flex-column" [style.width]="'100%'">
        <div class="d-flex flex-column " [style.width]="'100%'" *ngIf="policyId">
            <span class="label">Poliza N° {{policy.PolicyNumber}}</span>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'" [style.margin-top]="'0.5em'" *ngIf="payPalMethod">
            <span class="label"> Medio de pago: {{payPalMethod | titlecase}} **** **** **** 1234</span>
        </div>
    </div>
    <div class="d-flex flex-column" [style.width]="'100%'" *ngIf="policy">
        <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label"> Vigencia del {{ policy.TermStartDate | date:'dd/MM/yyyy'}} al {{ policy.TermEndDate | date:'dd/MM/yyyy'}}</span>
        </div>
        <div class="d-flex flex-column" [style.width]="'100%'">
            <span class="label-estado"> Estado</span>
            <span class="label-value"> {{policy.PolicyStatus}}</span>
        </div>
    </div>
</div>

  <div class="row my-5">
    <div class="col">
      <div
        class="input-container"
        [style.text-align]="'center'"
        [style.margin-top]="'1em !important'"
      >
        <p-button
        routerLink="/polizas"
          [style.margin-top]="'1em !important'"
          class="max"
          label="Ir a mis pólizas"
        ></p-button>
      </div>
    </div>
  </div>
</div>

<ng-template #error>
  <div class="container w-100 my-5" *ngIf="!spinner">
    <div class="row">
      <div class="col-12">
        <p-messages
          [closable]="false"
          [value]="[{ severity: 'error', detail: 'Ha ocurrido un error' }]"
        ></p-messages>
      </div>
    </div>
  </div>
</ng-template>