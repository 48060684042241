<p-dialog [(visible)]="visiblePopUpContratacion" [style]="{ width: '50vw' }" [modal]="true">
  <app-pop-up-detail-poliza  [contratarBtn]="false" [budgetId]="budgetId" [quoteDetailsCompany]="quoteDetailsCompany" [company]="company"
    [comboReceived]="comboReceived"></app-pop-up-detail-poliza>
</p-dialog>
<app-title title="Contratación"></app-title>

<div class="card w-100 text-center" *ngIf="spinner;else content" [style.min-height]="'42vh'">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<ng-template #content>
  <div class="container-w80" *ngIf="!spinner && budgetId;else error" [style.min-height]="'70vh'">
    <div class="d-flex flex-column flex-sm-row justify-content-between" *ngIf="comboReceived">
      <span class="subtitle" [style.margin]="'2em 0px '">
        {{ comboReceived.brand.Value }} > {{ comboReceived.model.Value }} >
        {{ comboReceived.year.Value }} > {{ comboReceived.version.Value }} >
        {{ comboReceived.province.Value }} >
        {{ comboReceived.localitie.Value }}</span>
      <span class="pasos" [style.margin]="'2em 0px '"> Paso {{ paso }} / 4 </span>
    </div>

    <div class="d-flex flex-row" [style.width]="'100%'">
      <div class="card-datos" class="d-flex flex-column" [style.width]="'100%'">
        <div class="d-flex flex-column flex-sm-row justify-content-around text-align-center" [style.width]="'100%'">
          <div class="row" [style.width]="'100%'">
            <div class="col-6 col-lg-2 text-center">
              <div>
                <span class="modal-monto">${{ quoteDetailsCompany.premioMensual }}</span>
              </div>
              <div>
                <span class="label">Por Mes</span>
              </div>

            </div>
            <div class="col-6 col-lg-2">
              <span class="label">Responsabilidad Civil</span>
            </div>
            <div class="col-6 col-lg-2 text-center">
              <div>
                <span class="label">Monto Asegurado</span>
              </div>
              <div>
                <span class="label">${{ quoteDetailsCompany.valorAsegurado }}</span>
              </div>
            </div>
            <div class="col-6 col-lg-2">
              <span class="detalle-poliza" [style.width]="'100%'">
                <span (click)="showPopUpSeeDetails()">Detalle de la póliza</span></span>
              </div>
            <div class="col-6 col-lg-2 text-center text-lg-start my-3 my-lg-0">
              <span class="suma-puntos">SUMAS {{ quoteDetailsCompany.sumaRc }} PUNTOS!</span>
            </div>
            <div class="col-6 col-lg-2">
              <img class="logo" [src]="
                  'https://www.aseguraygana.com/img/logos/' +
                  company.Code +
                  '.png'
                " alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row flex-md-row" [style.margin]="'2em 0px !important'">
      <div [style.width]="'100%'" [style.margin]="'1em!important'">
        <form [formGroup]="formLogin" (ngSubmit)="login()" class="d-flex flex-column" [style.width]="'100%'">
          <div [style.margin]="'0.5em !important'">
            <h1 class="subtitle-blue">¿Ya tenés cuenta?</h1>
            <h1 class="subtitle-pink-ligth">Ingresá con tus datos</h1>
            <p-divider></p-divider>
          </div>
          <div class="input-container">
            <label class="label-input">N° de Documento</label>
            <div class="p-inputgroup">
              <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="user" />
            </div>
          </div>
          <div class="input-container">
            <label class="label-input">Contraseña</label>
            <div class="p-inputgroup">
              <input type="password" class="input p-inputtext p-component p-element" pInputText
                formControlName="password" />
            </div>
          </div>
          <div class="input-container" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
            <p-button type="submit" [style.margin-top]="'1em !important'" class="max" label="Ingresar"
              [disabled]="formLogin.invalid"></p-button>
          </div>
        </form>
      </div>
      <div [style.width]="'100%'" [style.margin]="'1em!important'">
        <form [formGroup]="formRegister" (ngSubmit)="register()" autocomplete="off" class="d-flex flex-column"
          [style.width]="'100%'">
          <div [style.margin]="'0.5em !important'">
            <h1 class="subtitle-blue">¿Aún no tenés cuenta?</h1>
            <h1 class="subtitle-pink-ligth">Creala ahora</h1>
            <p-divider></p-divider>
          </div>
          <div class="d-flex flex-row">
            <div class="input-container" [style.width]="'100%'">
              <label class="label-input">Nombre</label>
              <div class="p-inputgroup">
                <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="nombre" />
              </div>
              <span class="danger" *ngIf="formRegister.get('nombre').hasError('required') && formRegister.get('nombre').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
            <div class="input-container" [style.width]="'100%'">
              <label class="label-input">Apellido</label>
              <div class="p-inputgroup">
                <input type="text" class="input p-inputtext p-component p-element" pInputText
                  formControlName="apellido" />
              </div>
              <span class="danger" *ngIf="formRegister.get('apellido').hasError('required') && formRegister.get('apellido').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <label class="label-input">Nacionalidad</label>
              <div class="p-inputgroup">
                <p-dropdown class="" [options]="nacionality" [style]="{ width: '100%' }" placeholder="- Nacionalidad -" optionLabel="Value" formControlName="nacionalidad">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'nacionalidad-' + option.Code">{{ option.Value }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
              <span class="danger" *ngIf="
                formRegister.get('nacionalidad').hasError('required') &&
                formRegister.get('nacionalidad').touched
              ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
            <div class="col-6">
              <label class="label-input">Teléfono móvil <small>(Sin el 0 y el 15)</small></label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">N°</span>
                <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="telefono"
                  title="Número de Teléfono Móvil" oninput="this.value = this.value.replace(/[^0-9]/g, '')"/>
              </div>
              <span class="danger" *ngIf="formRegister.get('telefono').hasError('required') && formRegister.get('telefono').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
              <span class="danger" *ngIf="formRegister.get('telefono').hasError('maxlength') || formRegister.get('telefono').hasError('minlength')">
                <small class="p-error">Cod. Area o N° de Telefono incompleto.</small>
              </span>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-6">
              <label for="" class="label-input">Sexo</label>
              <p-dropdown class="" [options]="sex" [style]="{ width: '100%' }" placeholder="- Sexo -" optionLabel="Value"
                formControlName="sex">
                <ng-template let-option pTemplate="item">
                  <span [class]="'sex-' + option.Code">{{ option.Value }}</span>
                </ng-template>
              </p-dropdown>
              <span class="danger" *ngIf="formRegister.get('sex').hasError('required') && formRegister.get('sex').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
            <div class="col-6">
              <label for="" class="label-input">Estado civil</label>
              <p-dropdown class="" [options]="stateCivil" [style]="{ width: '100%' }" placeholder="- Estado civil -"
                optionLabel="Value" formControlName="stateCivil">
                <ng-template let-option pTemplate="item">
                  <span [class]="'sex-' + option.Code">{{ option.Value }}</span>
                </ng-template>
              </p-dropdown>
              <span class="danger" *ngIf="formRegister.get('stateCivil').hasError('required') && formRegister.get('stateCivil').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-12">
              <label for="" class="label-input">Fecha de nacimiento</label>
              <span class="danger" *ngIf="dateErrorFormat.has('birthDate')">
                <small class="p-error">El formato de la fecha debe ser (dd/mm/yyyy).</small>
              </span>
              <p-calendar [style]="{ width: '100%' }" formControlName="birthDate" dateFormat="dd/mm/yy" (onInput)="changeCalendar($event,'birthDate')"
                (onSelect)="selectCalendar($event,'birthDate')"></p-calendar>
              <span class="danger" *ngIf="formRegister.get('birthDate').hasError('required') && formRegister.get('birthDate').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-6">
              <label for="" class="label-input">Provincia</label>
              <p-dropdown class="" [style]="{ width: '100%' }" [options]="provinces" placeholder="- Provincia -"
                formControlName="province" optionLabel="Value" (onChange)="filterCombo('ComboLocation', 'Province', $event.value)">
                <ng-template let-option pTemplate="item">
                  <span [class]="'province-' + option.Code">{{ option.Value }}</span>
                </ng-template>
              </p-dropdown>
              <span class="danger" *ngIf="formRegister.get('province').hasError('required') && formRegister.get('province').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
            <div class="col-6">
              <label for="" class="label-input">Localidad</label>
              <p-dropdown class="" (onChange)="onLocalitieChange($event)" [style]="{ width: '100%' }"
                [options]="localities" placeholder="- Localidad -" formControlName="localitie" optionLabel="Value">
                <ng-template let-option pTemplate="item">
                  <span [class]="'model-' + option.Code">{{ option.Value }}</span>
                </ng-template>
              </p-dropdown>
              <span class="danger" *ngIf="formRegister.get('localitie').hasError('required') && formRegister.get('localitie').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row m-0 p-0 mt-3  justify-content-end" *ngIf="inputCode">
            <div class="col-12">
              <!-- <p-inputNumber formControlName="cp" placeholder="- Código postal -" mode="decimal"  [useGrouping]="false"></p-inputNumber> -->
              <input type="text" class="form-control" pInputText formControlName="cp" title="Código Postal" placeholder="- Código postal -"/>
              <br>
              <span class="danger" *ngIf="formRegister.get('cp').hasError('required') && formRegister.get('cp').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-6">
              <label for="" class="label-input">Calle</label>
              <input type="text" class="form-control" pInputText formControlName="calle" />
              <span class="danger" *ngIf="formRegister.get('calle').hasError('required') && formRegister.get('calle').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
            <div class="col-6">
              <label for="" class="label-input">Altura</label>
              <input pInputText formControlName="numeroCalle" type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')"/>
              <span class="danger" *ngIf="formRegister.get('numeroCalle').hasError('max')">
                <small class="p-error">Este campo tiene un limite hasta 99999</small>
              </span>
              <span class="danger" *ngIf=" formRegister.get('numeroCalle').hasError('required') && formRegister.get('numeroCalle').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>

          <div class="d-flex flex-sm-row flex-column">
            <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
              <label for="" class="label-input">Piso</label>
              <input type="text" class="form-control" pInputText formControlName="piso" title="Piso" />
            </div>
            <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
              <label for="" class="label-input">Depto</label>
              <input type="text" class="form-control" pInputText formControlName="depto" title="Departamento" />
            </div>
          </div>

          <div class="row m-0">
            <label for="" class="label-input">Nro documento</label>
            <div class="col-12">
              <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" pInputText formControlName="numeroDocumento"/>
              <span class="danger" *ngIf="formRegister.get('numeroDocumento').hasError('required') && formRegister.get('numeroDocumento').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
              <span class="danger" *ngIf="formRegister.get('numeroDocumento').hasError('maxlength')">
                <small class="p-error">El N° de Documento no debe tener mas de 8 caracteres</small>
              </span>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-12">
              <label class="label-input">Correo electronico</label>
              <input type="text" class="form-control" pInputText formControlName="mail" />
              <span class="danger" *ngIf="formRegister.get('mail').hasError('email')">
                <small class="p-error">EL correo es invalido</small>
              </span>
              <span class="danger" *ngIf="formRegister.get('mail').hasError('required') && formRegister.get('mail').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-6">
              <label class="label-input">Contraseña</label>
              <input type="password" class="form-control" pInputText formControlName="password" />
              <span class="danger" *ngIf="formRegister.get('password').hasError('required') && formRegister.get('password').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
              <span class="danger" *ngIf="formRegister.get('password').hasError('minlength')">
                <small class="p-error">La contraseña debe tener al menos 8 caracteres.</small>
              </span>
              <span class="danger" *ngIf="formRegister.get('password').hasError('pattern')">
                <small class="p-error">La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número.</small>
              </span>
              <span class="danger" *ngIf="formRegister.hasError('notEqual')" [style.margin-top]="'1em'">
                <small class="p-error">Las contraseñas deben ser iguales.</small>
              </span>
            </div>
            <div class="col-6">
              <label class="label-input">Confirmar contraseña</label>
              <input type="password" class="form-control" pInputText formControlName="passwordRepeat"/>
              <span class="danger" *ngIf="formRegister.get('passwordRepeat').hasError('required') && formRegister.get('passwordRepeat').touched">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
              <span class="danger" *ngIf="formRegister.get('passwordRepeat').hasError('minlength')">
                <small class="p-error">La contraseña debe tener al menos 8 caracteres.</small>
              </span>
              <span class="danger" *ngIf="formRegister.get('passwordRepeat').hasError('pattern')">
                <small class="p-error">La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número.</small>
              </span>
            </div>
          </div>
          <div class="input-container" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
            <p-button type="submit" [style.margin-top]="'1em !important'" class="max" label="Crear cuenta" [disabled]="formRegister.invalid" (click)="mostrarErrores()"></p-button>
            <!-- <p-button type="submit" [style.margin]="'1em !important'" class="max"  label="Crear cuenta" [disabled]="formRegister.invalid"></p-button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div class="container w-100 my-5">
    <div class="row">
      <div class="col-12">
        <p-messages [closable]="false" [value]="[{ severity: 'error', detail: 'Ha ocurrido un error' }]"></p-messages>
      </div>
    </div>
  </div>
</ng-template>