<div *ngIf="policyDocumentation?.length > 0; else noDocuments">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center">Nombre del Archivo</th>
          <th class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let document of policyDocumentation">
          <td class="text-center">{{ document.nombreArchivo }}</td>
          <td class="text-center">
            <button pButton type="button" label="Descargar" icon="pi pi-download" class="p-button-sm"
                    (click)="descargarArchivo(document.archivoPATH)"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <!-- Template para cuando no hay documentos -->
  <ng-template #noDocuments>
    <div class="no-documents-message">
      {{ mensajePopUp }}
    </div>
  </ng-template>