import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import {
  ListCompany,
  ListQuoteDetail,
} from "src/app/modules/polizas/interfaces/companies.interface";
import { Combo } from "src/app/modules/shell/interfaces/combo.interface";
import {
  comboQuoted,
  sendCombos,
} from "src/app/modules/shell/interfaces/sendComboQuoted.interface";
import { dataCar } from "../../interfaces/car.interface";
import { addressCar } from "../../interfaces/address.interface";
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";

@Component({
  selector: "app-contratacion-automovil",
  templateUrl: "./contratacion-automovil.component.html",
  styleUrls: ["./contratacion-automovil.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ContratacionAutomovilComponent implements OnInit {
  spinner = true;
  virtualScroll = false;
  readOnlyCP: boolean = true;
  comboReceived: sendCombos = history.state.comboReceived;
  company: ListCompany;
  quoteDetailsCompany: ListQuoteDetail;
  budgetId: any;
  addressCar: any;
  dataCar: any;

  currentUser: any;
  paso = 1;
  form: FormGroup;
  selectedCombustible = "Nafta";
  selectedConsumidorFinal: any;
  selectedDatos: any;
  datosFormulario3: any;
  calle: string;
  numero: string;
  pisoDepto: string;
  Localidad: string;
  Provincia: string;
  cp: string;

  brands: Combo[] = [];
  models: Combo[] = [];
  years: Combo[] = [];
  versions: Combo[] = [];
  provinces: Combo[] = [];
  localities: Combo[] = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userManagerService: UserManagerService,
    private budgetManagerService: BudgetManagerService,
    private utilitiesService: UtilitiesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userManagerService.GetUserData();

    this.company = history.state.company;
    this.quoteDetailsCompany = history.state.quoteDetailsCompany;
    this.budgetId = history.state.budgetId;
    this.addressCar = history.state.addressCar;
    this.dataCar = history.state.dataCar;

    this.calle = this.addressCar ? this.addressCar.street : this.currentUser.Calle;
    this.numero = this.addressCar ? this.addressCar.numberStreet : this.currentUser.Numero;
    this.pisoDepto = this.addressCar ? this.addressCar.flatOrApartment : this.currentUser.Piso + " " + this.currentUser.Depto;
    this.Localidad = this.addressCar ? this.addressCar.localitie : this.currentUser.Localidad;
    this.Provincia = this.addressCar ? this.addressCar.province : this.currentUser.Provincia;
    this.cp = this.addressCar ? this.addressCar.postalCode : this.currentUser.CodigoPostal;

    this.filterCombo("ComboProvince", "Province", this.currentUser.Provincia);
    if (this.comboReceived) {
      if (this.comboReceived.brand) {
        this.filterCombo("ComboCarBrand");
        this.filterCombo("ComboCarModel", "Brand", this.comboReceived.brand);
      }
      if (this.comboReceived.model) {
        this.filterCombo("ComboCarYear", "Model", this.comboReceived.model);
      }
      if (this.comboReceived.year) {
        this.filterCombo("ComboCarVersion", "Year", this.comboReceived.year);
      }
    }
    
    this.filters = [];
    
    this.form = this.formBuilder.group({
      brand: [this.comboReceived?.brand.Value, [Validators.required]],
      year: [this.comboReceived?.year.Value, [Validators.required]],
      model: [this.comboReceived?.model.Value, [Validators.required]],
      version: [this.comboReceived?.version.Value, [Validators.required]],
      patent: [this.dataCar?.patent, [Validators.required, Validators.minLength(6), Validators.maxLength(7)]],
      fuel: [this.comboReceived?.version.Field1, [Validators.required]],
      engine: [this.dataCar?.engine, [Validators.required]],
      chassis: [this.dataCar?.chassis, [Validators.required]],
      numberStreet: [this.numero, [Validators.required]],
      street: [this.calle, [Validators.required]],
      flatOrApartment: [this.pisoDepto, []],
      postalCode: [this.cp, [Validators.required]],
      province: ["", [Validators.required]],
      localitie: ["", [Validators.required]],
      trueData: [history.state.volver? history.state.volver : false, [this.isTrueValidator()]],
      finalConsumer: [history.state.volver? history.state.volver : false, [this.isTrueValidator()]],
    });
    
    this.spinner = false;
  }
  
  isTrueValidator(): ValidatorFn {
    return (control) => {
      const isValid = control.value === true;
      return isValid ? null : { isTrue: { valid: false } };
    };
  }

  ingresar() {
    this.paso = 2;
  }
  continuar() {
    this.form.markAllAsTouched();
    if (this.form.valid) {

      let dataCar: dataCar = {
        fuel: this.form.get("fuel").value,
        patent: this.form.get("patent").value,
        engine: this.form.get("engine").value,
        chassis: this.form.get("chassis").value,
      };

      let addressCar: addressCar = {
        street: this.form.get("street").value,
        numberStreet: this.form.get("numberStreet").value,
        flatOrApartment: this.form.get("flatOrApartment").value,
        postalCode: this.form.get("postalCode").value,
        finalConsumer: this.form.get("finalConsumer").value,
        trueData: this.form.get("trueData").value,
      };
        
      this.router.navigate(["/contratacion/finalizar"], {
        state: {
          quoteDetailsCompany: this.quoteDetailsCompany,
          company: this.company,
          comboReceived: this.comboReceived,
          addressCar,
          dataCar,
          budgetId: this.budgetId,
        },
      });
    }
  }

  brand = "";
  model = "";
  year = "";
  combustible = "";
  province = "";
  filters: any[] = [];

  query: any[] = [];

  async filterCombo(
    comboType: any,
    col: any = "",
    value: any = "",
    formCamp?: string
  ) {
    if (formCamp) {
      switch (formCamp) {
        case "brand":
          this.brandChange();
          break;
        case "model":
          this.modelChange();
          break;
        case "year":
          this.yearChange();
          break;
        case "province":
          this.provinceChange();
          break;
        default:
          break;
      }
    }

    let filtros = "";

    this.query = [];

    let find = this.filters.find((d) => d.col == "filters");
    if (find) {
      let index = this.filters.indexOf(find);
      this.filters.splice(index, 1);
    }

    let findFil = this.filters.find((d) => d.col == col);
    if (findFil) {
      let index = this.filters.indexOf(findFil);
      this.filters.splice(index, 1);
    }

    if (col == "Brand") {
      this.brand = value.Value;
      this.filters.push({ col, value: col + "=" + this.brand });
    }
    if (col == "Model") {
      this.model = value.Value;
      this.filters.push({ col, value: col + "=" + this.model });
    }
    if (col == "Year") {
      this.year = value.Value;
      this.filters.push({ col, value: col + "=" + this.year });
    }
    if (col == "Combustible") {
      this.combustible = value.Value;
      this.filters.push({ col, value: col + "=" + this.combustible });
    }
    if (col == "Province") {
      this.province = value.Code;
      this.filters.push({ col, value: col + "=" + this.province });
    }

    const values = this.filters.map((filter) => filter.value);

    filtros = values.join(";");

    if (comboType) {
      this.query.push({ col: "comboType", value: comboType });
      this.query.push({ col: "filters", value: filtros });

      switch (comboType) {
        case "ComboCarBrand":
          this.brands = await this.getCombo();
          break;
        case "ComboCarModel":
          this.models = await this.getCombo();
          break;
        case "ComboCarYear":
          this.years = await this.getCombo();
          break;
        case "ComboCarVersion":
          this.versions = await this.getCombo();
          break;
        case "ComboProvince":
          this.provinces = await this.getCombo();
          this.form.get("province").setValue(this.provinces.find(p => p.Value === this.Provincia))
          this.filterCombo("ComboLocation", "Province", this.provinces.find(p => p.Value === this.Provincia));
          break;
        case "ComboLocation":
          this.localities = await this.getCombo();
          this.form.get("localitie").setValue(this.localities.find(l => l.Value === this.Localidad));
          this.virtualScroll = this.localities.length > 3;
          break;
        default:
          break;
      }
    }
  }

  async getCombo() {
    return await this.budgetManagerService.getCombo(this.query);
  }

  brandChange() {
    this.form.get("model").setValue(null);
    this.form.get("year").setValue(null);
    this.form.get("version").setValue(null);
  }
  modelChange() {
    this.form.get("year").setValue(null);
    this.form.get("version").setValue(null);
  }
  yearChange() {
    this.form.get("version").setValue(null);
  }

  provinceChange() {
    this.form.get("localitie").setValue(null);
    this.readOnlyCP = true;
    if (this.form.get("province").value.Value == "CAPITAL FEDERAL") {
      console.log('entro al if');
      this.readOnlyCP = false;
      this.form.controls["postalCode"].setValue(null);
    }
  }

  onLocalitieChange(event: any) {
    const selectedValue = event.value;
    this.form.controls["postalCode"].setValue(selectedValue.Code);
  }
  volver() {
    let data = {
      budgetId: this.budgetId,
      companies: history.state.companies
    }    
    this.utilitiesService.redirectToHome('/buscador', this.comboReceived, data);
  }
}
