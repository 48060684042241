import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PremiosYCanjesComponent } from "./pages/premios-y-canjes/premios-y-canjes.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "primeng/api";
import { PrimeNgModule } from "src/app/shared/prime-ng/prime-ng.module";
import { PremioCategoriaComponent } from "./pages/premio-categoria/premio-categoria.component";
import { PremioCategoriaCardComponent } from './components/premio-categoria-card/premio-categoria-card.component';
import { PremioCanjeComponent } from './pages/premio-canje/premio-canje.component';


@NgModule({
  declarations: [
    PremiosYCanjesComponent,
    PremioCategoriaComponent,
    PremioCategoriaCardComponent,
    PremioCanjeComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    PrimeNgModule,
    ReactiveFormsModule,
  ],
  exports: [PremiosYCanjesComponent, PremioCategoriaComponent,PremioCategoriaCardComponent,PremioCanjeComponent],
})
export class PremiosYCanjesModule {}
