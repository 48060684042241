import { Component } from '@angular/core';

@Component({
  selector: 'app-preguntas-precuentes',
  templateUrl: './preguntas-precuentes.component.html',
  styleUrls: ['./preguntas-precuentes.component.css']
})
export class PreguntasPrecuentesComponent {

}
