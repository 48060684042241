<!-- <div class="card w-100 text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center" >Cargando...</span>
  </div>
 -->

<div class="d-flex flex-column" *ngIf="company && quoteDetailsCompany">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-column">
      <span class="modal-monto">${{ quoteDetailsCompany.premioMensual }}</span>
      <span class="label">Por Mes <span class="label" *ngIf="userNotLoged">(Monto Estimado)</span></span>
      
    </div>
    <div class="d-flex flex-column">
      <img
        class="logo"
        [src]="
          'https://www.aseguraygana.com/img/logos/' + company.Code + '.png'
        "
        alt=""
      />
    </div>
  </div>
  <p-divider></p-divider>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-column">
      <span class="label">Monto Asegurado</span>
    </div>
    <div class="d-flex flex-column">
      <span class="label">${{ quoteDetailsCompany.valorAsegurado }}</span>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="d-flex flex-column justify-content-between align-items-start">
    <span class="label">Detalles</span>
    <ul>
      <li
        class="info"
        *ngFor="let coverage of quoteDetailsCompany.listCoberturas"
      >
        {{ coverage }}
      </li>
    </ul>
    <span class="info"> </span>
  </div>
  <div
    class="d-flex flex-column justify-content-between align-items-center"
    fxLayoutAlign="space-between center"
  >
    <p-button
    *ngIf="contratarBtn"
      [style.margin]="'2em 0px'"
      class="max"
      label="Contratar"
      (click)="contratar()"
    ></p-button>
  </div>
</div>
