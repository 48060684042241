import { Component, Input, OnInit } from "@angular/core";
import { ListCompany } from "../../interfaces/companies.interface";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { ListQuoteDetail } from "../../interfaces/companies.interface";
import { sendCombos } from "src/app/modules/shell/interfaces/sendComboQuoted.interface";

@Component({
  selector: "app-table-budget",
  templateUrl: "./table-budget.component.html",
  styleUrls: ["./table-budget.component.css"],
 // encapsulation: ViewEncapsulation.None,
})
export class TableBudgetComponent implements OnInit {
  @Input() companies: ListCompany[] = [];
  @Input() comboReceived: sendCombos;
  @Input() budgetId:any;
  loading = true;
  companyQuoteSelectDetails: ListQuoteDetail;
  companySelect: ListCompany;
  visiblePopUpContratacion = false;
  highlightedIndex: number = -1;
  missingImages: string[] = [];

  indice: number = 0;

  constructor(private budgetService: BudgetManagerService) {}

  ngOnInit(): void {}

  showPopUpSeeDetails(quoteDetail: ListQuoteDetail,company:ListCompany) {
    this.companyQuoteSelectDetails = quoteDetail;
    this.companySelect = company;
    this.visiblePopUpContratacion = true;

  }

  showHover(quoteDetail: ListQuoteDetail, index: number) {
    if (quoteDetail) {
      quoteDetail.isHovered = true;
      this.highlightedIndex = index;
    }
  }

  hiddenHover(quoteDetail: ListQuoteDetail, index: number) {
    if (quoteDetail) {
      quoteDetail.isHovered = false;
      this.highlightedIndex = -1;
    }
  }

  trackByIndex(index: number, quote: any): number {
    return index;
  }

  onImageError(companyCode: string): void {
    if (!this.missingImages.includes(companyCode)) {
      this.missingImages.push(companyCode);
    }
  }
}
