import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-premio-canje',
  templateUrl: './premio-canje.component.html',
  styleUrls: ['./premio-canje.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PremioCanjeComponent {

}
