<div class="clsfix"></div>
<p-dialog header="Acceso Agentes" [modal]="true" [position]="'top'" [transitionOptions]="'500ms'"
  [(visible)]="visibleModalLogin" [style]="{ width: '50vw' }">
  <form [formGroup]="form" (ngSubmit)="login()">
    <div class="row mt-3">
      <div class="col-12">
        <label for="user"><span>Número de documento o Mail</span></label>
        <input type="text" [style]="{ width: '100%' }" pInputText formControlName="user"
          class="input p-inputtext p-component p-element" />
        <div *ngIf="
            form.get('user').hasError('required') && form.get('user').touched
          " class="text-center">
          <small class="text-danger">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <label for="password"><span>Contraseña</span></label>
        <input type="password" [style]="{ width: '100%' }" pInputText formControlName="password"
          class="input p-inputtext p-component p-element" />
        <div *ngIf="
            form.get('password').hasError('required') &&
            form.get('password').touched
          " class="text-center">
          <small class="text-danger">Este campo es <strong>requerido</strong></small>
        </div>
      </div>
    </div>
    <!--<div class="row mt-3">
      <div class="col-12">
        <span>
          <a (click)="forgotPasswordVisible()" class="forget-password">Olvide mi contraseña</a>
        </span>
      </div>
    </div>-->
    <div class="row mt-3">
      <div class="col-12">

        <button [disabled]="form.invalid" (click)="onButtonClick()" class="submit-login btn ">
          Ingresar >
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<div
  class="d-flex flex-row justify-content-around text-align-center w-100"
  [style.padding]="'1em 0px'"
  [style.background-color]="'#22325f'"
>
  <img id="logoFooter" src="assets/img/logo-footer.svg" alt="" />
  <div [style.max-width]="'150px'">
    <p routerLink="sobre-nosotros"  class="content pointer">Sobre Asegurá y Ganá</p>
    <p routerLink="terminos-y-condiciones"  class="content pointer">Términos y condiciones</p>
    <p routerLink="preguntas-frecuentes"  class="content pointer">Preguntas frecuentes</p>
    <!-- <p (click)="visibleModalLogin=true" class="content pointer">Acceso agentes</p> -->
    <!-- <p routerLink="servicio-al-cliente" class="content pointer">Servicio al cliente</p> -->
  </div>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between social-links">
        <a class="pi pi-facebook text-decoration-none" href="https://www.facebook.com/profile.php?id=100093839271208" target="_blank"></a>
        <a class="pi pi-instagram text-decoration-none" href="https://instagram.com/aseguraygana.arg" target="_blank"></a>
      </div>
      <div class="d-flex justify-content-between social-links">
        <a class="pi pi-whatsapp text-decoration-none" href="https://wa.me/message/HIPSUUP24QBJN1" target="_blank"></a>
        <a class="pi pi-envelope text-decoration-none" href="mailto:contacto@aseguraygana.com"></a>
      </div>
    </div>
  </div>
</div>
<div class="clsfix"></div>
<div class="d-flex flex-column" [style.padding]="'1em 0px'" [style.width]="'100%'">
  <span> Copyright © 2010-2021 Asegurá y Ganá. Derechos Reservados. </span>
  <div class="d-flex flex-row justify-content-center" [style.margin]="'1em'">
    <img class="img-footer primera" src="assets/img/img-footer-1.svg" alt="" />
    <img class="img-footer segunda" src="assets/img/img-footer-2.svg" alt="" />
    <img class="img-footer tercera" src="assets/img/img-footer-3.webp" alt="" />
    <img class="img-footer cuarta" src="assets/img/img-footer-4.svg" alt="" />
  </div>
</div>
