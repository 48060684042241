<div class="card w-100 text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>
<div *ngIf="!spinner">
  <form [formGroup]="userForm" autocomplete="off" class="d-flex flex-column" [style.width]="'90%'" (ngSubmit)="saveUser()">
    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Nombre (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="nombre" title="Nombre" (keydown)="false" readonly/>
        </div>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Apellido (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="apellido" title="Apellido" (keydown)="false" readonly/>
        </div>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Sexo (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="sex" title="Sexo" (keydown)="false" readonly/>
        </div>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Estado Civil (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="stateCivil" title="Estado Civil" (keydown)="false" readonly/>
        </div>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Fecha de Nacimiento (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="birthDate" title="Fecha de Nacimiento" (keydown)="false" readonly/>
        </div>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">N° documento (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="numeroDocumento" title="N° documento" (keydown)="false" readonly/>
        </div>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <div class="input-container" [style.width]="'100%'">
          <label class="label-input">Nacionalidad (*)</label>
          <div class="p-inputgroup">
            <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="nacionalidad" title="Nacionalidad" (keydown)="false" readonly/>
          </div>
        </div>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Teléfono móvil <small>(Sin el 0 y sin el 15)</small> (*)</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">N°</span>
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="telefonoOne" title="Código de Área"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" (keydown)="false" readonly/>
        </div>
        <span class="danger" *ngIf="userForm.get('telefonoOne').hasError('required') && userForm.get('telefonoOne').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
        <span class="danger" *ngIf="userForm.get('telefonoOne').hasError('maxlength') || userForm.get('telefonoOne').hasError('minlength')">
          <small class="p-error">Cod. Area o N° de Telefono incompleto.</small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Correo Electrónico (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="mail" title="Correo Electrónico" (keydown)="false" readonly/>
        </div>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label for="" class="label-input">Provincia (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="province" title="Provincia" (keydown)="false" readonly/>
          <!-- <p-dropdown class="" [style]="{ width: '100%' }" [options]="provinces" placeholder="- Provincia -"
            formControlName="province" optionLabel="Value" (onChange)="filterCombo('ComboLocation', 'Province', $event.value)">
            <ng-template let-option pTemplate="item">
              <span [class]="'province-' + option.Code">{{ option.Value }}</span>
            </ng-template>
          </p-dropdown> -->
        </div>
        <span class="danger" *ngIf="userForm.get('province').hasError('required') && userForm.get('province').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label for="" class="label-input">Localidad (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="localitie" title="Localidad" (keydown)="false" readonly/>
          <!-- <p-dropdown class="" (onChange)="onLocalitieChange($event)" [style]="{ width: '100%' }" [options]="localities"
            placeholder="- Localidad -" formControlName="localitie" optionLabel="Value">
            <ng-template let-option pTemplate="item">
              <span [class]="'localitie-' + option.Code">{{ option.Value }}</span>
            </ng-template>
          </p-dropdown> -->
        </div>
        <span class="danger" *ngIf="userForm.get('localitie').hasError('required') && userForm.get('localitie').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Código Postal (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="form-control" pInputText formControlName="cp" title="Código Postal" placeholder="- Código postal -" (keydown)="false" readonly/>
          <span class="danger" *ngIf="userForm.get('cp').hasError('required') && userForm.get('cp').touched">
            <small class="p-error">Este campo es <strong>requerido</strong></small>
          </span>
        </div>
      </div>      
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Nombre de Calle (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="calle" title="Nombre de Calle" (keydown)="false" readonly/>
        </div>
        <span class="danger" *ngIf="userForm.get('calle').hasError('required') && userForm.get('calle').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Número (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="numeroCalle" title="Número de Calle" 
            oninput="this.value = this.value.replace(/[^0-9]/g, '')" (keydown)="false" readonly/>
        </div>
        <span class="danger" *ngIf="userForm.get('numeroCalle').hasError('max')">
          <small class="p-error">Este campo tiene un limite hasta 99999</small>
        </span>
        <span class="danger" *ngIf="userForm.get('numeroCalle').hasError('required') && userForm.get('numeroCalle').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Piso (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="piso" title="Piso" (keydown)="false" readonly/>
        </div>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Departamento (*)</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="depto" title="Departamento" (keydown)="false" readonly/>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-center" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
      <p-button type="submit" (click)="saveUser()" [style.margin]="'1em !important'" class="max"  label="Guardar Cambios" [disabled]="!userForm.dirty || !userForm.valid || loading"></p-button>
      <p-button routerLink="/home" (click)="volver()" [style.margin]="'1em !important'" class="max secondary" label="Volver"></p-button>
    </div>
  </form>
</div>
<p-toast></p-toast>
