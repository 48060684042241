import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService, SharedModule } from 'primeng/api';
import { UserModule } from './modules/users/user.module';
import { PrimeNgModule } from './shared/prime-ng/prime-ng.module';
import { PolizasModule } from './modules/polizas/polizas.module';
import { ShellModule } from './modules/shell/shell.module';
import { ReferidosModule } from './modules/referidos/referidos.module';
import { PremiosYCanjesModule } from './modules/premios/premios-y-canjes.module';
import { HttpClientModule} from "@angular/common/http";
import { ContratacionModule } from './modules/contratacion/contratacion.module';
import { ConsejoModule } from './modules/consejos/consejo.module';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PrimeNgModule,
    UserModule,
    PolizasModule,
    ShellModule,
    ReferidosModule,
    PremiosYCanjesModule,
    ContratacionModule,
    ConsejoModule
  ],
  providers: [MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }
