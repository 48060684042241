<div class="d-flex flex-row" [style.background-color]="'#da6d73'">
  <div [style.padding]="'1em'" class="container-w80">
    <h1 class="title-white">Mi perfil</h1>
  </div>
</div>
<div class="d-flex flex-wrap justify-content-start align-items-center mt-5">
  <h6 class="subtitle align-content-center" style="margin-left: 10.5%">Datos personales</h6>
  <small class="subtitle-small" style="margin-left: 10px;">(*) Para modificar estos datos necesitamos que te contactes con nuestro soporte a través de: soporte&#64;aseguraygana.com</small>
</div>

<div class="container" style="margin-left: 9.0%">
  <div class="row mt-3" style="overflow: auto">
    <div class="col">
      <app-table-description-user [user]="user" *ngIf="user"></app-table-description-user>
    </div>
  </div>
</div>

<p-toast></p-toast>