<div class="d-flex flex-row" [style.background-color]="'#da6d73'">
  <div [style.padding]="'1em'" class="container-w80">
    <h1 class="title-white">Pólizas</h1>
  </div>
</div>

<div class="card w-100 text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container">
  <div *ngIf="policys.length > 0 && !spinner; else notPolicys">
    <div class="p-grid p-nogutter policies-wrapper">
      <div [ngClass]="{'p-col-12 p-md-6 policy-item': policys.length > 1, 'p-col-12 p-md-6 single-policy': policys.length === 1}" 
           *ngFor="let policy of policys">
        <div class="card-poliza">
          <app-poliza [policy]="policy"></app-poliza>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notPolicys>
  <div class="container w-100 my-5" *ngIf="!spinner">
    <div class="p-grid">
      <div class="p-col-12">
        <p-messages [closable]="false" [value]="[{ severity: 'info', detail: 'No se encontraron polizas' }]"></p-messages>
      </div>
    </div>
  </div>
</ng-template>