import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CatalogoManagerService {


  constructor(private http: HttpClient) { }

  getListCatalogo() {
    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return this.http
      .get(`${environment.apiUrl}/PointRedemptionsManager.svc/GetTemplatesCatalog`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  getListProducts() {
    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return this.http
      .get(`${environment.apiUrl}/PointRedemptionsManager.svc/GetProductsToRedeem`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
