import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PolicyManagerService } from 'src/app/api/services/policyManager/policy-manager.service';
import { UserManagerService } from 'src/app/api/services/userManager/user-manager.service';
import { UtilitiesService } from 'src/app/api/services/utility/utilities.service';

@Component({
  selector: 'app-polizas',
  templateUrl: './polizas.component.html',
  styleUrls: ['./polizas.component.css']
})

export class PolizasComponent implements OnInit{
  lista:any[]=[]

  currentUser: any;

  policys:any = [];

  query: any[] = [];

  spinner = true;

  constructor(
    private policyManager:PolicyManagerService,
    private userManagerService:UserManagerService,
    private messageService:MessageService, 
    private utilitiesService: UtilitiesService){
   this.lista.push(
    {
      estado:"VIGENTE"
    }
   )
   this.lista.push(
    {
      estado:"VENCIDA"
    }
   )
   this.lista.push(
    {
      estado:"EN PROCESO DE EMISIÓN"
    }
   )
  }
  async ngOnInit(): Promise<void> {
    if (!(localStorage.getItem("tokenAsegurayGana"))) {
      this.utilitiesService.redirectToHome('/home');
    }
    this.currentUser = await this.userManagerService.GetUserData();
    this.getUserPolicies();
  }

  async getCurrentUser() {
    return await this.userManagerService.GetUserData();
  }

  getUserPolicies(){
    this.query = [{ col: "policyStatus", value: "VIGENTE,EN EMISION" }];
    this.policyManager.getUserPolicies(this.query).subscribe({
      next : (data)=> {
        this.policys = data.ListItems
        this.spinner = false;
      },
      error : (err)=>{
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error.message
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
      },
      complete : ()=>{
        console.log("subscribe completo");
      }
    })
  }

}
