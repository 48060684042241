import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogoManagerService } from 'src/app/api/services/catalogoManager/catalogo-manager.service';
import { UtilitiesService } from 'src/app/api/services/utility/utilities.service';

@Component({
  selector: 'app-premios-y-canjes',
  templateUrl: './premios-y-canjes.component.html',
  styleUrls: ['./premios-y-canjes.component.css']
})
export class PremiosYCanjesComponent implements OnInit {

  categories = []
  currentUser = JSON.parse(localStorage.getItem("user")) || "";
  constructor(private router: Router, private catalogoManagerService: CatalogoManagerService, private utilitiesService: UtilitiesService) {
    
    /*this.catalogoManagerService.getListCatalogo().subscribe(res=>{
      console.log(res)
    })
    this.catalogoManagerService.getListProducts().subscribe(res => {
      this.categories = res;
    })*/

  }

  async ngOnInit(): Promise<void> {
    if (!(localStorage.getItem("tokenAsegurayGana"))) {
      this.utilitiesService.redirectToHome('/home');
    }
  }

  navegarPremio(seccion: string) {
    this.router.navigate([`premios-y-canjes/${seccion}`])
  }

}
