<div class="d-flex flex-row" [style.background-color]="'#da6d73'">
  <div [style]="{'margin-left' : '6.1em','padding': '1em'}" class=" container-w80 ml-5">
      <h1 class="title-white">Premios y Canjes &nbsp; > &nbsp; Gastronomía</h1>
  </div>
</div>
<div class="row m-0 p-0">
  <div class="offset-md-1 col-12 col-md-4">
    <a routerLink="/premios-y-canjes" class="subtitle"
      ><i class="pi pi-arrow-left flecha"></i>Gastronomía</a
    >
  </div>
  <div class="col-12 col-lg-3 mt-4">
    <div class="row">
      <div class="col-2 title-margin text-end">
        <span class="title-label">Ver</span>
      </div>
      <div class="col-10">
        <p-dropdown
          [options]="viesPremier"
          (onChange)="filter($event, 'view')"
          optionLabel="label"
          optionValue="value"
          class="view"
        >
          <ng-template let-option pTemplate="item">
            <span [class]="'customer-badge status-' + option.value">{{
              option.label
            }}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-3 mt-4">
    <div class="row">
      <div class="col-2 title-margin text-end">
        <span class="title-label">De</span>
      </div>
      <div class="col-10">
        <p-dropdown
          [options]="filtroOrden"
          (onChange)="filter($event, 'sort')"
          optionLabel="label"
          optionValue="value"
          class="sort"
        >
          <ng-template let-option pTemplate="item">
            <span [class]="'customer-badge status-' + option.value">{{
              option.label
            }}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
</div>

<div class="container p-0 mb-5">
  <div class="row m-0 p-0 m-auto">
    <div
      class="offset-3 col-9 offset-md-0 col-sm-6 col-lg-4 col-xl-3 mb-5 p-sm-4 p-md-2 mr-xl-1 ml-xl-1"
      *ngFor="let premio of premios; let i = index"
    >
      <app-premio-categoria-card
        (click)="canjear(premio)"
        [premio]="premio"
      ></app-premio-categoria-card>
    </div>
  </div>
</div>
