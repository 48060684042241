import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './title/title.component';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { PopUpDetailPolizaComponent } from './pop-up-detail-poliza/pop-up-detail-poliza.component';



@NgModule({
  declarations: [
    TitleComponent,
    PopUpDetailPolizaComponent
  ],
  imports: [
    CommonModule,PrimeNgModule
  ],
  exports: [
    TitleComponent,
    PopUpDetailPolizaComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]


})
export class SharedModule { }
