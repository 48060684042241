<div class="card w-100 text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container" *ngIf="currentUser && budgetId && !spinner; else error">
  <div class="row">
    <div class="col">
      <form [formGroup]="form" class="d-flex flex-column" [style.margin]="'2em 0px !important'">
        <div class="row">
          <div class="col-9">
            <div [style.margin]="'0.5em !important'">
              <h1 class="subtitle-blue">
                Hola {{ currentUser.Nombre }}, ingresá por favor los datos de tu
                automóvil
              </h1>
              <h1 class="subtitle-pink-ligth">
                Completamos algunos campos con los datos que ingresaste
                inicialmente, verificá que sean correctos
              </h1>
              <p-divider></p-divider>
            </div>
          </div>
          <div class="col-3">
            <span class="pasos" [style.margin]="'2em 0px '" style="float: right;"> Paso 1 / 3 </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-6">
                <label class="label-input">Marca</label>
                <!-- <label class="form-control">{{comboReceived.brand.Value}}</label> -->
                <input type="text" class="form-control" pInputText formControlName="brand" readonly title="Marca"/>
              </div>
              <div class="col-6">
                <label class="label-input">Modelo</label>
                <!-- <label class="form-control">{{comboReceived.model.Value}}</label> -->
                <input type="text" class="form-control" pInputText formControlName="model" readonly title="Modelo"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-6">
                <label class="label-input">Combustible</label>
                <div class="row">
                  <div class="col-12">
                    <!-- <label class="form-control">{{comboReceived.version.Field1}}</label> -->
                    <input type="text" class="form-control" pInputText formControlName="fuel" readonly title="Combustible"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <label class="label-input">Patente
                  <div class="container-atencion">
                    <span class="atencion">¡</span>
                    <div class="cartel">Este dato podes encontrarlo en la cédula azul/verde del automóvil</div>
                  </div>
                </label>
                <input type="text" class="form-control" pInputText formControlName="patent" title="Patente"/>
                <span class="danger" *ngIf=" form.get('patent').hasError('required') && form.get('patent').touched">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
                <span class="danger" *ngIf="form.get('patent').hasError('maxlength')">
                  <small class="p-error">La patente debe tener como máximo 7 caracteres</small> 
                </span>
                <span class="danger" *ngIf="form.get('patent').hasError('minlength')">
                  <small class="p-error">La patente debe tener como mínimo 6 caracteres</small> 
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-6">
                <label class="label-input">Año</label>
                <!-- <label class="form-control">{{comboReceived.year.Value}}</label> -->
                <input type="text" class="form-control" pInputText formControlName="year" readonly title="Año"/>
              </div>
              <div class="col-6">
                <label class="label-input">Versión</label>
                <!-- <label class="form-control">{{comboReceived.version.Value}}</label> -->
                <input type="text" class="form-control" pInputText formControlName="version" readonly title="Versión"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-6">
                <label class="label-input">N° de motor
                  <div class="container-atencion">
                    <span class="atencion">¡</span>
                    <div class="cartel">Este dato podes encontrarlo en la cédula azul/verde del automóvil
                      <img width="220px" src="assets/img/motor-cédula.png" alt="">
                    </div>
                  </div>
                </label>
                <input type="text" class="form-control" pInputText formControlName="engine" title="Motor"/>
                <span class="danger" *ngIf="
                    form.get('engine').hasError('required') &&
                    form.get('engine').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
              <div class="col-6">
                <label class="label-input">N° de chasis
                  <div class="container-atencion">
                    <span class="atencion">¡</span>
                    <div class="cartel">Este dato podes encontrarlo en la cédula azul/verde del automóvil
                      <img width="220px" src="assets/img/chasis-cédula.png" alt="">
                    </div>
                  </div>
                </label>
                <input type="text" class="form-control" pInputText formControlName="chassis" title="Chasis"/>
                <span class="danger" *ngIf="form.get('chassis').hasError('required') && form.get('chassis').touched">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div [style.margin]="'0.5em !important'">
              <h1 class="subtitle-blue">Dirección para la inspección de mi automóvil</h1>
              <p-divider></p-divider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-6">
                <label class="label-input">Calle</label>
                <input type="text" class="form-control" pInputText formControlName="street" title="Calle"/>
                <span class="danger" *ngIf="
                form.get('street').hasError('required') &&
                form.get('street').touched
              ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
              <div class="col-6">
                <label class="label-input">Número</label>
                <p-inputNumber formControlName="numberStreet" mode="decimal" [useGrouping]="false"
                  [style]="{ width: '100%' }">
                </p-inputNumber>
                <span class="danger" *ngIf="
                form.get('numberStreet').hasError('required') &&
                form.get('numberStreet').touched
              ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-6">
                <label class="label-input">Piso/Dto</label>

                <input type="text" class="form-control" pInputText formControlName="flatOrApartment" title="Piso / Departamento"/>

              </div>
              <div class="col-6">
                <label class="label-input">Código postal</label>
                <p-inputNumber formControlName="postalCode" mode="decimal" [useGrouping]="false" [style]="{ width: '100%' }" [readonly]="readOnlyCP">
                </p-inputNumber>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <label class="label-input">Provincia</label>
            <p-dropdown class="" [style]="{ width: '100%' }" [options]="provinces" placeholder="- Provincia -"
              formControlName="province" optionLabel="Value" (onChange)="filterCombo('ComboLocation','Province',$event.value,'province')
            ">
              <ng-template let-option pTemplate="item">
                <span [class]="'province-' + option.Code">{{ option.Value }}</span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="form.get('province').hasError('required') && form.get('province').touched">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
            <!-- <input type="text" class="form-control" pInputText formControlName="province" readonly title="Provincia"/> -->
          </div>
          <div class="col-6">
            <label class="label-input">Localidad</label>
            <p-dropdown class="" (onChange)="onLocalitieChange($event)" [style]="{ width: '100%' }" [options]="localities"
            placeholder="- Localidad -" formControlName="localitie" optionLabel="Value">
            <ng-template let-option pTemplate="item">
              <span [class]="'localitie-' + option.Code">{{ option.Value }}</span>
            </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="form.get('localitie').hasError('required') && form.get('localitie').touched">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
              <!-- <input type="text" class="form-control" pInputText formControlName="localitie" readonly title="Localidad"/> -->
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-6">
            <p-checkbox formControlName="trueData" [style.margin-left]="'0.5em'" [style.margin-bottom]="'0.1em'"
              inputId="trueData" class="radio" [binary]="true"></p-checkbox>
            <label for="trueData" class="label-declaraciones mx-2">Declaro que mis datos son verdaderos</label>
            <span class="danger" *ngIf="form.get('trueData').hasError('isTrue') && form.get('trueData').touched">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-6">
            <p-checkbox formControlName="finalConsumer" [style.margin-left]="'0.5em'" [style.margin-bottom]="'0.1em'"
              inputId="finalConsumer" class="radio" [binary]="true"></p-checkbox>
            <label for="finalConsumer" class="label-declaraciones mx-2">Declaro que soy consumidor final</label>
            <span class="danger" *ngIf="form.get('finalConsumer').hasError('isTrue') && form.get('finalConsumer').touched">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <p-button [style.margin]="'0.5em !important'" (click)="continuar()" class="max" label="Continuar"></p-button>
            <p-button [style.margin]="'1em !important'" class="max" label="Volver" (click)="volver()"></p-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #error>
  <div class="container w-100 my-5" *ngIf="!spinner">
    <div class="row">
      <div class="col-12">
        <p-messages [closable]="false" [value]="[{ severity: 'error', detail: 'Ha ocurrido un error' }]"></p-messages>
      </div>
    </div>
  </div>
</ng-template>