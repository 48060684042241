import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RefererManagerService } from 'src/app/api/services/refererManager/referer-manager.service';
import { MessageService } from "primeng/api";
import { UtilitiesService } from 'src/app/api/services/utility/utilities.service';

@Component({
  selector: 'app-referidos',
  templateUrl: './referidos.component.html',
  styleUrls: ['./referidos.component.css']
})
export class ReferidosComponent implements OnInit {
  form1: FormGroup;
  lista: any[] = []
  buttonLoad = false;
  constructor(
    private formBuilder: FormBuilder, 
    private refererManagerService: RefererManagerService, 
    private messageService: MessageService,
    private utilitiesService: UtilitiesService
  ) {
    this.form1 = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.email]],
      //codigo: ['', []],
    })
   this.getLista();



  }
  ngOnInit(): void {

    if (!(localStorage.getItem("tokenAsegurayGana"))) {
      this.utilitiesService.redirectToHome('/home');
    }
  }
  getLista(){
    this.refererManagerService.getList().subscribe(
      res => {
        this.lista=[]
        res.ListItems.forEach(d => {
          this.lista.push(
            {
              correo: d.Email,
              codigo: d.Code,
              estado: (d.ReferralId) ? 'Activo' : 'Pendiente',
              id: d.Id
            }
          )
        })


      })

  }
  create() {
    let form = this.form1.value;
    this.buttonLoad = true;
    this.refererManagerService.create(form.correo).subscribe(
      res => {
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
          detail: "El referido fue creado con exito",
        });
        this.form1.reset({ emitEvent: false });
        this.getLista();
        this.buttonLoad = false;

      },
      err => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
        this.buttonLoad = false;
      }
    )

  }
  // eliminar(item) {

  // }

  volver() {
    this.utilitiesService.redirectToHome('/home');
  }
}
