
import { Component, HostListener, OnInit } from "@angular/core";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { BudgetGenerate } from "src/app/modules/shell/interfaces/budgetGenerate.interface";
import { sendCombos } from "src/app/modules/shell/interfaces/sendComboQuoted.interface";
import { Companies, ListCompany } from "../../interfaces/companies.interface";
import { ListQuoteDetail } from "../../interfaces/companies.interface";
import { interval } from "rxjs";
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";
import { Message } from "primeng/api";

@Component({
  selector: "app-buscador",
  templateUrl: "./buscador.component.html",
  styleUrls: ["./buscador.component.css"],
})
export class BuscadorComponent implements OnInit {
  registros: any[] = [];
  visible = false;
  companies: Companies;
  comboReceived: sendCombos;
  budgetId: any;
  historyBudgetId: any = history.state.budgetId || null;
  historyCompanies: ListCompany[] = history.state.companies || null;
  historyListDataComplete: any[] = history.state.listDataComplete || null;
  spinner = true;
  currentUser = JSON.parse(localStorage.getItem("user")) || ""; 
  messages: Message[] | undefined; 

  budgetItem: BudgetGenerate;
  listDataComplete: any = [];
  constructor(
    private budgetService: BudgetManagerService, 
    private utilitiesService: UtilitiesService) {}
    ngOnInit() {
      this.messages = [
        { severity: 'info', summary: 'Info', detail: 'Para obtener una cotizacion exacta, por favor registrarse o loguearse con su usuario.' },
      ];
      const minuteInterval$ = interval(10000);
      
      minuteInterval$.pipe().subscribe(() => {
        this.reload();
      });
      
      this.comboReceived = history.state.comboReceived;
      
      this.budgetItem = {
        budget: {
          tipoIva: this.comboReceived.tipoIva.Value,
          tipoIIBB: this.comboReceived.tipoIIBB.Value,
          tipoViaDeCobro: this.comboReceived.tipoViaDeCobro.Value,
          entidadBancaria: this.comboReceived.entidadBancaria.Value,
        },
        item: {
          anio: this.comboReceived.year.Value,
          codigo: this.comboReceived.version.Code,
          codigoPostal: this.comboReceived.localitie.Code,
          estadoCivil: this.currentUser ? this.currentUser.EstadoCivil : "Soltero(a)" ,
          fechaNacimiento: this.currentUser.FechaNacimiento ? new Date(new Date(this.currentUser.FechaNacimiento).toISOString()) : new Date(new Date("1994-05-11").toISOString()),
          provincia: this.comboReceived.province.Value,
          localidad: this.comboReceived.localitie.Value,
          sexo: this.currentUser.Sexo ? this.currentUser.Sexo : "Masculino",
          autoScoring: {
            MERIDIONAL_codUnidadNegocio: "AUTO",
            EXPERTA_codLocalidad: "AUTO"
          }
        },
      };
    
      if (this.historyBudgetId && this.historyCompanies) {
        this.companies = {budgetId: this.historyBudgetId,listCompanies: this.historyCompanies};        
        this.spinner = false;
        //this.reload();
      } else {
        if (this.budgetItem) {
          this.searchCompanies(this.budgetItem);
        }
      }
    }

  async searchCompanies(budgetItem: BudgetGenerate) {
    this.companies = await this.budgetService.generateAutoBudget(budgetItem);
    this.spinner = false;
    this.quoter();
  }

  reload() {
    this.listDataComplete.forEach((company) => {
      this.consultListQuote(company);
    });
  }

  async quoter() {
    const groupCompanies = [];
    const { listCompanies, budgetId } = this.companies;

    for (let i = 0; i < listCompanies.length; i += 3) {
      groupCompanies.push(listCompanies.slice(i, i + 3));
    }
    await Promise.all(
      groupCompanies.flatMap((companyGroup) =>
        companyGroup.map((company: ListCompany) => {     
          this.listDataComplete.push(company);
          this.generateQuote(company, true);
          // interval(10000).subscribe((n) =>
          //   console.log("quoteBudgetByCompany => " + company.Code, n)
          // );
        })
      )
    );
  }
  async generateQuote(company: any, backToQuotation: boolean) {
    try {
      company.listQuoteDetail = null;
      await this.budgetService.quoteBudgetByCompany({
        budgetId: this.historyBudgetId ? this.historyBudgetId : this.companies.budgetId,
        companyId: parseInt(company.Code),
        backToQuotation: backToQuotation,
      });
    } catch (error) {
      console.log(`Error al generar la cotización con la Cía. ${company.Value}: ${error.error}`);
    }
  }
  removeItem(code){    
    let indexToRemove = this.listDataComplete.findIndex(
      (item) => item.Code === code
    );
    if (indexToRemove !== -1) {
      this.listDataComplete.splice(indexToRemove, 1);
    }
  }
  async consultListQuote(company: any) {    
    company.listQuoteDetail = null;
    this.updateCompanyByCode(company);
    try {
      const listQuote = await this.budgetService.getListQuoteBudgetByCompany({
        budgetId: this.historyBudgetId ? this.historyBudgetId : this.companies.budgetId,
        companyId: parseInt(company.Code),
      });      
      await new Promise((resolve, reject) => {
        if (listQuote) {
          company.listQuote = listQuote;
          company.volverIntentar = false;
          if (company.listQuote.length > 0) {
            company.isHovered = false;
            company.listQuote["code"] = company.Code;
            company.logo = company.listQuote.quote.ciaAseguradoraLogo;
            company.Value = company.Value;
          }
          if ( 
            (company.listQuote.quote.listQuoteDetail.length > 0) ||
            (company.listQuote.quote.listQuoteDetail.length == 0 && company.listQuote.quote.comentarios.toLowerCase().indexOf("error") !== -1)
          ) {
            this.removeItem(company.Code);
          }
        } 
        resolve("");
      });
    } catch (error) {
      if (error.error.toLowerCase().includes('vuelva a intentar')) {
        company.listQuoteDetail = null;
        company.volverIntentar = true;
      } else {
        console.log(`Error al obtener la cotización de la Cía. ${company.Value}`);
        this.removeItem(company.Code);
      }
      
    } finally {
      this.updateCompanyByCode(company);
      this.transformCompany(company);
    }
  }

  contratar() {
    this.visible = true;
  }

  transformCompany(company: any) {
    let a1: ListQuoteDetail;
    let c1: ListQuoteDetail;
    let c2: ListQuoteDetail;
    let d1: ListQuoteDetail;
    let d2: ListQuoteDetail;
    
    if (
      company.listQuote &&
      company.listQuote.quote.listQuoteDetail.length > 0
    ) {
      a1 = company.listQuote.quote.listQuoteDetail.find((quoteDetail) => {
        return quoteDetail.coberturaPosicion === "A1";
      });
      c1 = company.listQuote.quote.listQuoteDetail.find((quoteDetail) => {
        return quoteDetail.coberturaPosicion === "C1";
      });
      c2 = company.listQuote.quote.listQuoteDetail.find((quoteDetail) => {
        return quoteDetail.coberturaPosicion === "C2";
      });
      d1 = company.listQuote.quote.listQuoteDetail.find((quoteDetail) => {
        return quoteDetail.coberturaPosicion === "D1";
      });
      d2 = company.listQuote.quote.listQuoteDetail.find((quoteDetail) => {
        return quoteDetail.coberturaPosicion === "D2";
      });
    }
    company.listQuoteDetail = [a1, c1, c2, d1, d2];
    this.spinner = false;
  }

  updateCompanyByCode(updatedCompany: ListCompany): void {
    const indexToUpdate = this.companies.listCompanies.findIndex(
      (company) => company.Code === updatedCompany.Code
    );

    if (indexToUpdate !== -1) {
      this.companies.listCompanies[indexToUpdate].load = updatedCompany.load;
      this.companies.listCompanies[indexToUpdate].listQuoteDetail =
        updatedCompany.listQuoteDetail;
    } else {
      console.error(
        `No se encontró ninguna compañía con el código ${updatedCompany.Code}`
      );
    }
  }

  removeCompaniesWithoutQuotas() {
    this.companies.listCompanies = this.companies.listCompanies.filter(
      (company) => {
        return company.listQuoteDetail.length > 0 
      }
    );
  }
  
  volver() {
    this.utilitiesService.redirectToHome('/home', this.comboReceived);
  }
}
