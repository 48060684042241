<div class="b-image-100 fondo-rosa">
  <div class="fondo-content">
    <div class="d-flex flex-column flex-md-row justify-content-around align-items-center">
      <div [style.padding]="' 10px 1em'">
        <h1 class="title-white">¡Obtené tu seguro hoy!</h1>
        <h1 class="title-blue-linght">y empezá a sumar puntos!</h1>
      </div>
      <div></div>
      <p-card class="selected" [style]="{
          width: '25rem',
          height: '100%'
        }">
        <div class="header d-flex flex-row justify-content-around align-items-center">
          <i [ngClass]="cardSelected == 1 ? 'selected-icon' : ''" class="pi pi-car primero">
          </i>

        </div>
        <div>
          <form *ngIf="cardSelected == 1" [formGroup]="form1" class="d-flex flex-column" (ngSubmit)="buscarSeguro()">
            <div class="row m-0 p-0 mt-1">
              <div class="col-12 col-sm-6 mt-3 mt-md-0">
                <p-dropdown class="" [options]="brands" [style]="{ width: '100%' }" placeholder="- Marca -" (onChange)="
                    filterCombo('ComboCarModel', 'Brand', $event.value, 'brand')
                  " formControlName="brand" optionLabel="Value">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'brand-' + option.Code">{{
                      option.Value
                      }}</span>
                  </ng-template>
                </p-dropdown>
                <span class="danger" *ngIf="
                    form1.get('brand').hasError('required') &&
                    form1.get('brand').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
              <div class="col-12 mt-3 mt-md-0 col-sm-6">
                <p-dropdown class=""  [style]="{ width: '100%' }" [options]="models" placeholder="- Modelo -"
                  formControlName="model" optionLabel="Value" (onChange)="
                    filterCombo('ComboCarYear', 'Model', $event.value, 'model')
                  ">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'model-' + option.Code">{{
                      option.Value
                      }}</span>
                  </ng-template>
                </p-dropdown>
                <span class="danger" *ngIf="
                    form1.get('model').hasError('required') &&
                    form1.get('model').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
            </div>
            <div class="row m-0 p-0 mt-3">
              <div class="col-12 col-sm-6 mt-3 mt-md-0">
                <p-dropdown class="" [style]="{ width: '100%' }" [options]="years" placeholder="- Años -"
                  formControlName="year" optionLabel="Value" (onChange)="
                    filterCombo('ComboCarVersion', 'Year', $event.value, 'year')
                  ">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'year-' + option.Code">{{
                      option.Value
                      }}</span>
                  </ng-template>
                </p-dropdown>
                <span class="danger" *ngIf="
                    form1.get('year').hasError('required') &&
                    form1.get('year').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
              <div class="col-12 mt-3 mt-md-0 col-sm-6">
                <p-dropdown class="" [style]="{ width: '100%' }" [options]="versions" placeholder="- Versión -"
                  formControlName="version" optionLabel="Value">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'version-' + option.Code">{{
                      option.Value
                      }}</span>
                  </ng-template>
                </p-dropdown>
                <span class="danger" *ngIf="
                    form1.get('version').hasError('required') &&
                    form1.get('version').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
            </div>
            <div class="row m-0 p-0 mt-3">
              <div class="col-12 col-sm-6 mt-3 mt-md-0">
                <p-dropdown class="" [style]="{ width: '100%' }" [options]="provinces" placeholder="- Provincia -"
                  formControlName="province" optionLabel="Value" (onChange)="
                    filterCombo('ComboLocation', 'Province', $event.value)
                  ">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'province-' + option.Code">{{
                      option.Value
                      }}</span>
                  </ng-template>
                </p-dropdown>
                <span class="danger" *ngIf="
                    form1.get('province').hasError('required') &&
                    form1.get('province').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
              <div class="col-12 mt-3 mt-md-0 col-sm-6">
                <p-dropdown id="localities" [style]="{ width: '100%!important' }" [lazy]="true" [virtualScroll]="virtualScroll"
                  [virtualScrollItemSize]="30" [options]="localities" [filter]="true"
                  placeholder="- Localidad -" formControlName="localitie" optionLabel="Value"
                  (onChange)="onLocalitieChange($event)">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'localitie-' + option.Code">{{
                      option.Value
                      }}</span>
                  </ng-template>
                </p-dropdown>

                <span class="danger" *ngIf="
                    form1.get('localitie').hasError('required') &&
                    form1.get('localitie').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
            </div>
            <div class="row m-0 p-0 mt-3  justify-content-end" *ngIf="inputCode">
              <div class="col-12  mt-md-0 col-sm-6">
                <p-inputNumber class="cp" formControlName="cp" placeholder="- Código postal -" mode="decimal"  [useGrouping]="false">
                </p-inputNumber>
                <br>
                <span class="danger" *ngIf="
                    form1.get('cp').hasError('required') &&
                    form1.get('cp').touched
                  ">
                  <small class="p-error">Este campo es <strong>requerido</strong></small>
                </span>
              </div>
            </div>
            <div [style.padding]="'20px!important'">
              <p-button type="submit" (click)="onButtonClick()" [disabled]="form1.invalid" class="basic w-100"
                label="Buscar el mejor seguro"></p-button>
            </div>
          </form>
        </div>
      </p-card>
    </div>
  </div>
  <img src="assets/img/banner-rosa.png" alt=""/>
</div>

<div class="d-flex flex-column justify-content-around align-items-center mt-5" [style.margin-top]="'2em'">
  <div class="b-image">
    <div class="fondo-content">
      <h1 class="title-white">Sumá puntos</h1>
      <h1 class="title-white-linght">y canjealos por premios</h1>
      <div class="btn-crear-cuenta btn-over" (click)="registrarse()">REGISTRARSE</div>
    </div>
    <img src="assets/img/banner-2.png" alt="Imagen genérica" />
  </div>

  <div [style.border-radius]="'6px'" class="container-w80" [style.text-align]="'center'" [style.padding]="'20px'">
    <h1 class="title-blue">Cotizamos con las mejores compañias</h1>
    <div class="container-responsive">
      <img class="compania" src="assets/img/5761.png" alt="" />
      <img class="compania" src="assets/img/162686861.png" alt="" />
      <img class="compania" src="assets/img/5813.png" alt="" />
      <!-- <img class="zurich" src="assets/img/zurich.png" alt="" /> -->
    </div>
  </div>
</div>
<div class="b-image-100">
  <div class="fondo-content celular">
    <div class="d-flex flex-column justify-content-start">
      <h1 class="title-white">Consultá y gestioná tus pólizas y beneficios</h1>
      <h1 class="title-white-linght">desde tu celular</h1>
    </div>
  </div>
  <img class="img-fondo-1" src="assets/img/banner-celular.png" alt="" />
</div>

<div class="d-flex flex-column justify-content-around align-items-center" [style.margin-top]="'2em'">
  <div class="d-flex flex-column flex-sm-row">
    <div class="card-caracteristicas">
      <img src="assets/img/caracteristica-1.png" alt="" />
      <h3 class="subtitle">Simple y rápido</h3>
      <h1 class="title-pink">Cotizá</h1>
      <!--<span class="content-gray">
        Elegí el tipo de seguro que te interesa, completa el formulario y vas a
        obtener la cotización de las principales compañías del mercado en
        cuestión de segundos.
      </span>-->
      <span class="content-gray">
        Completando tus datos en pocos pasos, obtendrás costos y coberturas a la medida de tus necesidades
      </span>
    </div>
    <div class="card-caracteristicas">
      <img src="assets/img/caracteristica-2.png" alt="" />
      <h3 class="subtitle">Opciones</h3>
      <h1 class="title-pink">Compará</h1>
      <!--<span class="content-gray">
        En cada cotización que te demos vas a encontrar el detalle de lo que
        viene otorgado por la compañía aseguradora que acompaña al precio. Para
        ayudarte a tomar la mejor decisión también vas a encontrar "Tips" utiles
        para cada tipo de seguro.
      </span>-->
      <span class="content-gray">
        Elegí tu seguro comparando cotizaciones de las mejores compañías del país
      </span>
    </div>
  </div>
  <div class="d-flex flex-column flex-sm-row">
    <div class="card-caracteristicas">
      <img src="assets/img/caracteristica-3.png" alt="" />
      <h3 class="subtitle">Accesible</h3>
      <h1 class="title-pink">Administrá</h1>
      <span class="content-gray">
        Somos también una herramienta de gestión de seguros. Ahora es muy sencillo
        administrar tus pólizas y obtener la información que necesites en el
        momento. Vas a poder verlas y descargarlas desde
        cualquier dispositivo.
      </span>
    </div>

    <div class="card-caracteristicas">
      <img src="assets/img/caracteristica-4.png" alt="" />
      <h3 class="subtitle">Fácil de usar</h3>
      <h1 class="title-pink">Contratá</h1>
      <span class="content-gray">
        Una vez que hayas decidido el seguro que más te conviene completa el formulario
        y listo! En cuestión de minutos tenes la póliza en tú casilla de mail.
        No es necesario salir de nuestra plataforma para poder contratar.
      </span>
    </div>
  </div>

  <p-divider [style.width]="'50%'"></p-divider>
</div>
<div class="b-image-100">
  <div class="fondo-content tablet">
    <div class="d-flex flex-column justify-content-end">
      <h1 class="title-white">Accede a tu cuenta desde cualquier lugar</h1>
      <h1 class="title-white-linght">en cualquier momento</h1>
    </div>
  </div>
  <img class="img-fondo-1" src="assets/img/banner-tablet.png" alt="" />
</div>

<div class="d-flex flex-column justify-content-around align-items-center">
  <div [style.margin-bottom]="'2em'" [style.padding]="' 0px 0.5em'">
    <h1 class="subtitle-pink">TIPS PARA CONTRATAR UN BUEN SERVICIO</h1>
    <h1 class="title-blue">Consejos para vos y tu automóvil</h1>
  </div>
</div>

<div class="container-responsive">
  <div class="card-tips d-flex flex-column justify-content-between">
    <img src="assets/img/hombre-calculadora.png" alt="" />
    <div class="d-flex flex-column">
      <span [style.padding]="'0.5em'" class="subtitle-blue">
        ¿Qué determina el precio de mi seguro?
      </span>
      <span class="content-gray">
        <!-- En caso de siniestro con el automóvil trata de mantener la calma, no
        abandones el lugar, del accidente, no toques nada y llamanos lo antes
        posible a los teléfonos que figuran en este enlace según tu país o región. -->
      </span>
    </div>
    <a class="ver-nota text-decoration-none" href="consejos/1">Ver nota</a>
  </div>
  <div class="card-tips d-flex flex-column justify-content-between">
    <img src="assets/img/hombre-negocios.png" alt="" />
    <div class="d-flex flex-column">
      <span [style.padding]="'0.5em'" class="subtitle-blue">
        ¿Qué debo tener en cuenta al contratar mi seguro?
      </span>
      <span class="content-gray">
        <!-- Pese a que a un buen porcentaje de usuarios ven en el precio del seguro,
        el factor más determinante a la hora de contratar una póliza, hay factores
        que son igual de importantes. -->
      </span>
    </div>
    <a class="ver-nota text-decoration-none" href="consejos/2">Ver nota</a>
  </div>

  <div class="card-tips d-flex flex-column justify-content-between">
    <img src="assets/img/naturaleza-etica.png" alt="" />
    <div class="d-flex flex-column">
      <span [style.padding]="'0.5em'" class="subtitle-blue">
        ¿Qué debo tener en cuenta al comparar?
      </span>
      <span class="content-gray">
        <!-- Mantener tu vehículo en el mejor estado posible es muy importante, ya que
        aparte de la gran inversión que presenta tu vehículo será el gran
        facilitador para el acceso de bienes y servicios -->
      </span>
    </div>
    <a class="ver-nota text-decoration-none" href="consejos/3">Ver nota</a>
  </div>
</div>

<div class="d-flex flex-column justify-content-around align-items-center" [style.margin]="'3em 0em'">
  <!-- <div class="recomendaciones">
    <h1 class="subtitle-blue">Mira todas las recomendaciones</h1>
  </div> -->
</div>