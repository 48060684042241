<div class="container">
  <div class="row my-4">
    <div class="col">
      <div class="text-start">
        <a href="" class="text-dark text-decoration-none title-blue" pTooltip="Volver">
          <i class="bi bi-arrow-left h1" routerLink="/home"></i>
        </a>
        <div class="text-center">
          <h1 class="my-4 title-blue d-inline text-center">
            ¿Qué determina el precio de mi seguro?
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <img
        src="assets/img/hombre-calculadora.png"
        alt=""
        srcset=""
        class="img-precio-seguro"
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <p class="word-break">
        Es muy común que en una reunión familiar o de amigos surja el tema y el
        típico: “¿Cuanto pagas de seguro?”, “nooo te están matando yo pago menos
        por un auto más nuevo que el tuyo”, y luego surjan preguntas a tu
        productor tales como: “Un amigo tiene un auto más nuevo que yo y paga
        menos, ¿como puede ser?” o “¿Mi auto es igual que el de un amigo y yo
        pago más?”.
      </p>
      <p class="word-break">
        Para entender estas y otras preguntas es necesario saber que las
        compañías para establecer los costos de tu seguro, maá allá de tener en
        cuenta el valor de tu auto, realizan un proceso llamado segmentación.
        ¿Qué es la segmentación? Es la división en grupos de personas
        (conductor) y riesgos (auto) que tengan algo (o mucho) en común. Marca,
        modelo, año, lugar de residencia, sexo, edad del conductor etc. Cuales
        son todos los factores que inciden en el precio de tu seguro más allá de
        la Suma Asegurada en la cual esta asegurado tu auto, la cobertura y la
        franquicia (en el caso de la cobertura Todo Riesgo) y el descuento que
        te puede hacer tu asesor.
      </p>

      <ul>
        <li>
          La Marca de tu auto
          <ul>
            <li>
              Algunas marcas de autos tienen mejor experiencia siniestral que
              otras. ¿Qué quiere decir esto?, que algunas marcas de autos son
              menos robadas que otras o tienen menos accidentes que otras por el
              perfil de consumidor que compra esta marca. 
            </li>
          </ul>
        </li>
        <li>
          El modelo de tu auto
          <ul>
            <li>
              Algunas modelos de autos tienen mejor experiencia siniestral que
              otras. ¿Qué quiere decir esto?, que algunos modelos de autos son
              menos robadas que otras o tienen menos accidentes que otras por el
              perfil de consumidor que compra ese modelos de autos de ciertas
              marcas.  Ejemplo: El perfil de conductor que compra un auto
              deportivo no es el mismo que el que compra una van familiar. 
            </li>
          </ul>
        </li>
        <li>
          El año de tu auto
          <ul>
            <li>
              No es lo mismo un auto/modelo que tiene menos de 10 años de
              antigüedad que un auto/modelo que tiene mas de 10 años por
              diferentes razones. Ejemplo: generalmente un auto/modelo de mas de
              10 años de antigüedad ya se dejo de fabricar, es más difícil
              conseguir repuestos, por ende es más factible que se robe para
              conseguir repuestos y ser vendidos en el mercado negro. 
            </li>
          </ul>
        </li>
        <li>
          Si tu auto es 0km o usado

          <ul>
            <li>
              Básicamente y entre otras razones, los 0km son los más deseados
              por las compañías, es por ello que se le aplican descuentos extra.
            </li>
          </ul>
        </li>

        <li>
          El código postal de tu domicilio
          <ul>
            <li>
              Las compañías tienen segmentado en Alto riesgo (Capital Federal,
              GBA y alguna ciudad importante del interior), Mediano riesgo
              (generalmente pasados los 50 Km de la Capital Federal), Bajo
              riesgo (Resto del país). Esto explicado a «groso modo». Y dentro
              de Alto riesgo (Capital Federal, GBA y alguna ciudad importante
              del interior) cada compañía tiene su segmentación por
              localidad/barrio. No es lo mismo zona norte de Capital Federal y
              GBA que otras zonas de Capital Federal y el conurbano bonaerense. 
            </li>
          </ul>
        </li>
        <li>
          Tu sexo
          <ul>
            <li>
              También hay diferencias en el costo dependiendo tu sexo. Existen
              estadísticas que indican que:
              <ul>
                <li>
                  El hombre tiene menor frecuencia en accidentes pero mayor
                  intensidad
                </li>
                <li>
                  La mujer tiene más frecuencia en accidentes pero menor
                  intensidad
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Tu edad
          <ul>
            <li>
              Algunas aseguradoras segmentan también por edad, por ejemplo:
              <ul>
                <li>18 - 25</li>
                <li>25 - 65</li>
                <li>65 - mayor</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Tu estado civil e hijos
          <ul>
            <li>
              Algunas aseguradoras segmentan también por estado civil e hijos, o
              hijos que conducen.
            </li>
          </ul>
        </li>
      </ul>

      <p class="word-break">
        Todas estas variables se basan en estadísticas de cada compañía. Y lo
        que hace más complejo aun esto es que todas estas variables se
        entrecruzan entre si y se obtienen diferentes resultados.
      </p>
      <p class="word-break">
        Una reflexión final, no todo es el precio, valora la relación con tu
        asesor, la comunicación, que te responda rápido y este siempre
        disponible.
      </p>
      <p class="word-break">Ante cualquier duda consultanos!</p>
    </div>
  </div>
</div>
