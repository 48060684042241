import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { Combo } from "../../interfaces/combo.interface";
import { BudgetFormValidatorService } from "../../../../shared/validators/budget-form-validator.service";
import { sendCombos } from "../../interfaces/sendComboQuoted.interface";
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  selectedSlider = 1;
  cardSelected = 1;
  virtualScroll = false;
  form1: FormGroup; 

  query: any[] = [];
  inputCode = false;
  first = 0;
  brands: Combo[] = [];
  models: Combo[] = [];
  years: Combo[] = [];
  versions: Combo[] = [];
  provinces: Combo[] = [];
  localities: Combo[] = [];
  iva: Combo[] = [];
  ibb: Combo[] = [];
  tipoViaDeCobro: Combo[] = [];
  tipoPersona: Combo[] = [];
  entidadBancaria: Combo[] = [];
  tokenLogin: string
  comboReceived: sendCombos;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private budgetManagerService: BudgetManagerService,
    private utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef
  ) {
    this.form1 = this.formBuilder.group(
      {
        brand: ["", [Validators.required]],
        model: ["", [Validators.required]],
        year: ["", [Validators.required]],
        version: ["", [Validators.required]],
        province: ["", [Validators.required]],
        localitie: ["", [Validators.required]],
        cp: ["", [Validators.required]],
      },
      {
        validators: [
          // this.budgetFormValidatorService.habilitarCampoModel("brand", "model"),
        ],
      }
    );
    this.tokenLogin = localStorage.getItem("tokenAsegurayGana") || "";
  }

  getCombo(query: any, comboType: any) {
    this.budgetManagerService.getComboObservable(query).subscribe((res) => {
      switch (comboType) {
        case "ComboCarBrand":
          this.form1.get("brand").disable();
          this.brands = res;
          this.form1.get("brand").enable();
          break;
        case "ComboCarModel":
          this.form1.get("model").disable();
          this.models = res;
          this.form1.get("model").enable();
          break;
        case "ComboCarYear":
          this.form1.get("year").disable();
          this.years = res;
          this.form1.get("year").enable();
          break;
        case "ComboCarVersion":
          this.form1.get("version").disable();
          this.versions = res;
          this.form1.get("version").enable();
          break;
        case "ComboProvince":
          this.form1.get("province").disable();
          this.provinces = res;
          this.form1.get("province").enable();
          break;
        case "ComboLocation":
          this.form1.get("localitie").disable();
          this.localities = res.sort((a, b) => (a.Value > b.Value ? 1 : -1));
          this.virtualScroll = this.localities.length > 3;
          this.form1.get("localitie").enable();

          break;
        case "ComboIva":
          this.iva = res;
          break;
        case "ComboIIBB":
          this.ibb = res;
          break;
        case "ComboWayPayment":
          this.tipoViaDeCobro = res;
          break;
        case "ComboPersonType":
          this.tipoPersona = res;
          break;
        case "ComboBankingEntity":
          this.entidadBancaria = res;
          //revisar esto porque no se usa la entidad Bancaria
          break;
        default:
          break;
      }
    });
  }

  brand = "";
  model = "";
  year = "";
  combustible = "";
  province = "";
  filters: any[] = [];

  filterCombo(
    comboType: any,
    col: any = "",
    value: any = "",
    formCamp?: string
  ) {
    this.cdr.detach();

    if (formCamp) {
      switch (formCamp) {
        case "brand":
          this.brandChange();
          break;
        case "model":
          this.modelChange();
          break;
        case "year":
          this.yearChange();
          break;
        case "province":
          this.provinceChange();
          break;
        default:
          break;
      }
    }

    let filtros = "";

    this.query = [];

    let find = this.filters.find((d) => d.col == "filters");
    if (find) {
      let index = this.filters.indexOf(find);
      this.filters.splice(index, 1);
    }

    let findFil = this.filters.find((d) => d.col == col);
    if (findFil) {
      let index = this.filters.indexOf(findFil);
      this.filters.splice(index, 1);
    }

    if (col == "Brand") {
      this.brand = value.Value;
      this.filters.push({ col, value: col + "=" + this.brand });
    }
    if (col == "Model") {
      this.model = value.Value;
      this.filters.push({ col, value: col + "=" + this.model });
    }
    if (col == "Year") {
      this.year = value.Value;
      this.filters.push({ col, value: col + "=" + this.year });
    }
    if (col == "Combustible") {
      this.combustible = value.Value;
      this.filters.push({ col, value: col + "=" + this.combustible });
    }
    if (col == "Province") {
      this.province = value.Code;
      this.filters.push({ col, value: col + "=" + this.province });
      this.inputCode = false;
      this.localities = [];
    }

    if (comboType == "ComboBankingEntity") {
      this.filters.push({
        col: "WayPaymentCode",
        value: "WayPaymentCode" + "=" + "4",
      });
    }

    const values = this.filters.map((filter) => filter.value);

    filtros = values.join(";");
    if (comboType) {
      let query = [];
      query.push({ col: "comboType", value: comboType });
      query.push({ col: "filters", value: filtros });

      this.getCombo(query, comboType);
    }
    this.cdr.reattach();
  }
  onLocalitieChange(event: any) {
    const selectedValue = event.value;
    this.form1.controls["cp"].setValue(selectedValue.Code);
    this.inputCode = true;
  }
  ngOnInit(): void {
    this.filterCombo("ComboCarBrand", "", "", "brand");
    this.filterCombo("ComboProvince", "", "", "province");
    this.form1.get("model").disable();
    this.form1.get("year").disable();
    this.form1.get("version").disable();
    this.form1.get("localitie").disable();
    
    this.comboReceived = history.state.comboReceived ? history.state.comboReceived : "";

    if (this.comboReceived) {
      if (this.comboReceived.brand) {
        this.filterCombo("ComboCarBrand");
        this.filterCombo("ComboCarModel", "Brand", this.comboReceived.brand);
      }
      if (this.comboReceived.model) {
        this.filterCombo("ComboCarYear", "Model", this.comboReceived.model);
      }
      if (this.comboReceived.year) {
        this.filterCombo("ComboCarVersion", "Year", this.comboReceived.year);
      }
      if (this.comboReceived.province) {
        this.filterCombo(
          "ComboProvince",
          "Province",
          this.comboReceived.province
        );
        this.filterCombo(
          'ComboLocation',
          'Province',
          this.comboReceived.province
        );
      }
    }

    this.form1 = this.formBuilder.group(
      {
        brand: [this.comboReceived.brand || "", [Validators.required]],
        model: [this.comboReceived.model || "", [Validators.required]],
        year: [this.comboReceived.year || "", [Validators.required]],
        version: [this.comboReceived.version || "", [Validators.required]],
        province: [this.comboReceived.province || "", [Validators.required]],
        localitie: [this.comboReceived.localitie || "", [Validators.required]],
        cp: [this.comboReceived.localitie?.Code || "", [Validators.required]],
      },
      {
        validators: [
          // this.budgetFormValidatorService.habilitarCampoModel("brand", "model"),
        ],
      }
    );
    
  }
  
  buscarSeguro() {
    let sendCombo: sendCombos = {
      brand: this.form1.get("brand").value,
      model: this.form1.get("model").value,
      year: this.form1.get("year").value,
      version: this.form1.get("version").value,
      province: this.form1.get("province").value,
      localitie: this.form1.get("localitie").value,
      tipoIva: {
        Code: "1",
        Value: "Consumidor Final",
        Field1: null,
        Field2: null,
      },
      tipoIIBB: {
        Code: "1",
        Value: "Regimen Simplificado",
        Field1: null,
        Field2: null,
      },
      tipoViaDeCobro: {
        Code: "4",
        Value: "TARJETA CREDITO",
        Field1: null,
        Field2: null,
      },
      entidadBancaria: { Code: "7", Value: "VISA", Field1: null, Field2: null },
    };
    if (this.inputCode) {
      sendCombo.localitie.Code = this.form1.get("cp").value;
    }

    this.router.navigate(["/buscador"], {
      state: {
        comboReceived: sendCombo,
      },
    });
  }

  brandChange() {
    this.form1.get("model").setValue(null);
    this.form1.get("year").setValue(null);
    this.form1.get("version").setValue(null);
    this.form1.get("model").disable();
    this.form1.get("year").disable();
    this.form1.get("version").disable();
  }
  modelChange() {
    this.form1.get("year").setValue(null);
    this.form1.get("version").setValue(null);
    this.form1.get("year").disable();
    this.form1.get("version").disable();
  }
  yearChange() {
    this.form1.get("version").setValue(null);
    this.form1.get("version").disable();
  }

  provinceChange() {
    this.form1.get("localitie").setValue(null);
    this.form1.get("localitie").disable();
  }
  onButtonClick() {
    // Marcar todos los campos como "touched"
    this.form1.markAllAsTouched();
  }
  registrarse() {
    this.utilitiesService.redirectToHome('/registrarse');
  }
}
