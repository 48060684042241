import { Component } from '@angular/core';

@Component({
  selector: 'app-servicio-al-cliente',
  templateUrl: './servicio-al-cliente.component.html',
  styleUrls: ['./servicio-al-cliente.component.css']
})
export class ServicioAlClienteComponent {

}
