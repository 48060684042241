
<app-title title="Coberturas para tu vehículo"></app-title>
<div class="container-w80">
  <div class="d-flex flex-row" *ngIf="comboReceived">
    <span class="subtitle" [style.margin]="'2em 0px '">
      {{ comboReceived.brand.Value }} > {{ comboReceived.model.Value }} >
      {{ comboReceived.year.Value }} > {{ comboReceived.version.Value }} >
      {{ comboReceived.province.Value }} > {{ comboReceived.localitie.Value }}</span
    >
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center" *ngIf="!currentUser">
    <p-messages [(value)]="messages" [enableService]="false" />
  </div>
  <div class="d-flex flex-row">
    <div class="card w-100 text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>

    <app-table-budget
      *ngIf="!spinner"
      [budgetId]="companies.budgetId"
      [companies]="companies.listCompanies"
      [comboReceived]="comboReceived"
    ></app-table-budget>
  </div>
  
    <div class="d-flex flex-row justify-content-end">
      <p-button routerLink="/home" [style.margin]="'1em !important'" class="max secondary" label="Volver" (click)="volver()"></p-button>
    </div>
  
</div>
