<div [style.width]="'100%'" [style.margin]="'1em!important'" class="d-flex justify-content-center" *ngIf="!spinner">
  <form [formGroup]="formRegister" (ngSubmit)="register()" autocomplete="off" class="d-flex flex-column"
    [style.width]="'90%'">
    <div [style.margin]="'0.5em !important'">
      <h1 class="subtitle-blue">Registrarse</h1>
      <p-divider></p-divider>
    </div>
    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Nombre</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="nombre"
            title="Nombre" />
        </div>
        <span class="danger" *ngIf="
          formRegister.get('nombre').hasError('required') &&
          formRegister.get('nombre').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Apellido</label>
        <div class="p-inputgroup">
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="apellido"
            title="Apellido" />
        </div>
        <span class="danger" *ngIf="
          formRegister.get('apellido').hasError('required') &&
          formRegister.get('apellido').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Sexo</label>
        <p-dropdown class="" [options]="sex" [style]="{ width: '100%' }" placeholder="- Sexo -" optionLabel="Value"
          formControlName="sex">
          <ng-template let-option pTemplate="item">
            <span [class]="'sex-' + option.Code">{{ option.Value }}</span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
          formRegister.get('sex').hasError('required') &&
          formRegister.get('sex').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Estado civil</label>
        <p-dropdown class="" [options]="stateCivil" [style]="{ width: '100%' }" placeholder="- Estado civil -"
          optionLabel="Value" formControlName="stateCivil">
          <ng-template let-option pTemplate="item">
            <span [class]="'sex-' + option.Code">{{ option.Value }}</span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
          formRegister.get('stateCivil').hasError('required') &&
          formRegister.get('stateCivil').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Fecha de nacimiento <small>(Ej: 31/12/2000)</small></label>
        <span class="danger" *ngIf="dateErrorFormat.has('birthDate')">
          <small class="p-error">El formato de la fecha debe ser (dd/mm/yyyy).</small>
        </span>
        <p-calendar [style]="{ width: '100%' }" dateFormat="dd/mm/yy" (onInput)="changeCalendar($event,'birthDate')"
          (onSelect)="selectCalendar($event,'birthDate')" formControlName="birthDate"></p-calendar>
        <span class="danger" *ngIf="formRegister.get('birthDate').hasError('required') && formRegister.get('birthDate').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">N° documento</label>

        <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" pInputText
          formControlName="numeroDocumento" title="Número de Documento" />
        <span class="danger" *ngIf="formRegister.get('numeroDocumento').hasError('required') && formRegister.get('numeroDocumento').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
        <span class="danger" *ngIf="formRegister.get('numeroDocumento').hasError('maxlength')">
          <small class="p-error">El N° de Documento no debe tener mas de 8 caracteres</small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Nacionalidad</label>
        <div class="p-inputgroup">
          <p-dropdown class="" [options]="nacionality" [style]="{ width: '100%' }" placeholder="- Nacionalidad -" optionLabel="Value" formControlName="nacionalidad">
            <ng-template let-option pTemplate="item">
              <span [class]="'nacionalidad-' + option.Code">{{ option.Value }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <span class="danger" *ngIf="
          formRegister.get('nacionalidad').hasError('required') &&
          formRegister.get('nacionalidad').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'">
        <label class="label-input">Teléfono móvil <small>(Sin el 0 y el 15)</small></label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">N°</span>
          <input type="text" class="input p-inputtext p-component p-element" pInputText formControlName="telefono"
            title="Número de Teléfono Móvil" oninput="this.value = this.value.replace(/[^0-9]/g, '')"/>
        </div>
        <span class="danger" *ngIf="formRegister.get('telefono').hasError('required') && formRegister.get('telefono').touched">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
        <span class="danger" *ngIf="formRegister.get('telefono').hasError('maxlength') || formRegister.get('telefono').hasError('minlength')">
          <small class="p-error">Cod. Area o N° de Telefono incompleto.</small>
        </span>
      </div>
    </div>
    
    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Provincia</label>
        <p-dropdown class="" [style]="{ width: '100%' }" [options]="provinces" placeholder="- Provincia -"
          formControlName="province" optionLabel="Value" (onChange)="filterCombo('ComboLocation', 'Province', $event.value)
        ">
          <ng-template let-option pTemplate="item">
            <span [class]="'province-' + option.Code">{{ option.Value }}</span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
          formRegister.get('province').hasError('required') &&
          formRegister.get('province').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Localidad</label>
        <p-dropdown class="" (onChange)="onLocalitieChange($event)" [style]="{ width: '100%' }" [options]="localities"
          placeholder="- Localidad -" formControlName="localitie" optionLabel="Value">
          <ng-template let-option pTemplate="item">
            <span [class]="'localitie-' + option.Code">{{ option.Value }}</span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
          formRegister.get('localitie').hasError('required') &&
          formRegister.get('localitie').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="row m-0 p-0 mt-3  justify-content-end" *ngIf="inputCode">
      <div class="input-container" [style.width]="'50%'">
        <input type="text" class="form-control" pInputText formControlName="cp" title="Código Postal" placeholder="- Código postal -"/>
        <br>
        <span class="danger" *ngIf="
          formRegister.get('cp').hasError('required') &&
          formRegister.get('cp').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Calle</label>
        <input type="text" class="form-control" pInputText formControlName="calle" title="Nombre de Calle" />
        <span class="danger" *ngIf="
          formRegister.get('calle').hasError('required') &&
          formRegister.get('calle').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Altura</label>
        <input pInputText formControlName="numeroCalle" type="text" class="form-control"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')" title="Número de Calle" />
        <span class="danger" *ngIf="
                    formRegister.get('numeroCalle').hasError('max')
                  ">
          <small class="p-error">Este campo tiene un limite hasta 99999</small>
        </span>
        <span class="danger" *ngIf="
          formRegister.get('numeroCalle').hasError('required') &&
          formRegister.get('numeroCalle').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Piso</label>
        <input type="text" class="form-control" pInputText formControlName="piso" title="Piso" />
      </div>
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label for="" class="label-input">Depto</label>
        <input type="text" class="form-control" pInputText formControlName="depto" title="Departamento" />
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label class="label-input">Correo electronico</label>
        <input type="text" class="form-control" pInputText formControlName="mail" title="Correo Electrónico" />
        <span class="danger" *ngIf="formRegister.get('mail').hasError('email')">
          <small class="p-error">EL correo es invalido</small>
        </span>
        <span class="danger" *ngIf="
          formRegister.get('mail').hasError('required') &&
          formRegister.get('mail').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>

    <div class="d-flex flex-sm-row flex-column">
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label class="label-input">Contraseña</label>
        <input matInput type="password" class="form-control" formControlName="password" title="Contraseña"
          [minlength]="8" [pattern]="passwordPattern" />
        <span class="danger" *ngIf="
          formRegister.get('password').hasError('required') &&
          formRegister.get('password').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
        <span class="danger" *ngIf="formRegister.get('password').hasError('minlength')">
          <small class="p-error">La contraseña debe tener al menos 8 caracteres.</small>
        </span>
        <span class="danger" *ngIf="formRegister.get('password').hasError('pattern')">
          <small class="p-error">La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un
            número.</small>
        </span>

        <span class="danger" *ngIf="formRegister.hasError('notEqual')" [style.margin-top]="'1em'">
          <small class="p-error">Las contraseñas deben ser iguales.</small>
        </span>
      </div>
      <div class="input-container" [style.width]="'100%'" [style.margin]="'0.5em'">
        <label class="label-input">Confirmar contraseña</label>
        <input matInput type="password" class="form-control" formControlName="passwordRepeat" title="Repetir Contraseña"
          [minlength]="8" [pattern]="passwordPattern" />
        <span class="danger" *ngIf="formRegister.get('passwordRepeat').hasError('minlength')">
          <small class="p-error">La contraseña debe tener al menos 8 caracteres.</small>
        </span>
        <span class="danger" *ngIf="formRegister.get('passwordRepeat').hasError('pattern')">
          <small class="p-error">La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un
            número.</small>
        </span>
        <span class="danger" *ngIf="
          formRegister.get('passwordRepeat').hasError('required') &&
          formRegister.get('passwordRepeat').touched
        ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
        <br>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <p-checkbox formControlName="termsConditions" [style.margin-left]="'0.5em'" [style.margin-bottom]="'0.1em'"
        inputId="termsConditions" class="radio" [binary]="true"></p-checkbox>
      <label for="termsConditions" class="label-input dark">Acepto los términos y condiciones <a class="terms-link" (click)="openTermsModal()">(Ver aquí)</a></label>
      <span class="danger" *ngIf="formRegister.get('termsConditions').hasError('isTrue') && formRegister.get('termsConditions').touched">
        <small class="p-error">Este campo es <strong>requerido</strong></small>
      </span>
    </div>

    <div class="d-flex flex-row justify-content-center" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
      <p-button type="submit" (click)="mostrarErrores()" [style.margin]="'1em !important'" class="max"  label="Crear cuenta"
        [disabled]="formRegister.invalid"></p-button>

      <p-button routerLink="/home" [style.margin]="'1em !important'" class="max secondary" label="Cancelar" (click)="volver()"> </p-button>
    </div>
  </form>
</div>
<div class="card w-100 text-center" *ngIf="spinner" [style.min-height]="'42vh'">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
</div>

<p-dialog header="Términos y Condiciones" [(visible)]="showTermsModal" [modal]="true" width="50vw">
  <app-pop-up-detail-terminos-y-condiciones></app-pop-up-detail-terminos-y-condiciones>
</p-dialog>