<p-dialog [(visible)]="visiblePopUpContratacion" [style]="{ width: '50vw' }" [modal]="true">
  <app-pop-up-detail-poliza  [contratarBtn]="true" [budgetId]="budgetId" [quoteDetailsCompany]="companyQuoteSelectDetails"
    [company]="companySelect" [companies]="companies" [comboReceived]="comboReceived"></app-pop-up-detail-poliza>
</p-dialog>

<p-table styleClass="p-datatable-gridlines" [value]="companies" [lazy]="true" dataKey="id"
  [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th class="content">Responsabilidad Civil</th>
      <th class="content">Terceros Completos</th>
      <th class="content">Terceros Completos Full + Granizo</th>
      <th class="content">Todo Riesgo Franquicia Variable</th>
      <th class="content">Todo Riesgo Franquicia Fija</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-company>
    {{company.load}}

    <tr [id]="company.Code" *ngIf="company">
      <td>
        <img [src]="'assets/img/'+company.Code+'.png'" 
          class="center-logo" 
          (error)="onImageError(company.Code)" 
          *ngIf="!missingImages.includes(company.Code); else default" 
          [title]="company.Value"/>
        <ng-template #default>
          <img src="assets/img/logo.svg" *ngIf="missingImages.includes(company.Code)" class="center-logo" [title]="company.Value"/>
        </ng-template>      
      </td>
      <td [class]="'hover-td ' + quote?.coberturaPosicion" *ngFor="
          let quote of company?.listQuoteDetail;
          let i = index;
          trackBy: trackByIndex
        " (mouseenter)="showHover(quote, i)" (mouseleave)="hiddenHover(quote, i)">
        <div *ngIf="quote; else puntos">
          <div *ngIf="!quote?.isHovered">
            <span class="monto"> {{ quote.premioMensual | currency}} / mes </span>
            <b class="text-dark"></b>
          </div>
          <div *ngIf="quote.isHovered && highlightedIndex === i">
            <span (click)="showPopUpSeeDetails(quote, company)" class="contratar">
              <i class="pi pi-check-circle" [style.margin-top]="'2px'" [style.margin-right]="'10px'"
                [style.font-size]="'1em'" [style.color]="'white'"></i>
              <span>Ver Detalle</span>
            </span>
          </div>
        </div>
        <ng-template #puntos>
          <div *ngIf="!company.volverIntentar; else imagen">
            <p class="h1 text-center">...</p>
          </div>
          <ng-template #imagen>
            <div  class="text-center">
              <p-progressSpinner [style.width]="'40px'" [style.display]="'inline-flex'"></p-progressSpinner>
            </div>
          </ng-template>
        </ng-template>
      </td>
    
      <td *ngIf="!company.listQuoteDetail" class="text-center">
        <p-progressSpinner [style.width]="'40px'" [style.display]="'inline-flex'"></p-progressSpinner>
      </td>
      <td *ngIf="!company.listQuoteDetail" class="text-center">
        <p-progressSpinner [style.width]="'40px'" [style.display]="'inline-flex'"></p-progressSpinner>
      </td>
      <td *ngIf="!company.listQuoteDetail" class="text-center">
        <p-progressSpinner [style.width]="'40px'" [style.display]="'inline-flex'"></p-progressSpinner>
      </td>
      <td *ngIf="!company.listQuoteDetail" class="text-center">
        <p-progressSpinner [style.width]="'40px'" [style.display]="'inline-flex'"></p-progressSpinner>
      </td>
      <td *ngIf="!company.listQuoteDetail" class="text-center">
        <p-progressSpinner [style.width]="'40px'" [style.display]="'inline-flex'"></p-progressSpinner>
      </td>
    </tr>
  </ng-template>
</p-table>