import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferidosComponent } from './pages/referidos/referidos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { PrimeNgModule } from 'src/app/shared/prime-ng/prime-ng.module';


@NgModule({
  declarations: [
    ReferidosComponent

  ],
  imports: [SharedModule,CommonModule, FormsModule, PrimeNgModule, ReactiveFormsModule],
  exports: [
    ReferidosComponent
  ],
})
export class ReferidosModule { }
