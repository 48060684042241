import {
  Component,
  DoCheck,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import { User } from "src/app/shared/models/user/user.class";
import { AfterContentChecked } from "@angular/core";
import { filter } from "rxjs";
import { Location } from '@angular/common';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, DoCheck, AfterContentChecked {
  form: FormGroup;
  form2: FormGroup;

  visibleModalLogin = false;
  visibleModalPassword = false;
  userLogin: User = new User();
  botonRegister = false;
  botonIngresar = false;
  botonVolver = false;
  currentUser: any;
  pathArray: string[] = ['/registrarse', '/registrarse/validar-codigo'];
  isSubmitting: boolean = false;

  tokenLogin: string = localStorage.getItem("tokenAsegurayGana") || "";

  items: MenuItem[] = [
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        // this.update();
      },
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: () => {
        // this.delete();
      },
    },
    { label: "Angular.io", icon: "pi pi-info", url: "http://angular.io" },
    { separator: true },
    { label: "Setup", icon: "pi pi-cog", routerLink: ["/setup"] },
  ];

  constructor(
    private location: Location,
    private messageService: MessageService,
    private userManagerService: UserManagerService,
    private router: Router
  ) {

  }
  ngAfterContentChecked(): void {
    this.tokenLogin = localStorage.getItem("tokenAsegurayGana") || "";
  }
  ngAfterViewChecked(): void { }

  async ngDoCheck(): Promise<void> {
    this.tokenLogin = localStorage.getItem("tokenAsegurayGana") || "";
    this.currentUser = JSON.parse(localStorage.getItem("user")) || "";
  }
  tipos = [
    { label: "CUIT", value: true },
    { label: "DNI", value: false }
  ]
  async ngOnInit(): Promise<void> {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        
        let path = this.location.path();
        const indexOfQuestionMark = path.indexOf('?');
        let cleanPath = indexOfQuestionMark !== -1 ? path.substring(0, indexOfQuestionMark) : path;
        
        this.botonRegister = this.pathArray.includes(cleanPath) ? false : true;
        this.botonIngresar = this.pathArray.includes(cleanPath) ? false : true;
        const allowedPaths = new Set(this.pathArray);

        this.botonVolver = allowedPaths.has(cleanPath);
      }
    });
    this.form = new FormGroup({
      user: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
    this.form2 = new FormGroup({
      mail: new FormControl("", [Validators.required, , Validators.email]),
      doc: new FormControl("", [Validators.required]),
      //isCuit: new FormControl(false, [Validators.required]),
    });
    if (this.tokenLogin != "" && this.tokenLogin != undefined) {
      let user = await this.getCurrentUser();
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  loginPopUp() {
    this.visibleModalLogin = true;
  }

  login() {
    this.userLogin.UserId = this.form.get("user").value;
    this.userLogin.Password = this.form.get("password").value;

    this.userLogin.isCuit = false;

    this.userManagerService.signIn(this.userLogin).subscribe(
      async (data) => {
        let { Token } = data;
        this.tokenLogin = Token;
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
          detail: "Inicio de sesión exitoso",
        });
        localStorage.setItem("tokenAsegurayGana", Token);
        let user = await this.getCurrentUser();
        localStorage.setItem("user", JSON.stringify(user));
        this.visibleModalLogin = false;
        location.reload();
      },
      (err) => {
        if (err.error.includes('activar')) {
          this.router.navigate(["/registrarse/validar-codigo"], { state: {doc: this.form.get("user").value} });
          this.visibleModalLogin = false;
        }
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Erros al querer ingresar con las credenciales actuales",
        });
      }
    );
  }

  async getCurrentUser() {
    return await this.userManagerService.GetUserData();
  }

  logout() {
    localStorage.removeItem("tokenAsegurayGana");
    localStorage.removeItem("user");
    this.currentUser = "";
    this.router.navigate(["/home"]);
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHandler($event: any): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url !== '/buscador') {
        localStorage.removeItem('budgetId');
      }
    });
  }

  onButtonClick() {
    // Marcar todos los campos como "touched"
    this.form.markAllAsTouched();
  }

  onButtonClick2() {
    this.form2.markAllAsTouched();
  }

  forgotPassword() {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    let mail = this.form2.get("mail").value;
    let doc = this.form2.get("doc").value;
    let isCuit = false;
    this.userManagerService.forgotPassword([{ col: "userEmail", value: mail }, { col: "userId", value: doc }, { col: "isCuit", value: isCuit }]).subscribe({
      next : (data)=>{
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
          detail: "",
        });
      },
      error : (err)=>{
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Error al querer recuperar contraseña",
        });
      },
      complete : ()=>{
        this.isSubmitting = false;        
      }
    })
  }
  
  forgotPasswordVisible() {
    this.visibleModalLogin = false;
    this.visibleModalPassword = true;
  }
}
