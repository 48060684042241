import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pop-up-detail-documentacion',
  templateUrl: './pop-up-detail-documentacion.component.html',
  styleUrls: ['./pop-up-detail-documentacion.component.css']
})
export class PopUpDetailDocumentacionComponent {
  @Input() policyDocumentation:any;
  @Input() mensajePopUp:string;

  ngOnInit(): void {}

  descargarArchivo(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = url.split('/').pop() || 'documento.pdf';
    link.click();
  }
}
