<div class="container">
  <div class="row my-4">
    <div class="col">
      <div class="text-start">
        <a href="" class="text-dark text-decoration-none title-blue" pTooltip="Volver">
          <i class="bi bi-arrow-left h1" routerLink="/home"></i>
        </a>
        <div class="text-center">
          <h1 class="text-center d-inline my-4 title-blue">¿Qué debo tener en cuenta al contratar mi seguro?</h1>
        </div>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col text-center">
        <img
          src="assets/img/hombre-negocios.png"
          alt=""
          srcset=""
          class="img-precio-seguro"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <p class="word-break">
            ¿Esta todo cubierto en un seguro automotor?. No, existen algunas exclusiones a la cobertura para Robo o Hurto.
        </p>

        <ul>
          <li>
            No están cubiertos el robo o hurto de:
            <ul>
              <li>
                Las tazas de ruedas
              </li>
              <li>
                Tapas del radiador, del tanque de combustible

              </li>
              <li>
                Escobillas y brazos limpiaparabrisas

              </li>
              <li>
                Espejos e insignias exteriores

              </li>
              <li>
                Herramientas, formen o no parte del equipo original de fábrica
              </li>
            </ul>
          </li>
        </ul>
  
        <p class="word-break">
            No obstante, la compañía responderá cuando la pérdida se hubiera producido con motivo del robo o hurto total del vehículo en la medida que esté comprendido el riesgo de robo o hurto parcial como secuela de acontecimiento cubierto. O sea, si te roban el auto y aparece con esos faltantes.
        </p>
        <p class="word-break">
          Una reflexión final, no todo es el precio, valora la relación con tu
          asesor, la comunicación, que te responda rápido y este siempre
          disponible.
        </p>
        <p class="word-break">¡No dudes en consultarnos!</p>
      </div>
    </div>
  </div>
  