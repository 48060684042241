import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  form: FormGroup;

  visibleModalLogin = false;
  constructor() {
    this.form = new FormGroup({
      user: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }
  onButtonClick() {
    // Marcar todos los campos como "touched"
    this.form.markAllAsTouched();
  }

  login() {
   /* this.userLogin.UserId = this.form.get("user").value;
    this.userLogin.Password = this.form.get("password").value;

    this.userLogin.isCuit = false;

    this.userManagerService.signIn(this.userLogin).subscribe(
      async (data) => {
        let { Token } = data;
        this.tokenLogin = Token;
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
          detail: "Inicio de sesión exitoso",
        });
        localStorage.setItem("tokenAsegurayGana", Token);
        let user = await this.getCurrentUser();
        localStorage.setItem("user", JSON.stringify(user));
        this.visibleModalLogin = false;
      },
      (err) => {
        console.log(err)
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message
            ? err.error.message
            : "Erros al querer ingresar con las credenciales actuales",
        });
      }
    );*/
  }
}
