import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, lastValueFrom, map } from "rxjs";
import {
  Companies,
  ListQuote,
} from "src/app/modules/polizas/interfaces/companies.interface";
import { Combo } from "src/app/modules/shell/interfaces/combo.interface";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class BudgetManagerService {
  constructor(private http: HttpClient) {}

  getCombo(query: any): Promise<Combo[]> {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "comboType") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "filters") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders({
      // "access-token": localStorage.getItem("tokenAsegurayGana"),
    });

    return lastValueFrom<Combo[]>(
      this.http.get<Combo[]>(
        `${environment.apiUrl}/BudgetManager.svc/GetCombo?${queryBuid}`,
        {
          headers: headers,
        }
      )
    );
  }

  getComboObservable(query: any): Observable<any> {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "comboType") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "filters") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });
    return this.http.get<any>(
      `${environment.apiUrl}/BudgetManager.svc/GetCombo?${queryBuid}`
    );
  }

  generateAutoBudget(autoBudget: any): Promise<Companies> {
    let token = localStorage.getItem("tokenAsegurayGana");

    let headers = token
      ? new HttpHeaders({ "access-token": token })
      : new HttpHeaders();

    return lastValueFrom<Companies>(
      this.http.post<Companies>(
        `${environment.apiUrl}/BudgetManager.svc/GenerateAutoBudget`,
        autoBudget,
        {
          headers,
        }
      )
    );
  }

  quoteBudgetByCompany(data: any): Promise<ListQuote> {
    let token = localStorage.getItem("tokenAsegurayGana");
    let headers = token
      ? new HttpHeaders({ "access-token": token })
      : new HttpHeaders();

    return lastValueFrom<ListQuote>(
      this.http.post<ListQuote>(
        `${environment.apiUrl}/BudgetManager.svc/QuoteBudgetByCompany`,
        data,
        { headers: headers }
      )
    );
  }
  getListQuoteBudgetByCompany(data: any): Promise<ListQuote> {
    let token = localStorage.getItem("tokenAsegurayGana");
    let headers = token
      ? new HttpHeaders({ "access-token": token })
      : new HttpHeaders();

    return lastValueFrom<ListQuote>(
      this.http.post<ListQuote>(
        `${environment.apiUrl}/BudgetManager.svc/QuoteBudgetByCompany`,
        data,
        { headers: headers }
      )
    );
  }

  quoteBudgetBulk(data: any): Promise<ListQuote> {
    let headers = new HttpHeaders({
      // "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return lastValueFrom<ListQuote>(
      this.http.post<ListQuote>(
        `${environment.apiUrl}/BudgetManager.svc/QuoteBudgetBulk`,
        data,
        { headers: headers }
      )
    );
  }

  issueOrderForClient(data: any) {
    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return this.http.post(`${environment.apiUrl}/BudgetManager.svc/GenerateIssueOrder`, data, {
        headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  checkOrderStatus(query: any) {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "OrderId") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });

    return this.http
      .get(`${environment.apiUrl}/checkOrderStatus?${queryBuid}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
