import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsejoRoutingModule } from './consejo-routing.module';
import { ConsejoPrecioSeguroComponent } from './pages/consejo-precio-seguro/consejo-precio-seguro.component';
import { ConsejoContratarSeguroComponent } from './pages/consejo-contratar-seguro/consejo-contratar-seguro.component';
import { ConsejoCompararComponent } from './pages/consejo-comparar/consejo-comparar.component';
import { PrimeNgModule } from 'src/app/shared/prime-ng/prime-ng.module';


@NgModule({
  declarations: [
    ConsejoPrecioSeguroComponent,
    ConsejoContratarSeguroComponent,
    ConsejoCompararComponent
  ],
  imports: [
    CommonModule,
    ConsejoRoutingModule,
    PrimeNgModule
  ]
})
export class ConsejoModule { }
