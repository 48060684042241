<div class="  d-flex flex-row" [style.background-color]="'#da6d73'">
    <div [style.padding]="'1em'" class=" container-w80">
        <h1 class="title-white">Referidos</h1>
    </div>
</div>
<div class=" container-w80">
    <div class="d-flex flex-row">
        <h2 class="subtitle-blue">Mis referidos</h2>
    </div>
    <div class="card-table">
        <p-table [value]="lista" [tableStyle]="{ 'min-width': '35rem' }" [style.width]="'100%'">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="label-table"> Email</th>
                    <th class="label-table">Estado</th>
                    <!--<th class="label-suma">¡Suma por mes!
                        <img width="20px" src="./assets/img/medalla.png" alt="">
                    </th>-->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td class="label-value"> {{item.correo}}</td>
                    <td class="label-value"> {{item.estado}}</td>
                    <!-- <td>
                        <span class="eliminar" (click)="eliminar(item)">Eliminar</span>
                    </td> -->
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div [style.width]="'100%'" [style.margin]="'1em 0px!important'" >
        <form [formGroup]="form1" autocomplete="off" class="d-flex flex-column" [style.width]="'100%'">
            <div [style.margin]="'0.5em !important'">
                <h1 class="subtitle-blue">Agregar nuevo</h1>
                <h1 class="subtitle-pink-ligth">¿Querés sumar un nuevo referido? Ingresalo acá</h1>
                <p-divider></p-divider>
            </div>
            <div class="d-flex flex-row">
                <div class="input-container" [style.width]="'100%'">
                    <label class="label-input">Correo electrónico</label>
                    <div class="p-inputgroup">
                        <input type="text" class="input p-inputtext p-component p-element" pInputText
                            formControlName="correo" />
                    </div>
                    <span class="danger" *ngIf="form1.get('correo').hasError('required') && form1.get('correo').touched">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </span>
                    <span class="danger" *ngIf="form1.get('correo').hasError('email')">
                        <small class="p-error">Este campo debe tener formato de <strong>email</strong></small>
                    </span>
                </div>
            </div>

            <div class="input-container" [style.text-align]="'center'" [style.margin-top]="'1em !important'">
                <p-button 
                    (click)="create()" 
                    [disabled]="form1.invalid || buttonLoad" 
                    [style.margin-top]="'1em !important'" 
                    class="max">
                    <span *ngIf="buttonLoad" class="text-b">Creando referencia...</span>
                    <i *ngIf="buttonLoad" class="pi pi-spinner pi-spin"></i>
                    <span class="text-b" *ngIf="!buttonLoad">Añadir</span>
                </p-button>
                <p-button routerLink="/home" [style.margin]="'1em !important'" class="max secondary" label="Volver" (click)="volver()"> </p-button>
            </div>
            
            
        </form>
    </div>
</div>