<div class="container mb-5">
    <div class="row my-4">
        <div class="col">
            <div class="text-start">
                <a href="" class="text-dark text-decoration-none title-blue" pTooltip="Volver">
                    <i class="bi bi-arrow-left h1" routerLink="/home"></i>
                </a>
                <div class="text-center">
                    <h1 class="text-center d-inline my-4 title-blue">Preguntas Frecuentes</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col col-12">
            <p class="word-break subtitle">
                1. ¿Cómo puedo obtener una cotización de seguro?
            </p>
    
            <p class="content">
                Puede obtener una cotización de seguro completando nuestro formulario en línea o llamando a nuestro equipo de servicio al cliente. Estamos aquí para ayudarte.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                2. ¿Cuáles son los tipos de cobertura que ofrecen?
            </p>
    
            <p class="content">
                Ofrecemos una variedad de coberturas, incluyendo seguro de automóviles, hogar, vida y más. Póngase en contacto con nosotros para obtener información detallada sobre nuestras opciones de cobertura.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                3. ¿Cómo puedo realizar un pago de prima?
            </p>
    
            <p class="content">
                Puedes realizar un pago de prima a través de nuestra plataforma en línea, por teléfono o visitando nuestras oficinas. Aceptamos varios métodos de pago para tu conveniencia.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                4. ¿Qué debo hacer en caso de un siniestro?
            </p>
    
            <p class="content">
                En caso de un siniestro, comunícate con nuestro equipo de reclamaciones lo antes posible. Proporcionaremos orientación sobre los pasos a seguir y la documentación necesaria.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                5. ¿Puedo modificar mi póliza de seguro?
            </p>
    
            <p class="content">
                Sí, puedes modificar tu póliza de seguro. Comunícate con nuestro equipo de servicio al cliente para discutir tus necesidades y realizar los ajustes necesarios.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                6. ¿Cuál es la duración típica de una póliza de seguro?
            </p>
    
            <p class="content">
                La duración típica de una póliza de seguro varía según el tipo de cobertura. Algunas pólizas son anuales, mientras que otras pueden ser a corto plazo. Consulta tu póliza para obtener información específica.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                7. ¿Cómo puedo cancelar mi póliza de seguro?
            </p>
    
            <p class="content">
                Puedes cancelar tu póliza de seguro enviando una notificación por escrito a nuestro equipo de servicio al cliente. Ten en cuenta que pueden aplicarse tarifas y condiciones específicas.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                8. ¿Qué sucede si olvido pagar mi prima?
            </p>
    
            <p class="content">
                Si olvidas pagar tu prima, comunícate con nosotros lo antes posible. Podemos proporcionarte opciones y asistencia para evitar interrupciones en tu cobertura.
            </p>
    
            
        </div>
        <div class="col col-12">
            <p class="word-break subtitle">
                9. ¿Cómo protegen mi información personal?
            </p>
    
            <p class="content">
                Nos tomamos muy en serio la protección de tu información personal. Seguimos las mejores prácticas de seguridad y cumplimos con todas las leyes de privacidad aplicables.
            </p>
    
            
        </div>
    
        <div class="col col-12">
            <p class="word-break subtitle">
                10. ¿Cómo puedo ponerme en contacto con su equipo de servicio al cliente?
            </p>
    
            <p class="content">
                Puedes comunicarte con nuestro equipo de servicio al cliente llamando a nuestro número gratuito o enviando un correo electrónico a [correo electrónico]. También puedes visitar nuestras oficinas durante horas hábiles.
            </p>
    
            
        </div>
    
    
    </div>
</div>