import {
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import { UtilitiesService } from "src/app/api/services/utility/utilities.service";

@Component({
  selector: "app-mi-perfil",
  templateUrl: "./mi-perfil.component.html",
  styleUrls: ["./mi-perfil.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MiPerfilComponent implements OnInit {
  user: any;
  changes: boolean = false;

  constructor(
    private messageService: MessageService,
    private userManagerService: UserManagerService,
    private utilitiesService: UtilitiesService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!(localStorage.getItem("tokenAsegurayGana"))) {
      this.utilitiesService.redirectToHome('/home');
    }
    await this.sendUser();
  }

  async sendUser() {
    if (localStorage.getItem("tokenAsegurayGana")) {
      this.user = await this.userManagerService.GetUserData();
    }
  }
  
  saveUser() {
    this.userManagerService.updateUser(this.user).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Hecho!",
          detail: "¡El usuario fue actualizado con éxito!",
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error
            : "Ups! ocurrio un error, intente de nuevo o contactese con el administrador",
        });
      },
      complete: () => {
      },
    });
  }
}
