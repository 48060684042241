import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserManagerService } from 'src/app/api/services/userManager/user-manager.service';
import { UtilitiesService } from 'src/app/api/services/utility/utilities.service';
import {  ActivatedRoute, Router } from "@angular/router";
import { Combo } from 'src/app/modules/shell/interfaces/combo.interface';
import { RefererManagerService } from 'src/app/api/services/refererManager/referer-manager.service';
import { sendCombos } from 'src/app/modules/shell/interfaces/sendComboQuoted.interface';

@Component({
  selector: 'app-validar-codigo',
  templateUrl: './validar-codigo.component.html',
  styleUrls: ['./validar-codigo.component.css']
})
export class ValidarCodigoComponent implements OnInit {
  form: FormGroup;
  doc?: string;
  mail?: string;
  fromContratacion?: boolean;
  referidos: Combo[] = [];
  referidosExist: boolean = false;
  comboReceived: sendCombos;
  
  spinner = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private userManagerService: UserManagerService,
    private RefererManagerService: RefererManagerService,
    private utilitiesService: UtilitiesService,) {
    this.form = new FormGroup({
      code: new FormControl("", [Validators.required]),
      doc: new FormControl("", [Validators.required]),
      isCuit: new FormControl(false, [Validators.required]),
      referralCode: new FormControl(null),
    });

  }
  ngOnInit(): void {
    this.doc = history.state.doc;
    this.form.patchValue({ doc: this.doc });
    this.mail = history.state.mail? history.state.mail : '';
    this.fromContratacion = history.state.fromContratacion? history.state.fromContratacion : false;
    this.comboReceived = history.state.comboReceived ? history.state.comboReceived : "";    
    
    this.RefererManagerService
      .GetUserReferrers(this.doc)
      .subscribe({
        next: (data) => {
          if (data.ListItems.length > 0) {
            this.referidos = data.ListItems;
            this.referidosExist = true;
          }

        },
        error: (err) => {
        },
        complete: () => {
        },
      });
  }

  async confirmar() {
    this.spinner = true;

    let VerificationCode = this.form.get("code").value;
    let UserId  = this.form.get("doc").value;
    let IsCuit = this.form.get("isCuit").value;
    let ReferralCode = this.form.get("referralCode").value;
    this.userManagerService.activateUser({
      UserId,
      IsCuit,
      VerificationCode,
      ReferralCode
    }).subscribe(async res => {
      this.messageService.add({
        severity: "Codigo verificado correctamente",
        summary: "Hecho!",
        detail: "",
      });
      localStorage.setItem("tokenAsegurayGana", res.Token);
      let user = await this.getCurrentUser();
      localStorage.setItem("user", JSON.stringify(user));
      
      if (history.state.fromContratacion) {        
        this.router.navigate(["/buscador"], { state: { comboReceived: this.comboReceived } });
        this.spinner = false;
      } else {        
        this.router.navigate(["/home"]);
        this.spinner = false;
      }

    },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error

        });
        this.spinner = false;

      })
  }
  async getCurrentUser() {
    return await this.userManagerService.GetUserData();
  }
  mostrarErrores() {
    this.form.markAllAsTouched();
  }

  volver() {
    this.utilitiesService.redirectToHome('/home');
  }
}
