import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  ListCompany,
  ListQuoteDetail,
} from "src/app/modules/polizas/interfaces/companies.interface";
import { sendCombos } from "src/app/modules/shell/interfaces/sendComboQuoted.interface";
import { dataCar } from "../../interfaces/car.interface";
import { addressCar } from "../../interfaces/address.interface";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Combo } from "src/app/modules/shell/interfaces/combo.interface";
import { BudgetManagerService } from "src/app/api/services/budgetManager/budget-manager.service";
import { UserManagerService } from "src/app/api/services/userManager/user-manager.service";
import { MessageService } from "primeng/api";
import { PolicyManagerService } from "src/app/api/services/policyManager/policy-manager.service";

@Component({
  selector: "app-contratacion-poliza-contratada",
  templateUrl: "./contratacion-poliza-contratada.component.html",
  styleUrls: ["./contratacion-poliza-contratada.component.css"],
})
export class ContratacionPolizaContratadaComponent {
  comboReceived: sendCombos;
  company: ListCompany;
  quoteDetailsCompany: ListQuoteDetail;
  dataCar: dataCar;
  addressCar: addressCar;
  budgetId: any;
  currentUser: any;
  policyId: any;
  dataPolicy: any;
  payPalMethod: any;
  policy: any;
  policyDetails:any;
  spinner = true;

  constructor(
    private formBuilder: FormBuilder,
    private budgetManagerService: BudgetManagerService,
    private userManagerService: UserManagerService,
    private messageService: MessageService,
    private policyManager: PolicyManagerService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userManagerService.GetUserData();
    this.comboReceived = history.state.comboReceived;
    this.company = history.state.company;
    this.quoteDetailsCompany = history.state.quoteDetailsCompany;
    this.dataCar = history.state.dataCar;
    this.addressCar = history.state.addressCar;
    this.budgetId = history.state.budgetId;
    this.policyId = history.state.policyId;
    this.dataPolicy = history.state.dataPolicy;
    this.payPalMethod = history.state.payPalMethod;
    this.getPoliciy();
    this.getUserPolicies()
  }

  fechaCreacion;
  fechaActualizacion;

  getPoliciy() {
    this.policyManager
      .getPolicyActiveItems([{ col: "policyId", value: this.policyId }])
      .subscribe({
        next: (data) => {
          this.policyDetails = data[0];
          this.spinner = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.error
              ? err.error
              : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
          });
          this.spinner = false;
        },
        complete: () => {
        },
      });
  }

  query: any[] = [];

  getUserPolicies() {
    this.policyManager.getUserPolicies(this.query).subscribe({
      next: (data) => {
        this.policy = data.ListItems.find(
          // (policy) => policy.Id === this.policyId
          (policy) => policy.Id === 15591472
        );
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error.message
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
      },
      complete: () => {
      },
    });
  }
}
