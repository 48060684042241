import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratacionLoginComponent } from './pages/contratacion-login/contratacion-login.component';
import { ShellModule } from '../shell/shell.module';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { PrimeNgModule } from 'src/app/shared/prime-ng/prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContratacionAutomovilComponent } from './pages/contratacion-automovil/contratacion-automovil.component';
import { ContratacionFinalizarComponent } from './pages/contratacion-finalizar/contratacion-finalizar.component';
import { ContratacionPolizaContratadaComponent } from './pages/contratacion-poliza-contratada/contratacion-poliza-contratada.component';



@NgModule({
  declarations: [
    ContratacionLoginComponent,
    ContratacionAutomovilComponent,
    ContratacionFinalizarComponent,
    ContratacionPolizaContratadaComponent
  ],
  imports: [
    CommonModule,
    PrimeNgModule,
    ShellModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports : [
    ContratacionLoginComponent,
    ContratacionAutomovilComponent
  ]
})
export class ContratacionModule { }
