<div class="row header" *ngIf="policy && policyDetails">
  <div class="col-8">
    <span class="subtitle-blue"
      >{{ policyDetails.marca }} - {{ policyDetails.modelo }}</span
    ><br />
    <span class="subtitle-pink-ligth">Póliza Nº {{ policy.PolicyNumber }}</span>
  </div>
  <div class="col-4 suma-puntos float-end">
    <span [style.max-width]="'6em'">¡SUMA 1240 PUNTOS!</span>
    <img width="25px" height="25px" src="./assets/img/medalla.png" alt="" />
  </div>
</div>
<div class="body" *ngIf="policy && policyDetails">
  <div class="row datos-seguro">
    <div class="col-6 col-lg-4">
      <img 
        [src]="'assets/img/' + companyCode + '.png'" 
        class="center-logo" 
        (error)="onImageError(companyCode)" 
        [title]="policy?.InsuranceCompany" 
        *ngIf="!missingImages.includes(companyCode); else defaultImage"
      />
      <ng-template #defaultImage>
        <img src="assets/img/logo.svg" class="center-logo" alt="Default Logo" [title]="policy?.InsuranceCompany" />
      </ng-template>
      <br />
      <!-- <span class="detalle-poliza mt-2" [style.width]="'100%'">
        Detalle de la póliza
      </span> -->
    </div>
    <div class="col-6 text-center col-lg-4">
      <div>
        <span class="label">{{ policyDetails.detallePaquete }}</span>
      </div>
      <div class="w-100 mt-0 mt-lg-2 text-center">
        <span class="label">Suma Asegurado</span><br />
        <span class="label">{{ policy.InsuredSum | currency }}</span>
      </div>
    </div>
    <div class="col-12 text-center col-lg-4">
      <span class="monto">{{ policy.FirstFeeValue | currency }}</span>
      <span class="label">Por Mes</span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p-divider></p-divider>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        {{ policyDetails.marca }} - {{ policyDetails.modelo }}
        </span>
    </div>
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        Combustible {{ policyDetails.tipoCombustible }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        {{ policyDetails.version }} - {{ policyDetails.anio }}
      </span>
    </div>
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        Nº de Chasis {{ policyDetails.chasis }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        Patente {{ policyDetails.patente }}
      </span>
    </div>
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        Nº de Motor {{ policyDetails.motor }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p-divider></p-divider>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        {{ policyDetails.direcccionRiesgo.calle }}
        {{ policyDetails.direcccionRiesgo.numero }}
      </span>
    </div>
    <div class="col-12 col-lg-6">
      <span [style.text-align]="'initial'" class="label">
        {{ policyDetails.direcccionRiesgo.provincia }}
        {{ policyDetails.direcccionRiesgo.localidad }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="label" [style.text-align]="'initial'">
        {{ policy.WayPayment }} {{ policy.CBUCreditCardNumber }}</span
      >
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <span class="label" [style.text-align]="'initial'"> Vigencia del:</span>
      <span
        class="label"
        [ngClass]="policy.PolicyStatus == 'VENCIDA' ? 'font-red' : ''"
        [style.text-align]="'initial'"
      >
        {{ policy.TermStartDate }} al {{ policy.TermEndDate }}</span
      >
    </div>
    <div class="col-12 col-lg-6">
      <span class="label-estado"> Estado:</span>
      <span class="estado yellow" *ngIf="policy.PolicyStatus == 'EMITIDA NO VIGENTE'">
        EMITIDA NO VIGENTE
      </span>
      <span class="estado red" *ngIf="policy.PolicyStatus == 'VENCIDA'">
        VENCIDA
      </span>
      <span class="estado green" *ngIf="policy.PolicyStatus == 'VIGENTE'">
        VIGENTE
      </span>
      <span class="estado yellow" *ngIf="policy.PolicyStatus == 'EN EMISION'">
        EN EMISION
      </span>
      <span class="estado red" *ngIf="policy.PolicyStatus == 'ORDEN CANCELADA'">
        ORDEN CANCELADA
      </span>
      <span class="estado red" *ngIf="policy.PolicyStatus == 'ANULADA'"
        >ANULADA
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col" *ngIf="policy.PolicyStatus != 'VENCIDA'">
      <a class="links" href="https://api.whatsapp.com/message/HIPSUUP24QBJN1?autoload=1&app_absent=0" target="_blank">Informar siniestro</a>
    </div>
    <div class="col text-end" *ngIf="policy.PolicyStatus != 'VENCIDA'">
      <span class="links" [style.width]="'100%'">
        <span (click)="showPopUpSeeDetails()">Documentación</span>
      </span>
    </div>
    <div class="col" *ngIf="policy.PolicyStatus == 'VENCIDA'">
      <div class="links">Renovar</div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="visiblePopUpDocumentacion" [style]="{ width: '50vw' }" [modal]="true">
  <ng-template pTemplate="header">
    <div class="custom-header">
      Póliza N° {{ policy.PolicyNumber }}
    </div>
  </ng-template>
  <app-pop-up-detail-documentacion [policyDocumentation]="policyDocumentation" [mensajePopUp]="mensajePopUp"></app-pop-up-detail-documentacion>
</p-dialog>
