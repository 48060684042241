<div class="  d-flex flex-row" [style.background-color]="'#da6d73'">
    <div [style.padding]="'1em'" class=" container-w80">
        <h1 class="title-white">Premios y canjes</h1>
    </div>
</div>
<div class="container-w80 d-flex flex-column ">
    <div class="card-blue ">
        <div class="d-flex flex-column flex-sm-row  text-align-center">
            <h1 class="title-white">¡Hola {{currentUser.Nombre}}!</h1>
            <img src="./assets/img/moneda.png" [style.margin-left]="'1em'" width="25px" height="25px" alt="">
        </div>
        <h2 class="title-white-linght"> Tenés 5.245 puntos</h2>
        <div class="d-flex flex-column flex-sm-row  text-align-center">
            <h3 class="pink-ligth-link"> Canjealos como quieras </h3>
            <i class="pi pi-arrow-right " [style.width]="'2em'"></i>
        </div>
    </div>
    <div [style.margin-top]="'2em'">
        <h2 class="subtitle-blue">Categorias</h2>
    </div>
    <div class="container-responsive">
        <!--<div class="card-icons" *ngFor="let c of categories" (click)="navegarPremio('Educación')">
            <img [src]="c.BrandImage"  width="75px" height="75px" alt="">
            <span class="label">{{c.Category.Name}}</span>
        </div>
    </div>-->
        <div class="card-icons">
            <img src="./assets/img/presentacion.png" width="75px" height="75px" alt="">
            <span class="label">Educación</span>

        </div>
        <div class="card-icons" (click)="navegarPremio('Hogar')">
            <img src="./assets/img/hogar.png" width="75px" height="75px" alt="">
            <span class="label">Hogar</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Regalos')">
            <img src="./assets/img/caja-de-regalo.png" width="75px" height="75px" alt="">
            <span class="label">Regalos</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Gastronomía')">
            <img src="./assets/img/restaurante.png" width="75px" height="75px" alt="">
            <span class="label">Gastronomía</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Electrónica')">
            <img src="./assets/img/sensible.png" width="75px" height="75px" alt="">
            <span class="label">Electrónica</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Indumentaria')">
            <img src="./assets/img/sudadera-con-capucha.png" width="75px" height="75px" alt="">
            <span class="label">Indumentaria</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Salud-y-bienestar')">
            <img src="./assets/img/meditacion.png" width="75px" height="75px" alt="">
            <span class="label">Salud y bienestar</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Recargas')">
            <img src="./assets/img/cajero-automatico.png" width="75px" height="75px" alt="">
            <span class="label">Recargas</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Entretenimiento')">
            <img src="./assets/img/consola-de-video.png" width="75px" height="75px" alt="">
            <span class="label">Entretenimiento</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Juguetes')">
            <img src="./assets/img/caja-de-almacenaje.png" width="75px" height="75px" alt="">
            <span class="label">Juguetes</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Vehículos')">
            <img src="./assets/img/coche.png" width="75px" height="75px" alt="">
            <span class="label">Vehículos</span>
        </div>
        <div class="card-icons" (click)="navegarPremio('Viajes')">
            <img src="./assets/img/avion.png" width="75px" height="75px" alt="">
            <span class="label">Viajes</span>
        </div>
    </div>


</div>