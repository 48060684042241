import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PolicyManagerService } from 'src/app/api/services/policyManager/policy-manager.service';

@Component({
  selector: 'app-poliza',
  templateUrl: './poliza.component.html',
  styleUrls: ['./poliza.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class PolizaComponent implements OnInit{


  @Input()
  policy:any;
  policyDetails:any;
  policyDocumentation:any;
  visiblePopUpDocumentacion: boolean = false;
  query:any[] = [];
  mensajePopUp: string;
  missingImages: string[] = [];
  companyCode: string = '';
  companyMap = [
    { name: "ALLIANZ ARG.CIA.DE SEG.S.A", code: '5761' },
    { name: 'LA MERIDIONAL CIA. ARG. DE SEG', code: '5813' },
    { name: 'EXPERTA SEGUROS S.A.', code: '162686861' },
    { name: 'SEGUROS SURA SA', code: '5800' },
  ];

  
  constructor(private policyManager:PolicyManagerService,private messageService:MessageService){}

  ngOnInit(): void {    
    if(this.policy){
      this.getDetailsPolicy();
      this.descargarDoc();
      this.getCompanyCode(this.policy.InsuranceCompany)
    }
  }

  getDetailsPolicy(){
    this.policyManager.getPolicyActiveItems([{col: "policyId",value : this.policy.Id}]).subscribe({
      next : (data)=>{
        this.policyDetails = data[0]
      },
      error : (err)=>{
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error.message
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
      },
      complete : ()=>{}
    })
  }
  
  showPopUpSeeDetails() {
    this.visiblePopUpDocumentacion = true;
  }

  descargarDoc(){
    this.policyManager.getPolicyDocumentation([{col: "policyId",value : this.policy.Id}]).subscribe({
      next : (data)=>{
        this.policyDocumentation = data.ListItems;
        if (this.policy.PolicyStatus == 'EN EMISION') {
          this.mensajePopUp = "Se le enviará el certificado por mail";
        } else {
          this.mensajePopUp = "No se encontraron documentos para descargar.";
        }        
      },
      error : (err)=>{
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error
            ? err.error.message
            : "Ups! ocurrio un error, intenté de nuevo o contacte con el administrador",
        });
      },
      complete : ()=>{}
    })
  }

  getCompanyCode(companyName: string): void {
    const company = this.companyMap.find((co) => co.name === companyName);
    this.companyCode = company ? company.code : 'default'; // Devuelve 'default' si no se encuentra
  }

  onImageError(companyCode: string): void {
    if (!this.missingImages.includes(companyCode)) {
      this.missingImages.push(companyCode);
    }
  }
}
