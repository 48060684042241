import { Component } from '@angular/core';

@Component({
  selector: 'app-consejo-precio-seguro',
  templateUrl: './consejo-precio-seguro.component.html',
  styleUrls: ['./consejo-precio-seguro.component.css']
})
export class ConsejoPrecioSeguroComponent {

}
