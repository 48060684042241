import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PolicyManagerService {

  constructor(private http:HttpClient) { }


  getUserPolicies(query: any = []) {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "policyStatus") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "riskType") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });
    return this.http
      .get(`${environment.apiUrl}/PolicyManager.svc/GetUserPolicies?${queryBuid}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  getPolicyDocumentation(query: any) {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "policyId") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value;
        }
      }
    });

    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });

    return this.http
      .get(`${environment.apiUrl}/PolicyManager.svc/getPolicyDocumentation?${queryBuid}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getPolicyActiveItems(query: any = []) {
    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "policyId") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders({
      "access-token": localStorage.getItem("tokenAsegurayGana"),
    });

    return this.http
      .get(`${environment.apiUrl}/PolicyManager.svc/GetPolicyActiveItems?${queryBuid}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

}
