import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { CheckboxModule } from "primeng/checkbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputNumberModule } from "primeng/inputnumber";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    ButtonModule,
    CardModule,
    DialogModule,
    AccordionModule,
    DividerModule,
    CheckboxModule,
    RadioButtonModule,
    InputNumberModule,
    TableModule,
    ToastModule,
    MessagesModule,
    DropdownModule,
    SplitButtonModule,
    ImageModule,
    ProgressSpinnerModule,
    InputMaskModule,
    InputTextModule,
    CalendarModule
  ],
})
export class PrimeNgModule {}
