import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './pages/footer/footer.component';
import { HeaderComponent } from './pages/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from 'src/app/shared/prime-ng/prime-ng.module';
import { SobreNosotrosComponent } from './components/sobre-nosotros/sobre-nosotros.component';
import { TerminosYCondicionesComponent } from './components/terminos-y-condiciones/terminos-y-condiciones.component';
import { PreguntasPrecuentesComponent } from './components/preguntas-precuentes/preguntas-precuentes.component';
import { ServicioAlClienteComponent } from './components/servicio-al-cliente/servicio-al-cliente.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SobreNosotrosComponent,
    TerminosYCondicionesComponent,
    PreguntasPrecuentesComponent,
    ServicioAlClienteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PrimeNgModule,
    ReactiveFormsModule,
  
    ],

  exports: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
  ],
})
export class ShellModule { }
